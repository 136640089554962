<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="7.507" height="9.433" viewBox="52.016 0 7.507 9.433">
        <g data-name="Group 1403">
            <path
                d="M52.972 8.978a.486.486 0 0 0 .493.455h4.609c.264 0 .482-.2.493-.455l.33-6.68h-6.255l.33 6.68Zm3.84-5.021c0-.107.09-.193.2-.193h.321c.11 0 .2.086.2.193v3.817c0 .106-.09.193-.2.193h-.32c-.111 0-.201-.087-.201-.193V3.957Zm-1.404 0c0-.107.09-.193.201-.193h.32c.111 0 .201.086.201.193v3.817c0 .106-.09.193-.2.193h-.321c-.11 0-.2-.087-.2-.193V3.957Zm-1.403 0c0-.107.09-.193.2-.193h.321c.11 0 .2.086.2.193v3.817c0 .106-.09.193-.2.193h-.32c-.111 0-.201-.087-.201-.193V3.957Z"
                fill="#36a8e0" fill-rule="evenodd" data-name="Path 1070" />
            <path
                d="M59.205.486h-2.183V.099c0-.054-.048-.099-.107-.099h-2.292c-.058 0-.106.045-.106.1v.386h-2.183c-.176 0-.318.133-.318.298v.936h7.507V.784c0-.165-.143-.298-.318-.298Z"
                fill="#36a8e0" fill-rule="evenodd" data-name="Path 1071" />
        </g>
    </svg>
</template>