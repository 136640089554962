<template>
<div>
  <template v-if="$root.$store.getters['auth/isCompany']">
    <div>
      <CompanyDashboard />
    </div>
  </template>

  <template v-else>
    <div>
      <CandidateDashboard />
    </div>
  </template>
</div>
</template>

<script>
import CandidateDashboard from '@/components/candidate/Dashboard.vue';
import CompanyDashboard from '@/components/company/Dashboard.vue';

export default {
  name: 'DashboardView',
  components: {
    CandidateDashboard,
    CompanyDashboard,
  },
  mounted() {

  }

};
</script>
