<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36">{{
        $t('company_dashboard_edit_user_profile_email_placeholder') }}</a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2  hidden md:block">info@belinqed.com</a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
        <span class="">{{ $t("edit") }}</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">
          {{ $t('company_dashboard_edit_user_profile_email_placeholder') }}
        </h1>
      </div>
      <div class="form flex-row mt-5 w-full max-w-sm mx-auto">
        <div class="mb-2">
          <!-- <a href="" class="flex justify-end text-xss text-main font-bannerExtrasquare hover:underline">+ელ-მისამართის დამატება</a> -->
          <InputFieldComponent :value="email" :label="$t('company_dashboard_edit_user_profile_email_placeholder')"
            type="email" @input="onEmailInput" v-model="email" class="" :error="v$.email.$error"
            @onBlur="v$.email.$touch()"
            :errorMessage="v$.email.$errors.length > 0 ? v$.email.$errors[0].$message : ''" />
        </div>
        <div class="mb-2 mt-4">
          <!-- <a href="" class="flex justify-end text-xss text-main font-bannerExtrasquare hover:underline">{{$t('lost_password') }}?</a> -->
          <InputFieldComponent :value="password" :label="$t('company_dashboard_edit_company_current_password')"
            type="password" v-model="password" class="" :error="v$.password.$error" @onBlur="v$.password.$touch()"
            :errorMessage="v$.password.$errors.length > 0 ? v$.password.$errors[0].$message : ''" />
        </div>
        <div class="mb-2">
          <div class="mt-4" v-if="isOtpRequired">
            <a @click.prevent="updateEmail"
              class=" cursor-pointer flex justify-end text-xss text-main font-bannerExtrasquare hover:underline">
              Send OTP Again
            </a>
          </div>
          <InputFieldComponent v-if="isOtpRequired" v-model="otp" label="OTP" type="text" :error="v$?.otp?.$error"
            :errorMessage="v$?.otp?.$errors.length > 0 ? v$?.otp?.$errors[0].$message : ''" @onBlur="" />
        </div>

      </div>

      <div class="flex mt-6" :class="{ 'hidden': isOtpRequired }" :disabled="v$.$invalid">
        <button :disabled="v$.$invalid || !isEmailChanged"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="updateEmail">{{ $t("update") }}</button>
      </div>

      <div class="flex mt-6" :class="{ 'hidden': !isOtpRequired }" :disabled="v$.$invalid">
        <button :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="saveOtp">{{ $t("update") }}</button>
      </div>
    </div>
    <Transition>
      <ConfirmYourEmailComponent :openConfirmEmailAddress="openConfirmEmailAddress"
        @toggleMessage="openConfirmEmailAddress = false" />
    </Transition>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import useValidate from '@vuelidate/core'
import { useStore } from 'vuex';
import { required, email, requiredIf, minLength, sameAs } from '@vuelidate/validators'
import IconClose from "@/components/icons/IconClose.vue";
import ConfirmYourEmailComponent from "@/components/common/ConfirmYourEmailComponent.vue";
export default {
  name: 'email',
  components: {
    QuillEditor,
    InputFieldComponent,
    IconClose,
    ConfirmYourEmailComponent
  },
  data() {
    return {
      v$: useValidate(),
      store: useStore(),
      isOtpRequired: false,
      isEmailChanged: false,
      errors: [],
      editing: false,
      email: '',
      otp: null,
      password: '',
      openConfirmEmailAddress: false
    };
  },
  methods: {

    resetInputs() {
      this.password = null;
      this.isOtpRequired = false;
      this.isEmailChanged = false;
    },

    onEmailInput() {
      if (this.email === this.store.state.auth.companyUserData.email) {
        this.isEmailChanged = false;
      } else {
        this.isEmailChanged = true;
      }
    },
    activate() {
      this.editing = !this.editing;

      if (this.editing) {
        this.$parent.activateEmail();
      }
      this.getExistingValues();
    },
    saveOtp() {
      this.v$.$validate(); // Check all inputs
      if (!this.v$.$error) {
        this.isProcessing = true;
        this.store.dispatch('commons/companyUserEmailOtpVerification', {
          'otp': this.otp
        })
          .then(res => {
            console.log("Response", res);
            if (res.data.success) {
              this.editing = false;
              this.store.dispatch('auth/getUserData');
              this.isOtpRequired = false;
              this.resetInputs();
              this.$notify({
                group: "foo",
                title: "Success Message",
                text: res.data.result,
                success: true
              }, 10000); // 10s
            } else {
              this.isProcessing = false;
              this.errors = res.data.error;
              this.$notify({
                group: "foo",
                title: "Something went wrong",
                text: res.data.error,
                success: false
              }, 10000); // 10s
            }
          });
      }
    },
    updateEmail() {
      this.v$.$validate()
      if (!this.v$.$error || this.isOtpRequired) {
        this.isProcessing = true;
        this.store.dispatch('commons/updateCompanyUserEmail', {
          'email': this.email,
          'password': this.password
        })
          .then(res => {
            console.log("Response", res);
            if (res.data.success) {
              if (res.data.result.extras === 'otp') {
                this.isOtpRequired = true;
                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result.message,
                  success: true
                }, 10000); // 10s
              } else {
                this.editing = false;
                this.isOtpRequired = false;
                this.store.dispatch('auth/getUserData');
                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result,
                  success: true
                }, 10000); // 10s
              }
            } else {
              console.log("error");
              this.isProcessing = false;
              this.$notify({
                group: "foo",
                title: "Something went wrong",
                text: res.data.error,
                success: false
              }, 10000); // 10s
            }
          });
        // this.openConfirmEmailAddress = true
        // this.editing = false;
        // this.isOtpRequired = true;

      }
    },
    getExistingValues() {
      console.log("getting")
      this.email = this.store.state.auth.companyUserData.email
    }
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
    }
  }
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
