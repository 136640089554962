import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state = {
  email: '',

  isCompany: false,

  companyData: null,
  companyUserData: null,

  userData: null,

  loggedIn: false,
  loginToken: null,

};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
