<template>
  <div class="mb-5">
    <!-- Loading State -->
    <div v-if="isLoading" class="text-center py-5">
      <p>Loading...</p> <!-- You can replace this with a spinner or another indicator -->
    </div>

    <!-- Main Content - Only displayed when data is loaded -->
    <div v-else>
      <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
        <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36">
          {{ $t("tags_additional_information") }}
        </a>
        <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2 hidden md:block"></a>
        <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
          <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
          <span>{{ $t("edit") }}</span>
        </a>
      </div>

      <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
        <div>
          <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">
            {{ $t("tags_additional_information") }}
          </h1>
        </div>
        <div class="form grid grid-rows-6 md:grid-rows-none md:grid-cols-2 gap-4 mt-5">
          <InputFieldComponent v-model="tags.facebook_url" label="Facebook URL" type="text" :error="false"
            :stayMainColorLabel="true" @onBlur="" />
          <InputFieldComponent v-model="tags.twitter_url" label="Twitter URL" type="text" :error="false"
            :stayMainColorLabel="true" @onBlur="" />
          <InputFieldComponent v-model="tags.linkedin_url" label="LinkedIn URL" type="text" :error="false"
            :stayMainColorLabel="true" @onBlur="" />
          <InputFieldComponent v-model="tags.company_website" label="Company Website" type="text" :error="false"
            :stayMainColorLabel="true" @onBlur="" />
          <InputFieldComponent v-model="tags.keyWordSeparatedCommas" label="Keywords (Separated by commas)" type="text"
            :error="false" :stayMainColorLabel="true" @onBlur="" />
        </div>

        <div class="flex mt-6">
          <a href="#" class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded"
            @click.prevent="dispatchChanges">{{ $t("update") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputFieldComponent from "@/components/common/InputFieldComponent.vue";
import { useStore } from 'vuex';
import useValidate from '@vuelidate/core';
import { required, email, requiredIf, minLength, sameAs, url } from '@vuelidate/validators';
import store from "@/store";

export default {
  name: "Tags",
  components: {
    InputFieldComponent
  },
  data() {
    return {
      store: useStore(),
      v$: useValidate(),
      editing: false,
      isLoading: true,  // Track loading state
      tags: {
        facebook_url: '',
        twitter_url: '',
        linkedin_url: '',
        company_website: '',
        keyWordSeparatedCommas: ''
      },
      vuelidateExternalResults: {}
    };
  },
  watch: {
    // Watch for changes in company data and update the form fields when available
    '$store.state.auth.companyData': function (newData) {
      if (newData) {
        this.tags = {
          facebook_url: newData.facebook_url || '',
          twitter_url: newData.twitter_url || '',
          linkedin_url: newData.linkedin_url || '',
          company_website: newData.company_website || '',
          keyWordSeparatedCommas: newData.keywords || ''
        };
        this.isLoading = false;
      }
    }
  },
  created() {
    // Initial check in case the data is already loaded in the store
    if (this.store.state.auth.companyData) {
      this.tags = {
        facebook_url: this.store.state.auth.companyData.facebook_url || '',
        twitter_url: this.store.state.auth.companyData.twitter_url || '',
        linkedin_url: this.store.state.auth.companyData.linkedin_url || '',
        company_website: this.store.state.auth.companyData.company_website || '',
        keyWordSeparatedCommas: this.store.state.auth.companyData.keywords || ''
      };
      this.isLoading = false;
    }
  },
  methods: {
    activate() {
      this.editing = !this.editing;
      if (this.editing) {
        this.$parent.activateTags();
      }
    },

    dispatchChanges() {
      this.v$.$validate();  // Validate form inputs

      const tagsData = {};

      if (!this.v$.$error) {
        // Collect the tags data
        for (const [key, value] of Object.entries(this.tags)) {
          if (value != null) {
            tagsData[key] = value;
          }
        }

        // Send the tags data if there are valid values
        if (Object.keys(tagsData).length > 0) {
          this.store.dispatch('commons/setCompanyTagsAndLinks', tagsData)
            .then(res => {
              if (res.data.success) {
                this.store.dispatch('auth/getPackages');
                this.editing = false;

                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result,
                  success: true
                }, 4000);  // Display notification
              }
            })
            .catch((err) => {
              const errors = { tags: err.response.data.errors };
              Object.assign(this.vuelidateExternalResults, errors);
            });
        } else {
          this.editing = false;
        }
      }
    }
  },
  validations() {
    return {
      tags: {
        // Example of adding some validations for the URLs
        facebook_url: { $params: { url: true }, $message: 'Invalid URL' },
        twitter_url: { $params: { url: true }, $message: 'Invalid URL' },
        linkedin_url: { $params: { url: true }, $message: 'Invalid URL' },
        company_website: { $params: { url: true }, $message: 'Invalid URL' }
      }
    };
  }
};
</script>

<style scoped>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
