<template>
    <div>
        <div class="flex w-full">
            <div class="relative z-0 w-full group bg-white flex-1">
                <select @blur="$emit('onBlur')" v-model="value" name="selectField"
                    class="block pt-2.5 px-5 w-full text-sm text-gray-900 bg-transparent border opacity-80 appearance-none  focus:outline-none focus:ring-0 focus:border-main font-bannerExtrasquare font-light   cursor-pointer peer h-14"
                    :class="{ 'border-form': showBorder === true, 'rounded-l-md': checkSlot, 'rounded': !checkSlot, 'border-red-500 focus:border-red-500': error === true }"
                    required>
                    <option v-if="inputType == 'array'" class="select-options my-3" v-for="(field, index) in options"
                        :value="field.id" :key="field">{{ field.title }}</option>
                    <option v-else class="select-options my-3" v-for="(field, index) in options" :key="index">{{ field
                        }}</option>
                </select>
                <label for="selectField" class="select-field-label  inline-block w-fit max-w-[80%] truncate"
                    :class="{ '-translate-y-4 text-[10px] ': value, 'text-red-500 peer-focus:text-red-500': error === true }">{{
                    label }}</label>
            </div>
            <slot name="icon"></slot>
        </div>
        <small v-if="error && errorMessage" class="text-xs text-red-500">{{ errorMessage }}</small>
    </div>
</template>
<script>
import IconStreetView from '../icons/IconStreetView.vue';
import { useSlots } from 'vue';
export default {

    props: ['label', 'options', 'showBorder', 'value', 'error', 'errorMessage', 'inputType'],
    components: { IconStreetView },
    emits: ['updateValue', 'onBlur'],

    watch: {
        value() {
            this.$emit('update:updateValue', this.value)
        }
    },
    computed: {
        checkSlot() {
            const slot = useSlots();
            return slot.icon ? true : false
        }
    }
}

</script>