<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('my_competencies')"/>
        
        <div class="mt-7 mb-12">
            <!--Preview Mode Start-->
            <div v-if="editing === false">
                <div class="flex items-center max-w-[50%] justify-between">
                    <div>
                        <p class="text-xs text-granite-gray">ადაპტირება</p>
                    </div>
                    <div class="flex items-center gap-2">
                        <IconStarFilled />
                        <IconStarFilled />
                        <IconStarFilled />
                        <IconStar />
                        <IconStar />
                    </div>
                </div>
                <div class="flex items-center max-w-[50%] justify-between">
                    <div>
                        <p class="text-xs text-granite-gray mt-3">ამბიცია</p>
                    </div>
                    <div class="flex items-center gap-2">
                        <IconStarFilled />
                        <IconStarFilled />
                        <IconStarFilled />
                        <IconStar />
                        <IconStar />
                    </div>
                </div>
            </div>
            <!--Preview Mode End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>

                    <div class="flex flex-col md:flex-row md:items-center gap-3">
                        <SelectInputComponent :label="$t('my_competencies')" :showBorder="true"
                            v-model:updateValue="field.myCompetencies" :value="field.myCompetencies"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="md:max-w-[50%]"/>
                        <div class="flex items-center gap-2 md:ml-10">
                            <IconStar v-for="(n, index) in 5" @click="field.rating = index + 1"
                                :class="{ '[&_path]:fill-main': index < field.rating }" />
                        </div>
                    </div>
                </div>
                <div class="my-7 lg:my-14">
                    <AddButton @addMore="addMore()"/>
                </div>
            </div>
            <!--Edit Mode End-->
        </div>
        <button v-if="isResumePreview === false" @click="editing = !editing"
            class="edit-update-btn">
            {{ editing === true ? $t('update') : $t('edit') }}</button>
    </div>
</template>
<script>
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconStar from '@/components/icons/IconStar.vue';
import IconStarFilled from '@/components/icons/IconStarFiled.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';

export default {
    name: 'MyCompetencies',
    components: { SelectInputComponent, IconStar, IconStarFilled, IconPlus, IconMinus, HeadingComponent, AddButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,

            fieldsArray: [
                {
                    myCompetencies: '',
                    rating: null,
                }
            ]
        }
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    myCompetencies: '',
                    rating: null,
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    }
}
</script>