<template>
  <div class="w-full">
    <div class="bg-white flex px-5 md:px-9 pt-5 mb-3">
      <!-- <div>
        <h1 class="font-bannerExtrasquareCaps text-2xl">{{ $t('company_about') }}</h1>
      </div>
      <span class="block ml-1">
        <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
      </span> -->
      <HeadingComponent :heading="$t('company_about')"/>
    </div>

    <div class="bg-white flex flex-col px-5 md:px-9 py-5 md:grid md:grid-cols-3 gap-8">
      <div class="font-bannerExtrasquare text-signed md:col-span-2 text-justify order-2 md:order-1">
        <div v-html="$root.store.getters['auth/companyData']('about_company')"></div>
        <a href="/" class="text-xs underline text-main font-bannerExtrasquare">{{ $t('see_more') }} ...</a>
      </div>
      <div class="flex-row justify-center order-1 md:order-2">
        <div class="flex-row justify-center">
          <img src="@/assets/Images/temp/temp_logo_1.png" alt="" class="rounded w-24 h-24 object-contain m-auto" />

          <h1 class="text-lg text-signed text-center font-bannerExtrasquare my-4 mb-8 mx-auto">
            {{$root.store.getters['auth/companyData']('company_name') }}
          </h1>
        </div>
        <ul class="text-signed text-xs">
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/bag-icon.png" class="w-4 h-4 my-auto mr-3" alt="" />
            <span class="my-auto">{{$root.store.getters['auth/companyData']('company_name') }}</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/maps-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
            <span class="my-auto">{{$root.store.getters['auth/companyData']('country') }}</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/maps-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
            <span class="my-auto">{{$root.store.getters['auth/companyData']('address_1') }}</span>
          </li>
          <li class="flex mb-3">
            <div class="w-4 mr-3"></div>
            <span class="my-auto">{{$root.store.getters['auth/companyData']('address_2') }}</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/mail-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
            <span class="my-auto">{{$root.store.getters['auth/companyData']('email') }}</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/call-answer-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
            <span class="my-auto">{{$root.store.getters['auth/companyData']('phone_number') }}</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/eye-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
            <span class="my-auto">5</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/people-icon.png" class="w-4 h-4 my-auto mr-3" alt="" />
            <!-- <span class="my-auto">{{$root.store.getters['auth/companyData']('users').length }}</span> -->
          </li>
        </ul>

        <ul class="my-10 flex">
          <li class="flex mr-3">
            <a href="#" v-if="$root.store.getters['auth/companyData']('facebook_url')">
              <img src="@/assets/Images/icons/fb-icon.png" alt="" />
            </a>
          </li>
          <li class="flex mr-3 fbb" v-if="$root.store.getters['auth/companyData']('linkedin_url')">
            <a href="#">
              <img src="@/assets/Images/icons/in-icon.png" alt="" />
            </a>
          </li>
          <li class="flex mr-3" v-if="$root.store.getters['auth/companyData']('twitter_url')">
            <a href="#">
              <img src="@/assets/Images/icons/tw-icon.png" alt="" />
            </a>
          </li>
          <li class="flex mr-3" v-if="$root.store.getters['auth/companyData']('instagram_url')">
            <a href="#">
              <img src="@/assets/Images/icons/ig-icon.png" alt="" />
            </a>
          </li>
          <li class="flex" v-if="$root.store.getters['auth/companyData']('facebook_url')">
            <a href="#">
              <img src="@/assets/Images/icons/ml-icon.png" alt="" />
            </a>
          </li>
        </ul>

        <div class="flex">
          <h3 class="text-main text-xs font-roboto cursor-pointer">{{ $t('resume_general_last_modified') }} {{$root.store.getters['auth/companyData']('updated_at') }}</h3>
        </div>

        <div class="flex my-9">
          <ul class="flex gap-3 flex-wrap">
            <li class="rounded border border-main flex-row text-main  p-1 justify-center  text-center flex-1">
              <div class="font-bannerExtrasquareCaps mx-auto text-2xl">{{$root.store.getters['auth/companyData']('countJobs') }}</div>
              <div class="font-bannerExtrasquareCaps text-xxs">გამოქვეყნებული სამსახურები</div>
            </li>
            <li class="rounded border border-main flex-row text-main  p-1 justify-center  text-center flex-1">
              <div class="font-bannerExtrasquareCaps mx-auto text-2xl">0</div>
              <div class="font-bannerExtrasquareCaps text-xxs">კანდიდატმა წაიკითხა</div>
            </li>
            <li class="rounded border border-main flex-row text-main  p-1 justify-center  text-center flex-1">
              <div class="font-bannerExtrasquareCaps mx-auto text-2xl">{{$root.store.getters['auth/companyData']('matched') }}</div>
              <div class="font-bannerExtrasquareCaps text-xxs mt-2">MATCHES</div>
            </li>
          </ul>
        </div>
        <!-- End flex my-9 -->

        <h3 class="text-sm mt-9 mb-3 text-signed font-bannerExtrasquare">Company Tags</h3>
        <div class="flex mb-9">
          <ul class="flex flex-wrap">
            <li class="tagsList" v-if="$root.store.getters['auth/companyData']('tags')" v-for="tag in $root.store.getters['auth/companyData']('tags')">
              <div class="font-bannerExtrasquareCaps text-xs">#{{tag.name }}</div>
            </li>
            <li class="tagsList" v-else>
              <router-link to="/dashboard/edit-company" class="">Set Company Tags</router-link>
              <!-- <div class="font-bannerExtrasquareCaps text-xs">#{{tag.name }}</div> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import { useRouter } from 'vue-router';

export default {
    name: "AboutMain",
    components: { HeadingComponent },
    mounted(){
      const router = useRouter()
      // if(this.$root.store.getters['auth/companyUserData']('name') == null ||this.$root.store.getters['auto/companyData']('phone_number') == null) {
      //   router.push({path: 'edit-company'})
      // }

      // console.log(this.$root.store.getters['auth/companyData']('tags').length);
    }
};
</script>
