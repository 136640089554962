<template>
    <div class="bg-white px-3 py-2 lg:px-9 lg:py-4 w-full">
        <div class="grid grid-cols-10 items-center">
            <div class="col-span-10 lg:col-span-4">
                <slot></slot>
                <div class="flex gap-3 items-center mt-2" v-if="showDeleteVacancy === true">
                    <div>
                        <label
                            class="container text-light-grey text-xs font-normal block relative cursor-pointer  select-none pl-5 font-bannerExtrasquare">{{ $t('all') }}
                            <input type="checkbox" checked="checked" v-model="checkedAll">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="flex items-center gap-2">
                        <div class="cursor-pointer">

                            <svg xmlns="http://www.w3.org/2000/svg" width="7.507" height="9.433"
                                viewBox="52.016 0 7.507 9.433">
                                <g data-name="Group 1403">
                                    <path
                                        d="M52.972 8.978a.486.486 0 0 0 .493.455h4.609c.264 0 .482-.2.493-.455l.33-6.68h-6.255l.33 6.68Zm3.84-5.021c0-.107.09-.193.2-.193h.321c.11 0 .2.086.2.193v3.817c0 .106-.09.193-.2.193h-.32c-.111 0-.201-.087-.201-.193V3.957Zm-1.404 0c0-.107.09-.193.201-.193h.32c.111 0 .201.086.201.193v3.817c0 .106-.09.193-.2.193h-.321c-.11 0-.2-.087-.2-.193V3.957Zm-1.403 0c0-.107.09-.193.2-.193h.321c.11 0 .2.086.2.193v3.817c0 .106-.09.193-.2.193h-.32c-.111 0-.201-.087-.201-.193V3.957Z"
                                        fill="#36a8e0" fill-rule="evenodd" data-name="Path 1070" />
                                    <path
                                        d="M59.205.486h-2.183V.099c0-.054-.048-.099-.107-.099h-2.292c-.058 0-.106.045-.106.1v.386h-2.183c-.176 0-.318.133-.318.298v.936h7.507V.784c0-.165-.143-.298-.318-.298Z"
                                        fill="#36a8e0" fill-rule="evenodd" data-name="Path 1071" />
                                </g>
                            </svg>
                        </div>
                        <p class="text-main text-xs font-normal font-bannerExtrasquare">{{ $t('company_dashboard_edit_company_remove') }} (1) {{ $t('home_job') }}</p>
                    </div>
                </div>
            </div>
            <div class="mt-3 lg:mt-0 col-span-10  lg:col-span-6 flex flex-col sm:flex-row gap-3">
                <div class="flex-1 border border-sub-main flex rounded bg-job-search-bg">
                    <div class="flex-1 py-2 pl-3 pr-2 ">
                        <input type="text" :placeholder="$t('search')+'...'" v-model="search"
                            class="w-full bg-transparent focus:outline-none text-still-blue text-xs font-normal  placeholder-still-blue" />
                    </div>
                    <div class="py-[9px] flex items-center px-4 bg-slate-blue">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.066" height="14.066">
                            <g data-name="Group 2009">
                                <path
                                    d="M12.297 1.769A6 6 0 0 0 8.027 0a6 6 0 0 0-4.27 1.769 6 6 0 0 0-1.77 4.27c0 1.475.526 2.869 1.488 3.969L.12 13.362a.413.413 0 0 0 .583.583l3.354-3.354a5.994 5.994 0 0 0 3.969 1.487 6 6 0 0 0 4.27-1.768 6 6 0 0 0 1.77-4.27 6 6 0 0 0-1.77-4.271Zm-.583 7.957a5.22 5.22 0 0 1-7.374 0 5.22 5.22 0 0 1 0-7.374A5.197 5.197 0 0 1 8.027.828c1.335 0 2.67.508 3.687 1.524a5.22 5.22 0 0 1 0 7.374Z"
                                    fill="#fff" fill-rule="evenodd" data-name="Path 1198" />
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="flex-1">
                    <DatePickerComponent @updateDate="getDate" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePickerComponent from '@/components/common/DatePickerComponent.vue';

export default {
    name: 'SubHeader',
    props: ['checkedAll', 'showDeleteVacancy'],
    emits: ['updateSearch', 'updateDate'],
    components: { DatePickerComponent },

    data() {
        return {
            search: ''
        }
    },
    methods: {
        getDate(date) {
            this.$emit('updateDate', date)
        }
    },
    watch: {
        search() {
            this.$emit('updateSearch', this.search)
        }
    }
}
</script>