import router from '@/router';
import axios from 'axios';

export default {

  async getUserData({ commit }) {
    if (localStorage.isCompany == 'true') {
      console.log('company repopulation');
      await axios.get('/company').then((res) => {
        commit('setCompanyData', res.data.result);
      });
      await axios.get('/company/user').then((res) => {
        commit('setCompanyUserData', res.data.result);
      });
    } else {
      await axios.get('/user').then((res) => {
        commit('setUserData', res.data.result);
      });
    }
  },
  async getPackages({ commit }) {

    if (localStorage.isCompany == 'true') {
      console.log("company repopulation")
      await axios.get("/company").then(res => {
        commit('setCompanyData',res.data.result)
      });
    } else {
      await axios.get("/user").then(res => {
        commit('setUserData',res.data.result)
      });
    }

  },

  async login({ commit }, payload) {
    return await axios.post('/login', payload).then((res) => res);
  },

  async forgotPassword({ commit }, payload) {
    return await axios.post('/password/recovery', payload).then((res) => res);
  },

  async forgotPasswordOtpCheck({ commit }, payload) {
    return await axios.post('/password/check-otp', payload).then((res) => res);
  },

  async registerCandidate({commit}, payload) {
		return await axios.post("/register/candidate", payload).then(res => {
			return res;
		});
	},

  async registerCompany({commit}, payload) {
		return await axios.post("/register/company", payload).then(res => {
			return res;
		});
	},

  async logout({commit}, payload) {

    
    // setTimeout(()=>{
    //   commit('logout');
    // }, 1000);
    return await axios.get("/logout", payload).then(res => {
      localStorage.clear();
      commit('resetToken');
      setTimeout(()=>{
        commit('logout');
        window.location.href = '/';
      }, 1000);
			return res;

		});
  },

  async authCheck({ commit }, payLoad) {
    return await axios.get('/authCheck').then((res) => res);
  },

};
