<template>
    <div>
        <button class=" hidden lg:flex items-center border-2 border-main rounded px-6 py-2 bg-main" @click="addMore()">
            <IconPlus class="[&_path]:fill-white w-8 h-8"/>
            <p class="text-base font-bannerExtrasquare font-medium text-white">{{ $t('add_field') }}</p>
        </button>
        <button class="bg-main p-2 rounded lg:hidden" @click="addMore()">
            <IconPlus class="[&_path]:fill-white w-8 h-8" />
        </button>
    </div>
</template>
<script>
import IconPlus from '../icons/IconPlus.vue';
export default {
    emits: ['addMore'],
    components: {
        IconPlus
    },
    methods: {
        addMore(){
            this.$emit('addMore');
        }
    }
}
</script>