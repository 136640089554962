<template>
    <div class="m-auto relative mt-10 min-w-[120px] min-h-[120px] max-w-[120px] max-h-[120px]">
        <div class="rounded-full pl-[52px] bg-opacity-75 bg-black w-fit m-auto pr-11 py-11">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="1449 456 24 32">
                <g data-name="Group 233">
                    <path d="m1472 472-22 15v-30l22 15Z" fill="#eef7ff" fill-rule="evenodd" data-name="Path 74" />
                    <path
                        d="M1450 488a.999.999 0 0 1-1-1v-30a.999.999 0 0 1 1.564-.826l22 15a1.001 1.001 0 0 1-.001 1.652l-22 15a1.002 1.002 0 0 1-.563.174Zm1-29.107v26.215L1470.225 472 1451 458.893Z"
                        fill="#eef7ff" fill-rule="evenodd" data-name="Path 75" />
                </g>
            </svg>
        </div>
        <button v-if="isEditing === true" class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2  cursor-pointer" @click="videoEditor()">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1446.999 339.95 21 21">
                <g data-name="Group 2295">
                    <path d="M1446.999 350.4a10.5 10.5 0 1 1 0 .1z" fill="#36a8e0" fill-rule="evenodd"
                        data-name="Ellipse 23" />
                    <g data-name="Group 257">
                        <g data-name="Group 256">
                            <g data-name="Path 85">
                                <path
                                    d="M1461.09 344.6a1.443 1.443 0 0 0-1.102-.145c-.375.1-.688.341-.882.677l-4.352 7.537a.346.346 0 0 0-.044.135l-.306 2.744a.346.346 0 0 0 .548.316l2.223-1.637a.345.345 0 0 0 .095-.105l4.351-7.537c.4-.694.162-1.584-.531-1.984Zm-5.911 10.239.16-1.433 1 .578-1.16.855Zm1.665-1.363-1.318-.76 3.492-6.049 1.318.761-3.492 6.048Zm4.178-7.237-.34.59-1.318-.76.34-.591a.756.756 0 0 1 .463-.355.756.756 0 0 1 .577.076.756.756 0 0 1 .354.462.756.756 0 0 1-.076.578Z"
                                    fill="#fff" fill-rule="evenodd" />
                                <path
                                    d="M1461.09 344.6a1.443 1.443 0 0 0-1.102-.145c-.375.1-.688.341-.882.677l-4.352 7.537a.346.346 0 0 0-.044.135l-.306 2.744a.346.346 0 0 0 .548.316l2.223-1.637a.345.345 0 0 0 .095-.105l4.351-7.537c.4-.694.162-1.584-.531-1.984Zm-5.911 10.239.16-1.433 1 .578-1.16.855Zm1.665-1.363-1.318-.76 3.492-6.049 1.318.761-3.492 6.048Zm4.178-7.237-.34.59-1.318-.76.34-.591a.756.756 0 0 1 .463-.355.756.756 0 0 1 .577.076.756.756 0 0 1 .354.462.756.756 0 0 1-.076.578Z"
                                    stroke-linejoin="round" stroke-linecap="round" stroke-width=".5" stroke="#fff"
                                    fill="transparent" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
        <p v-if="isEditing === true" class="text-main text-xs font-bannerExtrasquare text-center mt-4">{{ $t('change_video') }}</p>
    </div>
</template>
<script>
export default {
    props: ['isEditing'],
    emits: ['toggleVideoEditor'],

    methods: {
        videoEditor(){
            this.$emit('update:toggleVideoEditor', true)
        }
    }
}
</script>