<template>
  <div class="w-full">
    <div class="bg-white flex px-5 md:px-9 pt-5 mb-3">
      <!-- <div>
        <h1 class="font-bannerExtrasquareCaps text-2xl">{{ $t('my_profile') }}</h1>
      </div>
      <span class="block ml-1">
        <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
      </span> -->
      <HeadingComponent :heading="$t('my_profile')"/>
    </div>

    <div class="bg-white flex flex-col px-5 md:px-9 py-5 md:grid md:grid-cols-3 gap-8">
      <div class="font-bannerExtrasquare text-signed md:col-span-2 text-justify order-2 md:order-1" >
        <div v-html="$root.store.getters['auth/userResume']"></div>
        <!-- ჩემს შესახებ <br /><br />
        is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
        of type and scrambled it to make a type specimen book. <br />
        <br />
        It has survived not only five centuries, but also the leap into electronic typesetting,
        remaining essentially unchanged. It was popularised in the 1960s with the release of
        Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
        software like Aldus PageMaker including versions of Lorem Ipsum. <br />
        <br />
        is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
        of type and scrambled it to make a type specimen book. <br />
        <br /> -->

        <a href="/" class="text-xs underline text-main font-bannerExtrasquare">ნახე მეტი ...</a>

        <div class="flex w-full mt-20 justify-between">
          <carousel class="w-full mx-auto" :items-to-show="1" v-model="currentSlide" snapAlign="center" autoplay="2500" :breakpoints="breakpoints">
            <slide v-for="(carousel, index) in carousels" :key="carousel">
              <div class="bg-sec-nav px-11 py-7 w-44">
                <img v-if="carousel.value < 0"
                  :src="carousel.image"
                  class="w-7 h-9 m-auto"
                  alt=""
                />
                <h2 v-else class="w-9 h-9 m-auto text-center text-5xl text-main">{{ carousel.value }}</h2>
                <h3 class="text-main mt-4 text-xs font-bannerExtrasquareCaps uppercase">
                  <span  v-if="index == 0">{{ $t('nav_my_resume') }}</span>
                  <span  v-if="index == 1">{{ $t('myVideo') }}</span>
                  <span  v-if="index == 2">{{ $t('resume_matches') }}</span>
                </h3>
              </div>
              <!-- endItem -->
            </slide>

            <template #addons>
              <navigation class="text-main hidden md:block xl:hidden" />
              <!-- <pagination class="mt-5 xl:hidden" /> -->
            </template>
          </carousel>
        </div>
        <div class="flex justify-center items-center gap-1 mt-3 md:hidden pb-3">
          <div v-for="(slide, index) in carousels" :key="index" :class="{ 'active': currentSlide === index }"
            @click="currentSlide = index"
            class="w-3 h-3 bg-slider-btn-inactive rounded-full cursor-pointer [&.active]:bg-slider-btn-active">
          </div>
        </div>
      </div>
      <div class="flex-row justify-center order-1 md:order-2">
        <div class="flex-row justify-center">
          <img
            src="@/assets/Images/temp/temp_logo_1.png"
            alt=""
            class="rounded w-24 h-24 object-contain m-auto"
          />

          <h1 class="text-lg text-signed text-center font-bannerExtrasquare my-4 mb-8 mx-auto">
            L.P.S. International
          </h1>
        </div>
        <ul class="text-signed text-xs">
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/man-icon.png" class="w-4 h-4 my-auto mr-3" alt="" />
            <span class="my-auto">{{ $root.store.getters['auth/fullUserName'] }}</span>
          </li>
          <li class="flex mb-3">
            <img
              src="@/assets/Images/icons/portfolio-icon.png"
              class="w-4 h-4 my-auto mr-3"
              alt=""
            />
            <span class="my-auto">გრაფიკული დიზაინერი</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/resume-icon.png" class="w-4 h-4 my-auto mr-3" alt="" />
            <span class="my-auto">დიზაინი, მარკეტინგი, რეკლამა</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/maps-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
            <span class="my-auto">თბილისი, საქართველო</span>
          </li>
          <li class="flex mb-3">
            <img
              src="@/assets/Images/icons/passport-icon.png"
              class="w-4 h-5 my-auto mr-3"
              alt=""
            />
            <span class="my-auto">საქართველოს მოქალაქე</span>
          </li>
          <li class="flex mb-3">
            <img
              src="@/assets/Images/icons/gender-girl-icon.png"
              class="w-4 h-5 my-auto mr-3"
              alt=""
            />
            <span class="my-auto">მდედრობითი</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/bday-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
            <span class="my-auto">{{ $root.store.getters['auth/userData']('age') }} წლის</span>
          </li>

          <li class="flex mb-3">
            <img src="@/assets/Images/icons/maps-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
            <span class="my-auto">Georgia, Tbilisi Kakheti Highway</span>
          </li>
          <li class="flex mb-3">
            <div class="w-4 mr-3"></div>
            <span class="my-auto">Highway No112</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/mail-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
            <span class="my-auto">info@LPSInt.ge</span>
          </li>
          <li class="flex mb-3">
            <img
              src="@/assets/Images/icons/call-answer-icon.png"
              class="w-4 h-3 my-auto mr-3"
              alt=""
            />
            <span class="my-auto">{{ $root.store.getters['auth/userData']('mobile_number') }}  </span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/eye-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
            <span class="my-auto">ვიზიტორები 5</span>
          </li>
          <li class="flex mb-3">
            <img src="@/assets/Images/icons/cap-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
            <span class="my-auto">University of Georgia</span>
          </li>
        </ul>

        <ul class="my-10 flex">
          <li class="flex mr-3">
            <a href="#">
              <img src="@/assets/Images/icons/fb-icon.png" alt="" />
            </a>
          </li>
          <li class="flex mr-3">
            <a href="#">
              <img src="@/assets/Images/icons/in-icon.png" alt="" />
            </a>
          </li>
          <li class="flex mr-3">
            <a href="#">
              <img src="@/assets/Images/icons/tw-icon.png" alt="" />
            </a>
          </li>
          <li class="flex mr-3">
            <a href="#">
              <img src="@/assets/Images/icons/ig-icon.png" alt="" />
            </a>
          </li>
          <li class="flex">
            <a href="#">
              <img src="@/assets/Images/icons/ml-icon.png" alt="" />
            </a>
          </li>
        </ul>

        <div class="flex">
          <h3 class="text-main text-xs font-roboto cursor-pointer">ბოლო ცვლილება: {{ $root.store.getters['auth/userResumeData']('updated_at') }} </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import HeadingComponent from "@/components/common/HeadingComponent.vue";
export default {
  name: 'AboutMainCandidate',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    HeadingComponent
},
  data() {
    return {
      currentSlide: 0,
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: 'center-even',
        },
        // 1024 and up
        1280: {
          itemsToShow: 3,
          snapAlign: 'center-even',
        },
      },
      carousels: [
        { image: require('@/assets/Images/icons/resume-main-icon.png'), title: 'ჩემი რეზიუმე', value: -1 },
        { image: require('@/assets/Images/icons/video-icon.png'), title: 'ჩემი ვიდეო', value: -1 },
        { image: require('@/assets/Images/shared/profilePlaceholder.png'), title: 'MATCHES', value: 0 },
      ],
    };
  },
};
</script>
