<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('resume_general_software_knowledge')" />

        <div>
            <!--Preview Start-->
            <div v-if="editing === false" class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_software_knowledge') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('level_of_knowledge') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('certificate') }}</td>

                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in softwareKnowledge" :key="index">
                            <td class="jobsTableRow table-border">{{ data.softwareKnowledge }}</td>
                            <td class="jobsTableRow table-border">{{ data.levelOfKnowledge }}</td>
                            <td class="jobsTableRow table-border">{{ data.certificate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Preview End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index" class="mb-6">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>

                    <div class="flex flex-col lg:flex-row items-center gap-4 ">
                        <SelectInputComponent :label="$t('resume_general_software_knowledge')" :showBorder="true"
                            v-model:updateValue="field.softwareKnowledge" :value="field.softwareKnowledge"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1 " />
                        <SelectInputComponent :value="field.levelOfKnowledge" :label="$t('level_of_knowledge')" :showBorder="true"
                            v-model:updateValue="field.levelOfKnowledge"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
                    </div>

                    <div class="mt-9">
                    
                    <RadioButton :value="field.isGraduated" v-model:updateValue="field.isGraduated" :button1="{
                        label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                    }" :button2="{
                    label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
                        }" />
                </div>
                </div>
                <div class="my-7 lg:my-14">
                    <AddButton @addMore="addMore()" />
                </div>

            </div>
            <!--Edit Mode End-->

        </div>
        <div class="mt-[30px] flex items-center gap-3">
            <div>
                <IconPdfFile />
                <p class="text-auth text-[8px] text-center">CV.pdf</p>
            </div>
            <div>
                <IconWordFile />
                <p class="text-auth text-[8px] text-center">CV.docx</p>
            </div>
            <div v-if="isResumePreview === true">
                <IconDownload />
            </div>
            <div v-if="editing === true">
                <IconAddDocument />
                <p class="text-main text-xs text-center">{{ $t('add_field') }}</p>
            </div>
        </div>
        <div v-if="isResumePreview === false" class="mt-8">
            <button @click="editing = !editing" class="edit-update-btn">
                {{ editing === true ? $t('update') : $t('edit') }}</button>
        </div>
    </div>
</template>
<script>
import IconPdfFile from '@/components/icons/IconPdfFile.vue';
import IconWordFile from '@/components/icons/IconWordFile.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import IconAddDocument from '@/components/icons/IconAddDocument.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';
import RadioButton from '@/components/common/RadioButton.vue';
export default {
    name: "SoftwareKnowledge",
    components: { IconPdfFile, IconWordFile, SelectInputComponent, IconPlus, IconMinus, IconAddDocument, IconDownload, HeadingComponent, AddButton,RadioButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,
            fieldsArray: []

        }
    },
    computed: {
        softwareKnowledge() {
            return [
                {
                    softwareKnowledge: 'MS Excel',
                    levelOfKnowledge: 'ექსპერტი',
                    certificate: 'დიახ'
                },
                {
                    softwareKnowledge: 'ITIL Information Technology Infrastructure Library',
                    levelOfKnowledge: 'მოწინავე',
                    certificate: 'დიახ'
                },
                {
                    softwareKnowledge: 'Adobe Illustrator',
                    levelOfKnowledge: 'ექსპერტი',
                    certificate: 'დიახ'
                },

            ]
        },
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    softwareKnowledge: '',
                    levelOfKnowledge: '',
                    isGraduated: null
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    },
    mounted(){
        this.addMore();
    }
}
</script>
<style></style>