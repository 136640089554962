<template lang="">
  <div class="bg-white py-4 container items-center m-auto">
      <carousel :items-to-show="1"  :breakpoints="breakPoint" class=" "  :snapAlign="'start'" :wrapAround="true" >
        <slide v-for="slide in logos" :key="slide" class="">
          <div class="flex h-20 m-auto items-center">
            <a :href="slide.url" target="_blank" class="flex">
              <img :src="slide.icon" alt="Profile placeholder" class="m-auto py-5 h-24 object-contain ">
            </a>
          </div>
        </slide>
        <template #addons>
          <navigation class="hidden" />
          <pagination class="hidden"/>
        </template>
</carousel>
</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'PartnerLogos',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      logos: [
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/3dr-1.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/axius.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/centos.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/google-marketing-platform.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/hubspot-1.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/mastercard-7.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/microsoft-teams.png') },
        { url: 'http://google.com', icon: require('@/assets/Images/shared/logos/microsoft.png') },
      ],
      breakPoint: {
        // 700px and up
        768: {
          itemsToShow: 3,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
        },
        1280: {
          itemsToShow: 7,
        },

      },
    };
  },
};
</script>
