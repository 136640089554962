<template>
  <div class="w-full">
    <!-- <div class="bg-white flex px-9 py-5 mb-3">
      <div>
        <h1 class="font-bannerExtrasquareCaps text-2xl">კომპანიის რედაქტირება</h1>
      </div>
      <span class="block ml-1">
        <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
      </span>
    </div> -->
    <div class="bg-white flex px-5 md:px-9 pt-5 mb-3">
      <!-- <h1 class="heading-h1">
        {{$t('nav_edit_company')}}
      </h1>
      <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span> -->
      <HeadingComponent :heading="$t('nav_edit_company')"/>
    </div>

    <div class="bg-white flex-row px-5 md:px-9 py-5 md:py-16">

      <AboutInfo ref="about" />
      <Tags ref="tags" />
      <TCPhotos ref="photos" @show-profile-update-message="displayProfileUpdateMessage()" />


    </div>
    <Transition>
      <UpdateDataMessageComponent :showProfileUpdateMessage="showProfileUpdateMessage"
        message="თქვენი პროფილი განახლებულია!" @toggleMessage="showProfileUpdateMessage = false" />
    </Transition>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import AboutInfo from "./edit/AboutInfo.vue";
import Tags from "./edit/Tags.vue";
import TCPhotos from "./edit/TeamAndCompanyPhotos.vue";
import HeadingComponent from "@/components/common/HeadingComponent.vue";
import IconClose from "@/components/icons/IconClose.vue";
import UpdateDataMessageComponent from "@/components/common/UpdateDataMessageComponent.vue";

export default {
  name: "EditCompany",
  components: {
    QuillEditor,
    AboutInfo,
    Tags,
    TCPhotos,
    HeadingComponent,
    IconClose,
    UpdateDataMessageComponent
},
  data() {
    return {
      editingAbout: false,
      editing: false,
      showProfileUpdateMessage: false
    };
  },
  methods: {
    scrollToTop(){
      window.scrollTo({
        top:0,
        behavior:'smooth'
      })
    },
    activateAbout() {
      this.$refs.tags.editing = false;
      this.$refs.photos.editing = false;
      console.log('active about')
      this.scrollToTop()
    },
    activateTags() {
      this.$refs.about.editing = false;
      this.$refs.photos.editing = false;
      console.log('active tags')
      this.scrollToTop()
    },
    activatePhotos() {
      this.$refs.tags.editing = false;
      this.$refs.about.editing = false;
      console.log('active photos')
      this.scrollToTop()
    },
    displayProfileUpdateMessage() {
      this.showProfileUpdateMessage = true;
      this.scrollToTop()
    }
  },
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
