<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class=" bg-white sticky top-0 z-50"> <!-- Content wrapper -->
      <div class=" items-center m-auto">
        <!-- Mobile nav-->
        <NavMobile @signInEvent="enableAuthForm" />
        <!-- Nav Mobile extendable end -->
        <!-- Desktop Nav -->
        <NavDesktop @signInEvent="enableAuthForm" />
        <!-- end Desktop nav -->
      </div>
  </div>
  <!-- Content -->
  <router-view></router-view>

  <PartnerLogos />

  <div class="bg-footer py-4 " >
      <div class="container items-center m-auto hidden lg:block">
          <div class="grid grid-cols-4 border-b py-5">
              <div class="col-span-3"></div>
              <div>
                  <router-link to="/" class="">
                    <img src="@/assets/Images/shared/logo.png" class="" alt="">
                  </router-link>
              </div>
          </div>
          <div class="grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-3 pt-8 text-white pb-14 border-b">
              <div>
                  <h3 class="font-bannerExtrasquareCaps  text-lg mb-2">{{ $t('for_candidate') }}</h3>
                  <ul class="opacity-75">
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">რეზიუმეს შექმნა</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">საიტის პირობები</a></li>
                  </ul>
              </div>
              <div>
                  <h3 class="font-bannerExtrasquareCaps  text-lg mb-2">კომპანიისთვის</h3>
                  <ul class="opacity-75">
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">ვაკანსიის განთავსება</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">ტარიფები</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">საიტის პირობები</a></li>
                  </ul>
              </div>
              <div>
                  <h3 class="font-bannerExtrasquareCaps  text-lg mb-2">აირჩიე ქვეყანა</h3>
                  <ul class="opacity-75">
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">საქართველო</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">ჰოლანდია</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">რუსეთი</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">უკრაინა</a></li>
                  </ul>
              </div>
              <div>
                  <h3 class="font-bannerExtrasquareCaps  text-lg mb-2">ჩვენ შესახებ</h3>
                  <ul class="opacity-75">
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">კომპანიის შესახებ</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">ბლოგი</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">საიტის რუქა</a></li>
                      <li><a class="font-bannerExtrasquare text-sm block py-1" href="#">კონტაქტი</a></li>
                  </ul>
              </div>
          </div>
          <div class="grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-3 p-5 text-white ">
              <div class="col-span-3"></div>
              <div class="flex items-center">
                  <div class="flex mt-3">
                      <h3 class="m-auto font-bannerExtrasquareCaps  text-lg mb-2 ">შემოგვიერთდით</h3>
                  </div>
                  <ul class="flex m-auto">
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/Facebook.png" alt="FB Icon"></a>
                      </li>
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/Linkedin.png" alt="Linkedin Icon"></a>
                      </li>
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/Instagram.png" alt="IG Icon"></a>
                      </li>
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/twitter.png" alt="Twitter icon"></a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="">
          <div class="lg:hidden pb-10">
              <div class="flex-row items-center">
                  <div class="flex mt-3">
                      <h3 class="m-auto font-bannerExtrasquareCaps text-white text-lg mb-2 ">შემოგვიერთდით</h3>
                  </div>
                  <ul class="flex justify-center ">
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/Facebook.png" alt="FB Icon"></a>
                      </li>
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/Linkedin.png" alt="Linkedin Icon"></a>
                      </li>
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/Instagram.png" alt="IG Icon"></a>
                      </li>
                      <li class="mr-1">
                          <a href="#"><img src="@/assets/Images/shared/twitter.png" alt="Twitter icon"></a>
                      </li>
                  </ul>
                  <div class="flex justify-center mt-5">
                    <router-link to="/">
                      <img src="@/assets/Images/shared/logo.png" class="" alt="">
                    </router-link>
                  </div>
              </div>
          </div>
          <div class="container mx-auto">
              <div class="grid grid-rows-3 lg:grid-cols-3 border-t pt-9">
                  <div></div>
                  <div class="flex justify-center text-white">
                      Copyright BelinQed © 2019. All Rights Reserved
                  </div>
                  <div class="flex justify-center lg:justify-end">
                      <div class="text-white font-bannerExtrasquareCaps"  >
                          Powered by <a href="#" class="text-main">Coding-arts.com</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <AuthForm ref="authform"/>

  <NotificationsGroup />

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import AuthForm from '@/components/AuthForm.vue';
import NavMobile from '@/components/NavMobile.vue';
import NavDesktop from '@/components/NavDesktop.vue';
import PartnerLogos from '@/components/PartnerLogos.vue';
import NotificationsGroup from '@/components/NotificationsGroup.vue';

import {
  mapGetters, mapMutations, mapState, useStore,
} from 'vuex';

export default {
  name: 'AppVue',
  data() {
    return {
      email: 'sandro@antidze.com', // testing
      store: useStore(),
    };
  },
  components: {
    AuthForm,
    NavMobile,
    NavDesktop,
    PartnerLogos,
    NotificationsGroup,
  },
  created() {
    // todo re-populate state
    // console.log(this.store.getters['auth/isLoggedIn'])

    const html = document.documentElement; // returns the html tag
    html.setAttribute('lang', 'en');

    if (localStorage.token != undefined) {
      console.log('Created creating', localStorage.token);

      // Re populate state
      this.store.dispatch('auth/getUserData');
    } else {
      console.log('Undefineed');
    }
  },
  methods: {
    enableAuthForm() {
      setTimeout(() => {
        this.$refs.authform.showForm = true;
      }, 10);
    },

    // login() {
    //   // testing func only
    //   this.$store.commit('login', this.email);
    // },

    // logout() {
    //   this.$store.commit('logout');
    //   this.$router.push('/');
    // },

    // // todo remove
    // setActiveProfile(status) {
    //   this.$store.commit('setCompany', status);
    //     // this.$router.push('/');
    //     // this.$router.go()

    //     window.location.href = '/';

    // },
  },
  mounted() {
    this.$store.dispatch('commons/getLocale');
    // this.$store.commit('initalizeStore');
  },
};
</script>
