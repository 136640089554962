<template>
  <div>
    <div class="w-full mt-9 bg-white md:p-12">
      <div class="bg-white">
        <div class="flex items-center mb-3 gap-3 p-3 md:p-0">
          <h3
            class="text-nav text-lg sm:text-[22px] font-medium inline-block w-fit truncate font-bannerExtrasquareCaps">
            {{ $t('pictures_of_office_and_team') }}
          </h3>
          <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span>
        </div>

        <div :class="{ 'hidden': $root.store.getters['auth/companyData']('members').length == 0 }">
          <div class="flex w-full mb-5"
            :class="{ hidden: $root.store.getters['auth/companyData']('office_images').length === 0 }">
            <Carousel :items-to-show="2" :wrap-around="true" v-model="currentSlide" :mouseDrag="false"
              class="customer-feedback-carousel w-full">
              <Slide v-for="(img, index) in $root.store.getters['auth/companyData']('office_images')" :key="index">
                <div class="mx-2">
                  <img class="m-auto h-full w-full" :src="`${mainWebsiteUrl}storage/${img.image}`" alt="" />
                </div>
              </Slide>
              <template #addons>
                <Navigation class="text-main h-8 hidden md:block" />
              </template>
            </Carousel>

          </div>

          <div class="flex justify-center items-center gap-1 mt-3 md:hidden">
            <div v-for="(slide, index) in officeImages" :key="index" :class="{ active: currentSlide === index }"
              @click="currentSlide = index"
              class="w-3 h-3 bg-slider-btn-inactive rounded-full cursor-pointer [&.active]:bg-slider-btn-active"></div>
          </div>
        </div>

        <div class="flex w-full" :class="{ 'hidden': $root.store.getters['auth/companyData']('members').length == 0 }">
          <Carousel :items-to-show="3" :wrap-around="true" v-model="currentMemberSlide" :mouse-drag="false"
            class="customer-feedback-carousel w-full">
            <Slide v-for="(member, index) in $root.store.getters['auth/companyData']('members')" :key="index"
              class="flex justify-center items-center px-4 my-4">
              <div class="bg-white shadow-lg rounded-lg overflow-hidden max-w-sm w-full">
                <!-- Container for image and button -->
                <div class="relative bg-gray-100 mt-24">
                  <!-- Centered Circular Image -->
                  <img
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 object-cover rounded-full shadow-lg"
                    :src="mainWebsiteUrl + 'storage/' + member.image" alt="Member Image" />

                  <!-- Centered Play Button -->
                  <button
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2  bg-main text-white rounded-full shadow-md p-4 flex items-center justify-center w-16"
                    aria-label="Play Button" @click="toggleVideo(mainWebsiteUrl + 'storage/' + member.video)">
                    <!-- Play Icon -->
                    <IconVideoPlay class="w-5 h-7 text-white" />
                  </button>
                </div>

                <!-- Member Info -->
                <div class="p-6 mt-44">
                  <h3 class="text-lg font-bold text-gray-800 text-center">
                    {{ member.name }} {{ member.lastName }}
                  </h3>
                  <p class="text-sm text-gray-600 text-center">
                    {{ member.position }}
                  </p>
                  <p class="text-xs text-gray-500 mt-2 text-center">
                    {{ member.about }}
                  </p>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation class="text-main mt-4" />
            </template>
          </Carousel>
        </div>


        <div class="flex justify-center items-center gap-1 mt-3 md:hidden pb-3">
          <div v-for="(member, index) in teamMembers" :key="index" :class="{ active: currentMemberSlide === index }"
            @click="currentMemberSlide = index"
            class="w-3 h-3 bg-slider-btn-inactive rounded-full cursor-pointer [&.active]:bg-slider-btn-active"></div>
        </div>
      </div>
    </div>

    <div class="bg-white mt-10 pt-6">
      <SubHeader :show-delete-vacancy="false" @update-search="updateSearch" @update-date="getDate">
        <div class="flex items-center mr-3 gap-2">
          <h1
            class="text-nav text-lg sm:text-[22px] font-medium inline-block w-fit truncate font-bannerExtrasquareCaps">
            {{ $t('list_of_vacancies') }}
          </h1>
          <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span>
        </div>
      </SubHeader>

      <div class="overflow-x-scroll lg:overflow-auto jobsTableContainer customHeight">
        <table class="table-auto w-full">
          <thead>
            <tr>
              <td class="table-border jobsTableHeader order-2">{{ $t('date') }}</td>
              <td class="table-border jobsTableHeader order-1">{{ $t('id_code') }}</td>
              <td class="table-border jobsTableHeader order-3">{{ $t('location') }}</td>
              <td class="table-border jobsTableHeader order-4">{{ $t('position') }}</td>
              <td class="table-border jobsTableHeader order-5">{{ $t('sector') }}</td>
              <!-- <td class="table-border jobsTableHeader order-6">{{ $t('service_experience') }}</td> -->
              <!-- <td class="table-border jobsTableHeader order-7">{{ $t('position_level') }}</td> -->
              <td class="table-border jobsTableHeader order-8">{{ $t('hours_of_service') }}</td>
              <td class="table-border jobsTableHeader order-9">{{ $t('resume_general_education_direction') }}</td>
              <td class="table-border jobsTableHeader order-10">{{ $t('company_job_detail_education_level') }}</td>
              <td class="table-border jobsTableHeader order-11">{{ $t('salary') }}</td>
              <td class="table-border jobsTableHeader order-12"></td>
            </tr>
          </thead>
          <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
            <tr v-for="job in jobList" :key="job.idCode">
              <td class="table-border jobsTableRow order-1">{{ job.created_at }}</td>
              <td class="table-border jobsTableRow order-2">{{ job.id }}</td>
              <td class="table-border jobsTableRow order-3">{{ job.job_location ? getCityFromPlaceId(job.job_location) :
                ''
              }}</td>
              <td class="table-border jobsTableRow order-4">{{ job.job_title.title }}</td>
              <td class="table-border jobsTableRow order-5">{{ job.job_sector.title }}</td>
              <!-- <td class="table-border jobsTableRow order-6">{{ job.workingExperience }}</td> -->
              <!-- <td class="table-border jobsTableRow order-7">{{ job.jobLevel }}</td> -->
              <td class="table-border jobsTableRow order-8">{{ job.job_hours.name }}</td>
              <td class="table-border jobsTableRow order-9">{{ job.education_field.name }}</td>
              <td class="table-border jobsTableRow order-10">{{ job.education_level.name }}</td>
              <td class="table-border jobsTableRow order-11">{{ job.job_salary.name }}</td>
              <td class="table-border jobsTableRow order-12">
                <div v-click-away="job.dropdownActive = false" class="relative group">
                  <a href="#" class="px-2 py-3 bg-main flex rounded w-8"
                    @click.prevent="job.dropdownActive = !job.dropdownActive">
                    <img class="m-auto w-9 h-2.5" src="@/assets/Images/icons/arrow-down-icon.png" alt="" />
                    <div class="group-hover:flex" :class="{ flex: job.dropdownActive, hidden: !job.dropdownActive }">
                      <ul class="absolute transition-transform top-8 z-40 right-0 border-main">
                        <li class="border-x border-main first:border-b first:border-t last:border-b">
                          <a href="#"
                            class="px-6 py-3 block text-main bg-custom-main font-bannerExtrasquareCaps text-xs hover:bg-main hover:text-white">
                            {{ $t('view') }}
                          </a>
                        </li>
                        <li>
                          <a href="#"
                            class="px-6 py-3 block text-main bg-custom-main font-bannerExtrasquareCaps text-xs hover:bg-main hover:text-white">
                            {{ $t('apply') }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <VideoPlayerPopup :isVisible="isVideoPopupVisible" :videoUrl="selectedVideoUrl"
    @update:isVisible="isVideoPopupVisible = $event" />
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import SubHeader from '@/components/common/SubHeader.vue';
import VideoPlayerPopup from "@/components/company/sub-pages/edit/VideoPlayerPopup.vue";
import IconVideoPlay from "@/components/icons/IconVideoPlay.vue";
export default {
  name: "About",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    SubHeader,
    VideoPlayerPopup,
    IconVideoPlay
  },
  data() {
    return {
      date: [],
      search: '',
      currentMemberSlide: 0,
      mainWebsiteUrl: process.env.VUE_APP_AXIOS_BASE_DOMAIN,
      currentSlide: 0,
      selectedVideoUrl: null,
      isVideoPopupVisible: false,
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: "center-even",
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: "center-even",
        },
      },

      officeImages: [

      ],
      teamMembers: [

      ],

      jobList: [],
      cityCache: {}, // Cache for place ID to city mapping
    };
  },
  methods: {
    getCityFromPlaceId(placeId) {
      // Return cached value if available
      if (this.cityCache[placeId]) {
        return this.cityCache[placeId];
      }

      // Return the original value if no placeId or Google Maps API is not available
      if (!placeId || !window.google || !google.maps || !google.maps.places) {
        return placeId;
      }

      // Create a new PlacesService
      const service = new google.maps.places.PlacesService(document.createElement('div'));

      // Get place details (this is async, so we update the UI after it's fetched)
      service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          // Extract city name from address components
          let cityName = '';
          if (place.address_components) {
            for (const component of place.address_components) {
              if (component.types.includes('locality') || component.types.includes('administrative_area_level_1')) {
                cityName = component.long_name;
                break;
              }
            }
          }

          // If we found a city name, cache it and update the UI
          if (cityName) {
            this.cityCache[placeId] = cityName;
            // Force a re-render (only needed for the specific job)
            this.$forceUpdate();
          } else {
            // If no city found, use the formatted address or place name
            this.cityCache[placeId] = place.formatted_address || place.name || placeId;
            this.$forceUpdate();
          }
        }
      });

      // Initially return the place ID, will be updated after API response
      return placeId;
    },
    toggleVideo(videoUrl) {
      this.selectedVideoUrl = videoUrl;
      this.isVideoPopupVisible = !this.isVideoPopupVisible; // Toggle the visibility state
    },
    getDate(date) {
      this.date = date
    },
    updateSearch(search) {
      this.search = search;
    },
    fetchJobList() {
      const jobs = this.$root.store.getters['auth/companyData']('jobs') || [];
      this.jobList = jobs.map(job => ({
        ...job,
        dropdownActive: false
      }));
    }
  },
  mounted() {
    let customHeight = document.querySelector(".customHeight");
    customHeight.style.height = customHeight.offsetHeight + 200 + "px";
    this.fetchJobList();

    // Load Google Maps API if not already loaded
    if (!window.google || !google.maps || !google.maps.places) {
      // You may need to load the Google Maps API here if it's not already included in your app
      console.warn('Google Maps API not loaded. City information may not be available.');
    }
  },
  watch() {

  }
};
</script>
<style>
.carousel__prev {
  margin-left: -10px !important;
}

.carousel__next {
  margin-right: -10px !important;
}
</style>
