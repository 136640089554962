<template>
  <div class="w-full">
    <!-- <div class="bg-white px-9 py-5 mb-3">
      <div class="grid grid-cols-4 gap-2">
        <div class=" flex col-span-2">
          <div>
            <h1 class="font-bannerExtrasquareCaps text-2xl">კანდიდატები</h1>
          </div>
          <span class="block ml-1">
            <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
          </span>
        </div>
        <div class="flex justify-end">
          <input type="text" class="font-bannerExtrasquare text-xss text-main border rounded-l-lg border-sub-main pl-2 w-44 placeholder:text-placeholder-mainon  focus-visible:outline-none" placeholder="კანდიდატის ძიება"> 
          <a href="#" class=" text-white px-2 py-1 rounded-r-lg bg-sub-main flex">
            <img class="m-auto" src="@/assets/Images/icons/magnifier_icon.png" alt="" />
          </a>
        </div>
        <div class="flex">
          <div class="border rounded-lg border-sub-main flex">
            <img src="@/assets/Images/icons/calendar-icon.png" class="w-2 h-2 ml-2 my-auto" alt="">
            <input type="text" class="font-bannerExtrasquare text-xss rounded-r-lg text-main  pl-2 w-52 text-center h-8 focus-visible:outline-none" placeholder="March 14, 2018-April 15, 2018"> 
            <img src="@/assets/Images/icons/arrow-down-icon-colorful.png" class="w-2 h-1 mr-2 my-auto" alt="">
          </div>
        </div>
      </div>
      


    </div> -->

    <SubHeader @updateSearch="updateSearch" @updateDate="getDate" :show-delete-vacancy="true">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-2">
          <h1 class="heading-h1">
            {{ $t("company_dashboard_tabs_job_candidates") }}
          </h1>
          <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span>

        </div>
        <div class=" flex lg:hidden">
          <button @click="openFilterMenu = true">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4"
                d="M8.16 6.6499H15.83C16.47 6.6499 16.99 7.1699 16.99 7.8099V9.0899C16.99 9.5599 16.7 10.1399 16.41 10.4299L13.91 12.6399C13.56 12.9299 13.33 13.5099 13.33 13.9799V16.4799C13.33 16.8299 13.1 17.2899 12.81 17.4699L12 17.9799C11.24 18.4499 10.2 17.9199 10.2 16.9899V13.9099C10.2 13.4999 9.97 12.9799 9.73 12.6899L7.52 10.3599C7.23 10.0799 7 9.5499 7 9.1999V7.8699C7 7.1699 7.52 6.6499 8.16 6.6499Z"
                stroke="#36A8E0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#36A8E0"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </SubHeader>
    <Candidate v-for="(candidate, index) in candidates" :key="index" :name="candidate.name" :position="candidate.position"
      :address="candidate.address" :time="candidate.time" v-model:showChatBoard="candidate.showChatBoard"
      v-model:addToFavorites="candidate.addToFavorites"
      @toggle-chat-board="candidate.showChatBoard = !candidate.showChatBoard" />
    <div class="flex justify-center items-center gap-2 mt-6">
      <div class="w-3 h-3 bg-slider-btn-inactive rounded-full " v-for="(page, index) in pages"></div>
    </div>
  </div>

  <div :class="{ 'w-0': openFilterMenu === false, 'w-screen': openFilterMenu === true }"
    class="fixed top-0 pt-16  left-0 bottom-0 z-40 right-0 bg-default h-screen overflow-y-auto transition-[width] easy-in-out duration-300">
    <div class="p-4 pt-10  flex items-center justify-between sticky border-b border-sec-nav-border bg-white top-0 z-40">
      <p class="pl-4 font-bannerExtrasquare text-lg md:text-2xl ">{{ $t('manual_search') }}</p>
      <button class="mr-1">
        <IconClose class="w-3 h-3" @click="openFilterMenu = false" />
      </button>
    </div>
    <CandidatesFilterComponent />
    <div class="flex justify-center items-center mt-6 sticky bg-white bottom-0 left-0 right-0 py-2">
      <button class="bg-main text-white font-bannerExtrasquareCaps text-base rounded py-3 px-11 ">{{ $t('start_searching') }}
      </button>
    </div>
  </div>
</template>

<script >
import Candidate from "./candidate/Candidate.vue";
import SubHeader from "@/components/common/SubHeader.vue";
import CandidatesFilterComponent from "../CandidatesFilterComponent.vue";
import IconClose from '@/components/icons/IconClose.vue';
export default {
  name: "EditCompany",
  components: {
    Candidate,
    SubHeader,
    CandidatesFilterComponent,
    IconClose
  },
  data() {
    return {
      date: [],
      search: "",
      editingAbout: false,
      editing: false,
      candidates: [],
      pages: [1, 2, 3, 4],
      openFilterMenu: false

    };
  },
  methods: {
    getDate(date) {
      this.date = date;
    },
    updateSearch(search) {
      this.search = search;
    },
    getCandidates() {
      this.candidates = [
        {
          name: 'გიორგი ბაქრაძე',
          position: 'მარკეტინგის მენეჯერი',
          address: 'Tbilisi, Georgia',
          time: '2019-11-12 15:39:38',
          showChatBoard: false,
          addToFavorites: false

        },
        {

          name: 'გიორგი ბაქრაძე',
          position: 'მარკეტინგის მენეჯერი',
          address: 'Tbilisi, Georgia',
          time: '2019-11-12 15:39:38',
          showChatBoard: true,
          addToFavorites: false
        },
        {

          name: 'გიორგი ბაქრაძე',
          position: 'მარკეტინგის მენეჯერი',
          address: 'Tbilisi, Georgia',
          time: '2019-11-12 15:39:38',
          showChatBoard: false,
          addToFavorites: false
        },
        {

          name: 'გიორგი ბაქრაძე',
          position: 'მარკეტინგის მენეჯერი',
          address: 'Tbilisi, Georgia',
          time: '2019-11-12 15:39:38',
          showChatBoard: false,
          addToFavorites: false
        },
        {

          name: 'გიორგი ბაქრაძე',
          position: 'მარკეტინგის მენეჯერი',
          address: 'Tbilisi, Georgia',
          time: '2019-11-12 15:39:38',
          showChatBoard: false,
          addToFavorites: false
        },

      ].map((job, jobIdx) => ({ ...job, id: jobIdx }))
    },
  },
  mounted() {
    this.getCandidates();
  },
};
</script>
