<template>
  <!-- Login form -->
  <div>
    <div class="mt-5 mx-8 flex-row py-8 md:flex">
      <div class="my-4 h-5 flex items-center ">
        <div class="w-5 my-auto">
          <input type="radio" value="user"
            class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" id="user"
            v-model="signIn.userType">
        </div>
        <label for="user" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
          {{ $t('applicant') }}
        </label>
      </div>
      <div class="my-4 h-5 flex items-center md:ml-5">
        <div class="w-5 my-auto">
          <input type="radio" class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer"
            value="company" id="company" v-model="signIn.userType">
        </div>
        <label for="company" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
          {{ $t('company') }}
        </label>
      </div>
    </div>
    <div class="pb-5 mx-8 flex-row">
      <form action="">
        <div class="mt-4" v-if="emailIsSent">
          <a @click.prevent="reSendOtp"
            class=" inline cursor-pointer flex justify-end text-xss text-main font-bannerExtrasquare hover:underline">
            Send OTP Again
          </a>
        </div>
        <!-- Use v-model for email and OTP -->
        <InputFieldComponent v-model="signIn.email" :label="$t('email')" type="email" :error="v$.signIn.email.$error"
          @onBlur="v$.signIn.email.$touch()"
          :errorMessage="v$.signIn.email.$errors.length > 0 ? v$.signIn.email.$errors[0].$message : ''" />

        <div class="mt-4 mb-5">
          <InputFieldComponent v-if="emailIsSent" v-model="signIn.otp" label="OTP" type="text"
            :error="v$.signIn.otp.$error"
            :errorMessage="v$.signIn.otp.$errors.length > 0 ? v$.signIn.otp.$errors[0].$message : ''" @onBlur="" />
        </div>

        <div class="flex items-center mb-4 text-center" :class="{ 'hidden': emailIsSent }">
          <button :disabled="v$.$invalid || isProcessing"
            class="block py-3 w-full font-bannerExtrasquareCaps bg-main text-white rounded hover:bg-button-icon disabled:bg-opacity-60 disabled:cursor-not-allowed"
            @click.prevent="sendOtp">{{ isProcessing ? 'Processing ...' : 'Continue' }}</button>
        </div>

        <div class="flex items-center mb-4 text-center" :class="{ 'hidden': !emailIsSent }">
          <button :disabled="v$.$invalid || isProcessing"
            class="block py-3 w-full font-bannerExtrasquareCaps bg-main text-white rounded hover:bg-button-icon disabled:bg-opacity-60 disabled:cursor-not-allowed"
            @click.prevent="verifyOtp">{{ isProcessing ? 'Processing ...' : 'Reset Password' }}</button>
        </div>

        <div class="flex items-center  font-bannerExtrasquareCaps text-xss md:text-sm mb-5">
          <div class="text-input">
            თქვენ მიიღებთ დადასტურების კოდს ელ-ფოსტაზე, გთხოვთ შეიყვანოთ OTP ველში
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, email, requiredIf } from '@vuelidate/validators'
import InputFieldComponent from '../common/InputFieldComponent.vue';
import { useStore } from 'vuex';
export default {
  name: 'ForgotPasswordComponent',
  components: {
    InputFieldComponent
  },
  data() {
    return {
      isProcessing: false,
      emailIsSent: false,
      v$: useValidate(),
      signIn: {
        userType: 'user',
        email: '',
        otp: null
      }
    };
  },
  methods: {
    sendOtp() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isProcessing = true;
        this.$store.dispatch('auth/forgotPassword', this.signIn)
          .then(res => {
            if (res.data.success) {
              this.emailIsSent = true;
              this.isProcessing = false;
              this.$notify({ group: "foo", title: "Success", text: res.data.result, success: true });
            } else {
              this.isProcessing = false;
              this.emailIsSent = false;
              this.$notify({ group: "foo", title: "Error", text: res.data.error, success: false });
            }
          })
      }
    },
    reSendOtp() {
      this.sendOtp();
    },
    verifyOtp() {
      if (!this.v$.$error) {
        this.isProcessing = true;
        this.$store.dispatch('auth/forgotPasswordOtpCheck', this.signIn)
          .then(res => {
            if (res.data.success) {
              this.emailIsSent = true;
              this.isProcessing = false;
              this.$parent.setLoginEmail(this.signIn.email);
              this.activateLogin();
            } else {
              this.isProcessing = false;
              this.$notify({ group: "foo", title: "Error", text: res.data.error, success: false });
            }
          })
      }
    },
    activateLogin() {
      this.$parent.activateLogin();
    }
  },
  validations() {
    return {
      signIn: {
        email: { required, email },
        otp: {
          required: requiredIf(this.emailIsSent),
        }
      },
    };
  },
};
</script>
