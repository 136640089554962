<template>
    <div
        class="bg-white max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto py-6">
        <div class="bg-light-blue px-5 py-10 md:px-[30px]">
            <HeadingComponent :heading="$t('desired_position_and_candidates')" />

            <div class="mt-6 lg:mt-11 flex flex-col md:flex-row items-center gap-6 lg:gap-11">
                <SelectInputComponent :value="nameOfService" v-model:updateValue="nameOfService"
                    :label="$t('name_of_service_profession')" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                    class="flex-1" />
                <SelectInputComponent :value="opportunity" v-model:updateValue="opportunity"
                    :label="$t('opportunity_to_start')" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                    class="flex-1" />
            </div>
            <div class="mt-6 lg:mt-11 flex flex-col md:flex-row items-center gap-6 lg:gap-11">
                <SelectInputComponent :value="serviceSector" v-model:updateValue="serviceSector"
                    :label="$t('select_service_sector')" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                    class="flex-1" />
                <SelectInputComponent :value="desiredPay" v-model:updateValue="desiredPay" :label="$t('desired_pay')"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
            </div>
            <div class="mt-6 lg:mt-11 flex flex-col md:flex-row items-center gap-6 lg:gap-11">
                <SelectInputComponent :value="typeOfEmployment" v-model:updateValue="typeOfEmployment"
                    :label="$t('type_of_employment')" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                    class="flex-1" />
                <SelectInputComponent :value="experienceOfServiceAndPosition"
                    v-model:updateValue="experienceOfServiceAndPosition" :label="$t('experience_of_service_and_position')"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
            </div>
            <div class="mt-6 lg:mt-11 flex flex-col md:flex-row items-center gap-6 lg:gap-11">
                <SelectInputComponent :value="workingHours" v-model:updateValue="workingHours"
                    :label="$t('resume_general_job_hours')" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                    class="flex-1" />
                <SelectInputComponent :value="locationOfService" v-model:updateValue="locationOfService"
                    :label="$t('desired_location_of_service')" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                    class="flex-1" />
            </div>

            <div class="mt-8 lg:mt-12 h-1 bg-white w-full"></div>

            <div v-if="isResumePreview === false" class="flex items-center justify-center my-8 lg:my-12 gap-10">
                <div class="my-4 h-5 flex items-center ">
                    <div class="w-5 my-auto">
                        <input type="radio" value="turnOn"
                            class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" id="turnOn"
                            v-model="checkedValue">
                    </div>
                    <label for="turnOn" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
                        {{ $t('turn_on') }}
                    </label>
                </div>
                <div class="my-4 h-5 flex items-center ">
                    <div class="w-5 my-auto">
                        <input type="radio" value="hangUp"
                            class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" id="hangUp"
                            v-model="checkedValue">
                    </div>
                    <label for="hangUp" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
                        {{ $t('turn_off') }}
                    </label>
                </div>
            </div>
            <!--Review Start-->
            <div v-if="isResumePreview === true">
                <div class="flex justify-center items-center flex-col mb-8 mt-8">
                    <div class="flex items-center gap-2">
                        <IconUser />
                        <p class="px-3 py-1 bg-main text-xs rounded text-white">0</p>
                        <div class="flex items-center gap-1">
                            <IconStar v-for="(n, index) in 5" />
                        </div>
                    </div>
                    <p class="text-main text-xs mt-2">(0 {{ $t('review') }})</p>
                </div>
                <div class="max-w-2xl mx-auto">
                    <textarea :placeholder="$t('write_a_message') + '...'" class="textarea-field" />

                    <div class="flex justify-end mt-4">
                        <button class="edit-update-btn rounded-full">{{ $t('submit') }}</button>
                    </div>
                </div>
            </div>
            <!--Review End-->

            <div v-if="isResumePreview === false" class="flex justify-center mt-10">
                <button class="edit-update-btn">{{ $t('edit') }}</button>
            </div>

            <div class="mt-8 lg:mt-12 h-1 bg-white w-full"></div>
        </div>

        <!--Location Map Start-->
        <div class="mt-6">
            <div class="bg-default px-5 py-10 md:px-[30px]">
                <div class="flex items-center gap-3">
                    <h3 class="text-sm  sm:text-lg font-medium font-bannerExtrasquareCaps inline-block w-fit truncate ">
                        {{ $t('service_location') }}</h3>
                    <span class="text-main -mt-3 font-medium  cursor-pointer text-xs">[?]</span>
                </div>
                <div class="mt-6">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23824.313541428968!2d44.74961311990433!3d41.71967311641533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044734fbb28fd6d%3A0x6ef463850ea29c4!2sTbilisi%20Open%20Air%20Museum%20of%20Ethnography!5e0!3m2!1sen!2sin!4v1695040210512!5m2!1sen!2sin"
                            width="100%" height="450" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        <!--Location Map End-->
    </div>
</template>
<script>
import SelectInputComponent from '@/components/common/SelectInputComponent.vue'
import IconUser from '@/components/icons/IconUser.vue';
import IconStar from '@/components/icons/IconStar.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue'
export default {
    name: 'DesiredPosition',
    components: { SelectInputComponent, IconUser, IconStar, HeadingComponent },
    props: ['isResumePreview'],
    data() {
        return {
            nameOfService: '',
            opportunity: '',
            serviceSector: '',
            desiredPay: '',
            typeOfEmployment: '',
            experienceOfServiceAndPosition: '',
            workingHours: '',
            locationOfService: '',
            checkedValue: ''
        }
    }
}
</script>
<style>
select {
    background: url('@/assets/Images/icons/dropdown.png') no-repeat 98% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
</style>