import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";

import NotFoundComponent from "@/components/NotFound.vue";

import AboutCompany from "@/components/company/sub-pages/about/AboutMain.vue";
import AboutSecondary from "@/components/company/sub-pages/about/AboutSecondary.vue";
import EditCompany from "@/components/company/sub-pages/Edit.vue";
import EditCompanyUser from "@/components/company/sub-pages/EditUser.vue";
import CompanyCandidates from "@/components/company/sub-pages/Candidates.vue";
import CurrentSubscription from "@/components/company/sub-pages/subscription/CurrentSubscription.vue";
import MyPackage from "@/components/company/sub-pages/packages/MyPackage.vue";
import Packages from "@/components/company/sub-pages/packages/AllPackages.vue";
import CompanyMessages from "@/components/company/sub-pages/messages/Messages.vue";
import CreateService from "@/components/company/sub-pages/createService/CreateService.vue";
import CreatedService from "@/components/company/sub-pages/createdService/CreatedService.vue";

import Job from "@/components/company/Job.vue";

import EditCandidate from "@/components/candidate/sub-pages/Edit.vue";
import AboutCandidate from "@/components/candidate/sub-pages/About/AboutMain.vue";
import DashboardView from "../views/main/DashboardView.vue";
import AboutView from "../views/AboutView.vue";
import PriceView from "../views/PriceView.vue";
import HomeView from "../views/HomeView.vue";
import store from "../store/index";
import {useStore} from "vuex";

import MatchedJobs from "@/components/candidate/sub-pages/matched-jobs/MatchedJobs.vue";
import Messages from "@/components/candidate/sub-pages/messages/Messages.vue";
import AboutMe from "@/components/candidate/sub-pages/my-resume/AboutMe.vue";
import OtherDetails from "@/components/candidate/sub-pages/my-resume/OtherDetails.vue";
import DesiredPosition from "@/components/candidate/sub-pages/my-resume/DesiredPosition.vue";

import Resume from "@/components/candidate/Resume.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    alias: ["/verify/:id/:token/:userType"],
    meta: { title: "Belinqed" },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { auth: false },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { auth: true },
    children: [
      {
        path: "company",
        name: "company-dashboard",
        components: {
          default: AboutCompany,
          AboutSecondary,
        },
        meta: { company: true },
      },
      {
        path: "current-plan",
        component: CurrentSubscription,
        meta: { company: true },
      },
      {
        path: "edit-company",
        component: EditCompany,
        meta: { company: true },
      },
      {
        path: "edit-company-user",
        component: EditCompanyUser,
        meta: { company: true },
      },
      {
        path: "candidates",
        component: CompanyCandidates,
        meta: { company: true },
      },

      {
        path: "myPackage",
        component: MyPackage,
        meta: { company: true },
      },
      {
        path: "packages",
        component: Packages,
        meta: { company: true },
      },
      {
        path: "company-messages",
        component: CompanyMessages,
        meta: { company: true },
      },
      {
        path: "create-service",
        component: CreateService,
        meta: { company: true },
      },
      {
        path: "created-service",
        component: CreatedService,
        meta: { company: true },
      },

      // candidates
      {
        path: "candidate",
        name: "candidate-dashboard",
        component: AboutCandidate,
        meta: { company: false },
      },
      {
        path: "edit-candidate",
        component: EditCandidate,
        meta: { company: false },
      },
      {
        path: "candidate-jobs",
        name: "candidate-jobs",
        component: MatchedJobs,
        meta: { company: false },
      },
      {
        path: "messages",
        name: "messages",
        component: Messages,
        meta: { company: false },
      },
      {
        path: "my-resume",
        name: "my-resume",
        components: {
          default: AboutMe,
          OtherDetails,
          DesiredPosition,
        },
        meta: { company: false },
      },
    ],
  },
  // {
  //   path: '/pricing',
  //   name: 'pricing',
  //   component: PriceView,
  //   meta: {auth:true, company:true},
  // },
  {
    path: "/resume",
    name: "resume",
    component: Resume,
    meta: { auth: true },
  },
  {
    path: "/job",
    name: "job",
    component: Job,
    meta: { auth: true, company: true },
  },
  {
    /* Wildcard path to catch other paths */
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: NotFoundComponent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, scrollBehavior) {
    return { top: 0, behavior: "smooth" };
  },
});

// invalidate token
const self = this;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == 401) {
      localStorage.clear();
      router.push("/");
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (localStorage.token) {
    axios.get(`/authCheck?isCompany=${localStorage.isCompany}`).then((res) => {
      if (res.data.error) {
        localStorage.clear();
        router.push("/");
      }
    });
  }
  if (to.meta.auth) {
    if (!localStorage.token) {
      next("/");
    }
    if (to.meta.company) {
      if (localStorage.isCompany == "true") {
        next();
      } else {
        next("/");
      }
    } else if (localStorage.isCompany == "true") {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
