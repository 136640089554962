<template>
  <!-- Login form -->
  <div>
    <div class="mt-5 mx-8 flex-row py-8 md:flex">
      <div class="my-4 h-5 flex items-center ">
        <div class="w-5 my-auto">
          <input type="radio" value="user"
            class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" id="userApplicant"
            v-model="signIn.userType">
        </div>
        <label for="userApplicant" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
          {{ $t('applicant') }}
        </label>
      </div>
      <div class="my-4 h-5 flex items-center md:ml-5">
        <div class="w-5 my-auto">
          <input type="radio" class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer"
            value="company" id="userCompany" v-model="signIn.userType">
        </div>
        <label for="userCompany" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
          {{ $t('company') }}
        </label>
      </div>
    </div>
    <div class="pb-5 mx-8 flex-row">
      <form action="">
        <!-- <div class="relative z-0 w-full mb-9 group" :class="{'has-error':(v$.$anyDirty && v$.signIn.email.$error) || errors.email}">
          <input type="email" name="floating_email" class="formInputClass peer"  placeholder=" " required v-model="signIn.email" />
          <label for="floating_email" class="formLabelClass">{{ $t('email') }}</label>

          <div class="group"  v-if="v$.$anyDirty && v$.signIn.email.$error">
            <a href="#" class="absolute -right-5 top-1   " >
              <svg class="w-4 h-4" fill="none" stroke="#FC5757" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </a>
          </div>
        </div> -->
        <InputFieldComponent :value="signIn.email" :label="$t('email')" type="email" v-model="signIn.email" class=""
          :error="v$.signIn.email.$error" @onBlur="v$.signIn.email.$touch()"
          :errorMessage="v$.signIn.email.$errors.length > 0 ? v$.signIn.email.$errors[0].$message : ''" />

        <div class="mt-4">
          <a @click.prevent="forgotPasswordAction"
            class="cursor-pointer flex justify-end text-xss text-main font-bannerExtrasquare hover:underline">{{
              $t('lost_password') }}?</a>
          <!-- Password Field -->
          <InputFieldComponent :value="signIn.password" :label="$t('password')" type="password"
            v-model="signIn.password" class="" :error="v$.signIn.password.$error" @onBlur="v$.signIn.password.$touch()"
            :errorMessage="v$.signIn.password.$errors.length > 0 ? v$.signIn.password.$errors[0].$message : ''" />
        </div>
        <!-- <div class="relative z-0 w-full mb-9 group" :class="{'has-error':(v$.$anyDirty && v$.signIn.password.$error) || errors.email}">
          <input :type="showPassword ? 'text' : 'password'" name="floating_email" class="formInputClass peer" placeholder=" " required v-model="signIn.password" />
          <label for="floating_email" class="formLabelClass">{{ $t('password') }}</label>

          <svg @click="showPassword = !showPassword" class="w-6 h-6 absolute right-2 top-2.5 cursor-pointer" fill="none" stroke="#4E4E4E" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>

          <div class="group" v-if="v$.$anyDirty && v$.signIn.password.$error">
            <a href="#" class="absolute -right-5 top-1   " >
              <svg class="w-4 h-4" fill="none" stroke="#FC5757" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </a>
          </div>
        </div> -->
        <div class="flex items-center my-4">
          <input checked id="checkbox-1" type="checkbox" value=""
            class="w-4 h-4 text-main bg-gray-100 border-gray-300 rounded focus:ring-main indeterminate:bg-gray-300">
          <label for="checkbox-1"
            class="ml-2 text-xs font-medium text-input dark:text-gray-300 font-bannerExtrasquare ">გამოიწერე
            სიახლეები</label>
        </div>

        <div class="flex items-center mb-4 text-center">
          <button :disabled="v$.$invalid || isProcessing"
            class="block py-3 w-full font-bannerExtrasquareCaps bg-main text-white rounded hover:bg-button-icon disabled:bg-opacity-60 disabled:cursor-not-allowed"
            @click.prevent="login">{{ isProcessing ? 'Processing ...' : $t('sign_in') }}</button>
        </div>
        <div class="flex items-center  font-bannerExtrasquareCaps text-xss md:text-sm mb-5">
          <div class="text-input">

            {{ $t('agreement_part1') }}
            <a :href="'/signed/terms-' + $cookies.get('lang') + '.pdf'" target="_blank"
              class="ml-1 text-slate-grey hover:underline font-bannerExtrasquareCaps">
              {{ $t('agreement_part2') }}
            </a>
            {{ $t('and') }}
            {{ $t('agreement_part3') }}
            <a href="#" class="ml-1 text-slate-grey hover:underline font-bannerExtrasquareCaps"> {{
              $t('agreement_part4')
              }}</a>
          </div>
        </div>
      </form>
      <div class="mt-9 mb-10 flex flex-col items-center justify-center">
        <p class="text-xl mb-4 text-danim-blue font-bold">Sign Up with</p>
        <div class="flex items-center gap-3">
          <IconFb />
          <IconGoogle />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import IconFb from '@/components/icons/IconFb.vue';
import IconGoogle from '@/components/icons/IconGoogle.vue';
import InputFieldComponent from '../common/InputFieldComponent.vue';
import { mapGetters, mapMutations, mapState, useStore } from 'vuex';
import axios from 'axios'
export default {
  name: 'LoginComponent',
  components: {
    IconFb,
    IconGoogle,
    InputFieldComponent
  },
  data() {
    return {
      isProcessing: false,
      v$: useValidate(),
      showForm: true,
      showPassword: false,
      store: useStore(),
      loginActive: true,
      errors: [],
      form: {
        email: '',
        password: '',
      },
      signIn: {
        userType: 'user',
        email: '',
        password: '',
      },
    };
  },
  props: {
    msg: String,
  },
  computed: {
    successLoginMsg() {
      return this.$t('login_success');
    },
    loginFailedMsg() {
      return this.$t('login_fail');
    },
  },
  mounted() {
    // console.log(this.counter)
    // console.log(this.times2)
    // console.log(this.$store.commit('setCounter', this.times2));
    // console.log(this.counter)

    // const store = useStore()
    // this.store.dispatch('auth/get')
    // console.log("Logging")
    // console.log(this.$store.getters['auth/isLoggedIn'])
    // setTimeout(()=>{
    //   console.log(store.getters['auth/getList'])
    // }, 500);

    // setTimeout(()=>{
    //     store.commit('auth/addData', {"userId":1, "id": 201, "title":"Test Sandro title", "completed":false})
    //     // To get namespaced module getters
    //     console.log(store.getters['auth/getList'])

    // }, 2000)

  },
  methods: {
    forgotPasswordAction() {
      // this.$parent.$refs.forgotPassword.signIn.email = this.signIn.email;
      this.$parent.activateForgotPassword();
      // this.parent.activateForg = true;
    },
    login() {
      // this.store.dispatch('auth/login')

      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // this.$store.commit('login', this.signIn.email);

        this.isProcessing = true
        this.errors = [];
        // this.errors = {};
        // this.loading = true;
        this.store.dispatch('auth/login', this.signIn)
          .then(res => {
            console.log("Signing in", res);
            if (res.data.success) {
              this.isProcessing = false
              localStorage.token = res.data.result.token;
              localStorage.isCompany = res.data.result.isCompany;
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.result.token;

              const data = {
                email: this.signIn.email,
                token: res.data.result.token,
                isCompany: res.data.result.isCompany
              }
              this.store.commit('auth/login', data);
              this.store.dispatch('auth/getUserData');

              this.signIn.email = '';
              this.signIn.password = '';
              this.v$.$reset();
              this.$parent.showForm = false;

              if (data.isCompany) {

                this.$router.push({ name: 'company-dashboard' });
              } else {
                this.$router.push({ name: 'candidate-dashboard' });
              }
              this.$notify({
                group: "foo",
                title: this.successLoginMsg,
                text: res.data.error.email,
                success: true
              }, 2000) // 10s
              // if (res.data.result.isCompany)
              //       location = "/company/dashboard";
              //   else
              //       location = "/applicant/dashboard";
            } else {
              this.isProcessing = false
              this.errors = res.data.error
              this.$notify({
                group: "foo",
                title: this.loginFailedMsg,
                text: res.data.error.email,
                success: false
              }, 2000) // 10s
              // this.error
            }
          })
      }
    },
  },
  validations() {
    return {
      signIn: {
        email: { required, email },
        password: { required },
      },
    };
  },
};
</script>
