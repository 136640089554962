<template>
    <div class="w-full">
        <div class="bg-white py-4 px-6 gap-2 lg:px-12 flex items-center mb-3">
            <h1 class="heading-h1">
                გრაფიკული დიზაინერი </h1>
            <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span>
        </div>

        <!--About Company start-->
        <div class="flex flex-col sm:flex-row bg-white py-8 px-6 lg:px-12 gap-4">
            <div class=" flex-1 lg:flex-none lg:w-8/12">
                <p class="text-nav text-base lg:text-xl font-bannerExtrasquareCaps ">{{ $t('company_about') }}</p>
                <div class="mt-6">
                    <p class="job-content">
                        The debate about the ethical behaviour of organizations has taken place in administrative
                        practice as a way to survive paradigm of shifts and the hyper competition environment
                        experienced by companies in the western capitalist world and deregulated markets.
                    </p>
                    <p class="job-content">
                        The question of business ethics has long been considered of minor importance with the
                        justification that in the business world it is not always possible to make decisions with the
                        clarity of right and wrong, foresee and decide who will benefit or harmed.

                    </p>
                    <p class="job-content">
                        The company operates in an environment that is constantly changing due to social, market,
                    </p>
                    <button class="flex items-center">
                        <p class="text-main text-[8px]"> {{ $t('see_more') }} </p>
                        <div><svg width="15px" height="12px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 7L15 12L10 17" stroke="#36A8E0" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg></div>
                    </button>
                    <p class="text-nav mt-4 ml-3 text-[10px] sm:text-xs font-bannerExtrasquare ">გამოქვეყნდა: 20 იანვარი /
                        ბოლო ვადა: 20 თებერვალი</p>
                </div>
            </div>
            <div class="sm:flex-row sm:justify-center order-first sm:order-last sm:mx-auto">
                <div class="flex-row justify-center">
                    <img src="@/assets/Images/temp/temp_logo_1.png" alt=""
                        class="rounded w-24 h-24 object-contain m-auto" />

                    <h1 class="text-lg text-signed text-center font-bannerExtrasquare my-4 mb-6 mx-auto">
                        L.P.S. International
                    </h1>
                </div>
                <div class="text-signed text-xs">
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/bag-icon.png" class="w-4 h-4 my-auto mr-3" alt="" />
                        <span class="my-auto">L.P.S International</span>
                    </div>
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/maps-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
                        <span class="my-auto">თბილისი, საქართველო</span>
                    </div>
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/maps-icon.png" class="w-4 h-5 my-auto mr-3" alt="" />
                        <span class="my-auto">Georgia, Tbilisi Kakheti Highway</span>
                    </div>
                    <li class="flex mb-3">
                        <div class="w-4 mr-3"></div>
                        <span class="my-auto">Highway No112</span>
                    </li>
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/mail-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
                        <span class="my-auto">info@LPSInt.ge</span>
                    </div>
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/call-answer-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
                        <span class="my-auto">+995-555-555-555</span>
                    </div>
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/eye-icon.png" class="w-4 h-3 my-auto mr-3" alt="" />
                        <span class="my-auto">5</span>
                    </div>
                    <div class="flex mb-3">
                        <img src="@/assets/Images/icons/people-icon.png" class="w-4 h-4 my-auto mr-3" alt="" />
                        <span class="my-auto">1</span>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="flex mr-2">
                        <a href="#">
                            <img src="@/assets/Images/icons/fb-icon.png" class="w-5 h-5" />
                        </a>
                    </div>
                    <div class="flex mr-2">
                        <a href="#">
                            <img src="@/assets/Images/icons/in-icon.png" class="w-5 h-5" />
                        </a>
                    </div>
                    <div class="flex mr-2">
                        <a href="#">
                            <img src="@/assets/Images/icons/tw-icon.png" class="w-5 h-5" />
                        </a>
                    </div>
                    <div class="flex mr-2">
                        <a href="#">
                            <img src="@/assets/Images/icons/ig-icon.png" class="w-5 h-5" />
                        </a>
                    </div>
                    <div class="flex">
                        <a href="#">
                            <img src="@/assets/Images/icons/ml-icon.png" class="w-5 h-5" />
                        </a>
                    </div>
                </div>
                <div class="flex mt-3">
                    <p class="text-main text-[8px] font-roboto">{{ $t('resume_general_last_modified') }}: 22-11-2019</p>
                </div>
            </div>
            <!--  -->
        </div>
        <!--About Company End-->

        <!--Service Desc. Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">
            <div>
                <p class="text-nav text-base lg:text-xl font-bannerExtrasquareCaps mb-5">{{ $t('job_description') }}</p>
                <p class="job-content">
                    The debate about the ethical behaviour of organizations has taken place in administrative
                    practice as a way to survive paradigm of shifts and the hyper competition environment
                    experienced by companies in the western capitalist world and deregulated markets.
                </p>
                <p class="job-content">
                    The question of business ethics has long been considered of minor importance with the
                    justification that in the business world it is not always possible to make decisions with the
                    clarity of right and wrong, foresee and decide who will benefit or harmed.
                </p>
            </div>
            <div class="h-[1px] bg-gray-300 my-7"></div>
            <div class="bg-white  mb-3">
                <HeadingComponent :heading="$t('requirements')" />
                <div class="grid grid-cols-2 gap-8 w-full mt-6">
                    <div class="col-span-2 md:col-span-1 text-xs text-nav" v-for="(requirement, index) in requirements"
                        :key="index">
                        <p class="text-xs text-nav">{{ requirement.title }}</p>
                        <p class="mt-2 text-xs text-grey-58">{{ requirement.value }}</p>
                        <div class="h-[1px] bg-main mt-3"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--Service Desc. End-->

        <!--Education Block Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">
            <HeadingComponent :heading="$t('minimum_education_level_of_candidate')" />
            <div class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('education_direction') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('company_job_detail_education_level') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('having_diploma') }}</td>
                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in education" :key="index">
                            <td class="jobsTableRow table-border">{{ data.directionOfEducation }}</td>
                            <td class="jobsTableRow table-border">{{ data.levelOfEducation }}</td>
                            <td class="jobsTableRow table-border">{{ data.havingOfDiploma }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Education Block End-->

        <!--Software Knowledge Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">
            <HeadingComponent :heading="$t('software_knowledge')" />
            <div class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('software_knowledge') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('level_of_knowledge') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('having_diploma') }}</td>
                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in softwareKnowledge" :key="index">
                            <td class="jobsTableRow table-border">{{ data.knowledge }}</td>
                            <td class="jobsTableRow table-border">{{ data.levelOfKnowledge }}</td>
                            <td class="jobsTableRow table-border">{{ data.havingDiploma }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Software Knowledge Stop-->

        <!--Knowledge of Languages Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">
            <HeadingComponent :heading="$t('knowledge_of_languages')" />
            <div class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('knowledge_of_languages') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('level_of_conversation') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('writing_level') }}</td>
                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in knowledgeOfLanguages" :key="index">
                            <td class="jobsTableRow table-border">{{ data.language }}</td>
                            <td class="jobsTableRow table-border">{{ data.levelOfConversation }}</td>
                            <td class="jobsTableRow table-border">{{ data.writingLevel }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Knowledge of Languages End-->

        <!--Driving License Block Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">
            <HeadingComponent :heading="$t('create_job_driving_license_title')" />
            <h3
                class="border-t border-b border-main py-2 px-4 md:py-3 md:px-9 bg-alice-blue text-nav font-medium text-xs md:text-sm font-bannerExtrasquare">
                {{ $t('create_job_driving_license_title') }}</h3>
            <p class="text-xs font-bannerExtrasquare text-grey-58 px-4 md:px-9 mt-4">B - მსუბუქი მანქანა</p>
        </div>
        <!--Driving License Block End-->

        <!--Competencies Block Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">
            <HeadingComponent :heading="$t('my_competencies')" />
            <div class="mt-6">
                <h3
                    class="border-t border-b border-main py-2 px-4 md:py-3 md:px-9 bg-alice-blue text-nav font-medium text-xs md:text-sm font-bannerExtrasquare">
                    {{ $t('competencies_of_candidate') }}</h3>
                <div class="text-xs font-bannerExtrasquare text-grey-58 px-4 md:px-9">
                    <p class="mt-4">ანგარიშვალდებულება</p>
                    <p class="mt-4">პუნკტუალურობა</p>
                    <p class="mt-4">პუნკტუალურობა</p>

                </div>
            </div>
        </div>
        <!--Competencies Block Start-->

        <!--Location Map Start-->
        <div class="bg-white p-6 lg:p-12 mt-3">

            <HeadingComponent :heading="$t('service_location')" />
            <div class="mt-6">
                <div class="mt-6">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23824.313541428968!2d44.74961311990433!3d41.71967311641533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044734fbb28fd6d%3A0x6ef463850ea29c4!2sTbilisi%20Open%20Air%20Museum%20of%20Ethnography!5e0!3m2!1sen!2sin!4v1695040210512!5m2!1sen!2sin"
                        width="100%" height="450" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        <!--Location Map End-->
    </div>
</template>
<script>
import HeadingComponent from "../../../common/HeadingComponent.vue"
export default {
    name: "CreatedService",
    components: {
        HeadingComponent,
    },
    data() {
        return {}
    },
    computed: {
        requirements() {
            return [
                {
                    title: 'სამსახურის/ფროფესიის დასახელება',
                    value: 'გრაფიკული დიზაინერი'
                },
                {
                    title: 'სამსახურის თანამდებობის დონე',
                    value: 'მენეჯერი'
                },
                {
                    title: 'სასურველი ადგილმდებარეობა',
                    value: 'თბილისი'
                },
                {
                    title: 'სამუშაო გამოცთილება',
                    value: '10 წელი'
                },
                {
                    title: 'დასაქმების ტიპი',
                    value: 'დისტანციური'
                },
                {
                    title: 'სამსახურის სექტორი',
                    value: 'მარკეტინგი, რეკლამა'
                },
                {
                    title: 'სამუშაო საათები',
                    value: '15-20 საათი კვირაში'
                },
                {
                    title: 'ანაზღაურება',
                    value: 'GEL 1500-2000'
                },
                {
                    title: 'სამსახურის დაწყების პერიოდი',
                    value: '1 კვირაში'
                },
                {
                    title: 'ასაკი',
                    value: '25-37'
                }
            ]
        },
        education() {
            return [
                {
                    directionOfEducation: 'გრაფიკა, დიზაინი',
                    levelOfEducation: 'უნივერსიტეტი',
                    havingOfDiploma: 'დიახ'
                }
            ]
        },
        softwareKnowledge() {
            return [
                {
                    knowledge: 'Adobe Photoshop',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                },
                {
                    knowledge: 'Adobe Illustrator',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                },
                {
                    knowledge: 'Adobe After Effects',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                },
                {
                    knowledge: 'Adobe Premier Pro',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                }
            ]
        },
        knowledgeOfLanguages() {
            return [
                {
                    language: 'ქართული',
                    levelOfConversation: 'საუკეთესო',
                    writingLevel: 'საუკეთესო'
                },
                {
                    language: 'ინგლისური',
                    levelOfConversation: 'საუკეთესო',
                    writingLevel: 'საუკეთესო'
                },
                {
                    language: 'გერმანული',
                    levelOfConversation: 'საშუალო',
                    writingLevel: 'საშუალო'
                }
            ]
        }
    }
}
</script>