<template>
    <div class="flex items-center gap-3 mb-5 sm:mb-5">
            <h3 class="text-lg lg:text-2xl font-medium font-bannerExtrasquareCaps inline-block w-fit truncate ">
                {{ heading }}
            </h3>
            <span class="text-main font-medium -mt-3  cursor-pointer text-xs">[?]</span>
        </div>
</template>
<script>
export default {
    props: ['heading']
}
</script>