<template>
    <div class="flex flex-col">
        <!-- Radio Button 1 -->
        <div class="my-2 flex items-center">
            <input type="radio" :id="button1.id" :name="name" :value="button1.value"
                class="w-5 h-5 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" v-model="value"
                :aria-label="button1.label" />
            <label :for="button1.id" class="font-bannerExtrasquare text-auth ml-2 text-sm hover:cursor-pointer">
                {{ button1.label }}
            </label>
        </div>

        <!-- Radio Button 2 -->
        <div class="my-2 flex items-center">
            <input type="radio" :id="button2.id" :name="name" :value="button2.value"
                class="w-5 h-5 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" v-model="value"
                :aria-label="button2.label" />
            <label :for="button2.id" class="font-bannerExtrasquare text-auth ml-2 text-sm hover:cursor-pointer">
                {{ button2.label }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        button1: {
            type: Object,
            required: true,
            validator(value) {
                return value && value.id && value.label && value.value;
            }
        },
        button2: {
            type: Object,
            required: true,
            validator(value) {
                return value && value.id && value.label && value.value;
            }
        },
        value: {
            type: [String, Number],
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    emits: ['update:modelValue'],
    watch: {
        value(newValue) {
            this.$emit('update:modelValue', newValue);
        }
    }
}
</script>

<style scoped>
/* Improve spacing, layout, and hover effect */
.flex {
    display: flex;
    justify-content: flex-start;
}

.my-2 {
    margin-bottom: 0.5rem;
}

.my-auto {
    margin: auto 0;
}

.w-5 {
    width: 1.25rem;
}

.h-5 {
    height: 1.25rem;
}

.text-auth {
    color: #333;
    /* Assuming it's the main text color */
}

.font-bannerExtrasquare {
    font-family: 'Banner ExtraSquare', sans-serif;
}

.checked\:w-5:checked {
    width: 1.25rem;
}

.checked\:h-5:checked {
    height: 1.25rem;
}

.hover\:cursor-pointer:hover {
    cursor: pointer;
}

.ml-2 {
    margin-left: 0.5rem;
}

.text-sm {
    font-size: 0.875rem;
}
</style>
