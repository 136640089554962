<template lang="">
  <div class="bg-default p-5 s-nav">
    <div class="container items-center m-auto">
      <div class="lg:grid lg:grid-cols-4 lg:gap-9">
        <!-- Sidebar -->
        <div class="hidden lg:block">
          <ul class="font-bannerExtrasquare">
            <li class="border-b">
              <router-link 
                to="/dashboard/company" 
                v-slot="{ href, route, navigate, isActive, isExactActive }" 
                :class="{
                  'sec-nav-active': hasActiveClass(self),
                  'sec-nav': !hasActiveClass(self),
                }">
                {{$t('nav_about_company')}}
              </router-link>
            </li>

            <li class="border-t border-b border-sec-nav-border">
              <router-link 
                to="/dashboard/edit-company" 
                :class="{
                  'sec-nav-active': currentRouteName == 'dashboard',
                  'sec-nav': currentRouteName != 'dashboard',
                }">
                {{$t('nav_edit_company')}}
              </router-link>
            </li>

            <li class="border-b border-sec-nav-border">
              <router-link 
                to="/dashboard/edit-company-user" 
                :class="{
                  'sec-nav-active': currentRouteName == 'dashboard',
                  'sec-nav': currentRouteName != 'dashboard',
                }">
                {{$t('nav_edit_user')}}
              </router-link>
            </li>

            <li class="border-b border-sec-nav-border">
              <router-link 
                to="/dashboard/create-service" 
                :class="{
                  'sec-nav-active': currentRouteName == 'dashboard',
                  'sec-nav': currentRouteName != 'dashboard',
                }">
                {{$t('nav_create_a_job')}}
              </router-link>
            </li>

            <li @click="showFilterOptions = !showFilterOptions" class="border-b border-sec-nav-border">
              <router-link 
                to="/dashboard/candidates" 
                :class="{
                  'sec-nav-active': currentRouteName == 'dashboard',
                  'sec-nav': currentRouteName != 'dashboard',
                }">
                <p class="flex items-center justify-between">
                  {{$t('nav_candidates')}}
                  <!-- <svg 
                    class="w-4 h-4" 
                    :class="{'text-white' : currentRouteName == 'dashboard'}" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path 
                      stroke-linecap="round" 
                      stroke-linejoin="round" 
                      stroke-width="2" 
                      d="M19 9l-7 7-7-7">
                    </path>
                  </svg> -->
                </p>
              </router-link>
            </li>

            <!-- Manual search section (commented out) -->
            <!-- 
            <li v-if="showFilterOptions === true">
              <div class="p-4 border-b border-sec-nav-border bg-white">
                <p class="pl-4 font-bannerExtrasquare text-sm">{{$t('manual_search')}}</p>
              </div>
              <CandidatesFilterComponent class="max-h-[600px] overflow-y-auto"/>
              <div class="flex justify-center items-center bg-white py-2">
                <button class="bg-main text-white font-bannerExtrasquareCaps text-base rounded py-3 px-11">
                  {{$t('start_searching')}}
                </button>
              </div>
            </li> 
            -->

            

            <li class="border-b border-sec-nav-border">
              <router-link 
                to="/dashboard/created-service" 
                :class="{
                  'sec-nav-active': currentRouteName == 'dashboard',
                  'sec-nav': currentRouteName != 'dashboard',
                }">
                {{$t('created_job')}}
              </router-link>
            </li>

            <li class="border-b border-sec-nav-border">
              <router-link 
                to="/dashboard/company-messages" 
                :class="{
                  'sec-nav-active': currentRouteName == 'dashboard',
                  'sec-nav': currentRouteName != 'dashboard',
                }">
                {{$t('nav_messages')}}
                <span class="my-auto ml-2 bg-red-600 text-white px-1 py-px text-center rounded text-xs">4</span>
              </router-link>
            </li>

            <li class="border-b border-sec-nav-border flex justify-center">
              <router-link 
                to="/dashboard/myPackage" 
                href="#" 
                class="sec-nav">
                {{$t('nav_my_package')}}
              </router-link>
            </li>

            <li class="border-b border-sec-nav-border flex justify-center">
              <router-link 
                to="/dashboard/packages" 
                href="#" 
                class="sec-nav">
                {{$t('pricing')}}
              </router-link>
            </li>

            <li>
              <a href="#" @click.prevent="logout" class="sec-nav-log-out">
                {{ $t('sign_out') }}
              </a>
            </li>
          </ul>
        </div>

        <!-- Main Content -->
        <div class="flex col-span-3">
          <router-view class="view main-content"></router-view>
        </div>
      </div>

      <!-- Secondary Content -->
      <router-view class="view secondary-content" name="AboutSecondary"></router-view>
    </div>
  </div>
</template>

<script>
import CandidatesFilterComponent from './CandidatesFilterComponent.vue';

export default {
  name: 'Company-Dashboard',
  components: {
    CandidatesFilterComponent
  },
  data() {
    return {
      showFilterOptions: false
    };
  },
  methods: {
    hasActiveClass(self) {
      console.log(self); // You can update this method to return a boolean or use it for debugging
    },
    logout() {
      this.$root.store.dispatch('auth/logout');
    }
  },
};
</script>
