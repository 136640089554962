<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36 ">{{ $t('password') }}</a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2  hidden md:block"> ********** </a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
        <span class="">Edit</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">
          {{ $t('password') }}
        </h1>
      </div>
      <div class="form flex-row mt-5">
        <div class="relative z-0 w-full max-w-sm mx-auto">

          <InputFieldComponent :value="password" :label="$t('company_dashboard_edit_company_current_password')"
            type="password" v-model="password" class="mb-3" :error="v$.password.$errors.length > 0"
            @onBlur="v$.password.$touch()"
            :errorMessage="v$.password.$errors.length > 0 ? v$.password.$errors[0].$message : ''" />
          <p class="text-[10px] text-right text-input">Minimum 8 Characters, at least 1 number, at least 1 letter</p>
          <InputFieldComponent :value="newPassword" :label="$t('applicant_dashboard_edit_profile_form_new_password')"
            type="password" v-model="newPassword" class="mb-2" :error="v$.newPassword.$errors.length > 0"
            @onBlur="v$.newPassword.$touch()"
            :errorMessage="v$.newPassword.$errors.length > 0 ? v$.newPassword.$errors[0].$message : ''" />
          <PasswordStrengthComponent ref="passwordStrength" />
          <InputFieldComponent :value="repeatPassword"
            :label="$t('applicant_dashboard_edit_profile_form_repeat_password')" type="password"
            v-model="repeatPassword" class="mb-2" :error="v$.repeatPassword.$errors.length > 0"
            @onBlur="v$.repeatPassword.$touch()"
            :errorMessage="v$.repeatPassword.$errors.length > 0 ? v$.repeatPassword.$errors[0].$message : ''" />
        </div>
      </div>
      <div class="flex mt-6">
        <button :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="editing = false">{{ $t('applicant_dashboard_edit_profile_form_update') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import { email as emailValid, required, sameAs, minLength, helpers } from '@vuelidate/validators'
import PasswordStrengthComponent from "@/components/common/PasswordStrengthComponent.vue";
import useValidate from '@vuelidate/core'
export default {
  name: 'Password',
  components: {
    QuillEditor,
    InputFieldComponent,
    PasswordStrengthComponent
  },
  data() {
    return {
      v$: useValidate(),
      editing: false,
      password: '',
      newPassword: '',
      repeatPassword: '',
    };
  },
  watch: {
    newPassword(newValue) {
      this.$refs.passwordStrength.updateValue(newValue);
    }
  },
  methods: {
    activate() {
      this.editing = !this.editing;

      if (this.editing) {
        this.$parent.activatePassword();
      }
    },
  },
  validations() {
    return {
      password: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        required,
        sameAsNewPassword: helpers.withMessage('Value must be same as new password.', sameAs(this.newPassword))
      }
    }
  }
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
