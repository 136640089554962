<template lang="">
<div class="bg-default p-5 s-nav">
  <div class="container items-center m-auto">
    <div class="lg:grid lg:grid-cols-4 lg:gap-9">
      <div class=" hidden lg:block">
        <ul class="font-bannerExtrasquare">
          <li class="border-b border-sec-nav-border">
            <router-link to="/dashboard/candidate" v-slot="{ href, route, navigate, isActive, isExactActive }" :class="{ 'sec-nav-active': hasActiveClass(self), 'sec-nav': !hasActiveClass(self), }"> {{ $t('resume_general_about_me') }}</router-link>
          </li>
          <li class="border-b border-sec-nav-border">
            <router-link to="/dashboard/edit-candidate" :class="{ 'sec-nav-active': currentRouteName
                     == 'dashboard', 'sec-nav': currentRouteName != 'dashboard', }"> {{ $t('nav_edit_profile') }}
            </router-link>
          </li>
          <li class="border-b border-sec-nav-border">
            <router-link to="/dashboard/candidate-jobs"  :class="{ 'sec-nav-active': currentRouteName
                     == 'dashboard', 'sec-nav': currentRouteName != 'dashboard', }">{{$t('nav_matched_jobs')}}</router-link>
            </li>
          <li class="border-b
                  border-sec-nav-border">
                  <router-link to="/dashboard/my-resume" :class="{ 'sec-nav-active': currentRouteName
                     == 'dashboard', 'sec-nav': currentRouteName != 'dashboard', }"> {{ $t('nav_my_resume') }}
            </router-link>
                 </li>
           <li class="border-b border-sec-nav-border flex justify-center">
             
                  <router-link to="/dashboard/messages"  :class="{ 'sec-nav-active': currentRouteName
                     == 'dashboard', 'sec-nav': currentRouteName != 'dashboard', }">{{ $t('nav_my_messages') }} <span class="my-auto ml-2 bg-red-600 text-white px-1
                  py-px text-center rounded text-xs">4</span></router-link>
          </li>
          <!-- <li class="border-b
                  border-sec-nav-border"> <a href="#" class="sec-nav">{{ $t('params') }}</a> </li> -->
                  <!-- <li class="border-b border-sec-nav-border flex justify-center">
                    <a href="#" class="sec-nav">
                      {{ $t('nav_my_package') }}
                    </a>
                  </li> -->
          <li class="border-b border-sec-nav-border">
            <a href="#" @click.prevent="logout" class="sec-nav-log-out"> {{ $t('sign_out') }}</a>
          </li>
        </ul>
      </div>
      <div class="flex col-span-3">
        <router-view class="view main-content"></router-view>
      </div>
    </div>
    <router-view class="view secondary-content" name="OtherDetails"></router-view>
  </div>
</div>
<div>
  <router-view class="view secondary-content" name="DesiredPosition"></router-view>
</div>
</template>

<script>

export default {
  name: 'Candidate-Dashboard',
  components: {
    // HelloWorld,
  },
  computed: {},
  methods: {
    hasActiveClass(self) {
      console.log(self);
    },
    logout() {
      this.$root.store.dispatch('auth/logout');
    },
  },
};
</script>

<style scoped>
/* .router-link-exact-active {
        color: red;
    } */
</style>
