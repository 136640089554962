<template>
  <main class="bg-main-bg bg-cover bg-no-repeat h-auto min-h-min relative">
    <div class="container items-center m-auto p-6 lg:pt-52 pb-44">
      <div class="lg:block hidden">
        <h1 class="text-white font-bannerExtrasquareCaps text-6xl">Welcome to belinQed</h1>
        <h3 class="text-white font-bannerExtrasquare text-xl pt-5">ნახე 50 000 -ზე მეტი სამსახური</h3>
      </div>
      <div class="flex-row  md:flex my-20 lg:pb-44">
        <a href="#" class="cta-button ">
          <span class="bg-button-icon flex px-4 py-1 rounded-tl rounded-bl">
            <img class="w-3 m-auto" src="@/assets/Images/home/pencil-icon.png" alt="">
          </span>
          <span class="m-auto px-12 mt-3.5">
            {{ $t('submit_resume') }}
          </span>
        </a>
        <a href="#" class="cta-button md:ml-4 md:mt-0 mt-5">
          <span class="bg-button-icon flex px-4 py-1 rounded-tl rounded-bl">
            <img class="w-3 m-auto" src="@/assets/Images/home/add-resume-icon.png" alt="">
          </span>
          <span class="m-auto px-12 mt-3.5">
            {{ $t('post_job') }}
          </span>
        </a>
      </div>
    </div>
    <div class="w-full h-44 absolute bottom-0 left-0  bg-banner opacity-60 "></div>
    <div class=" w-full h-44 absolute bottom-0 left-0 flex ">
      <div class="container items-center m-auto ">
        <Carousel class="mx-2" :items-to-show="2" snapAlign="center-even" autoplay="2500" :breakpoints="breakpoints">
          <Slide v-for="(slide, index) in aboutCarousel" :key="slide">
            <div class="flex-row justify-center items-center text-center">
              <img class="w-11 m-auto mb-2 h-11" :src="slide.icon" alt="">
              <div class="font-roboto text-white text-3xl ">{{ slide.number }}</div>
              <div class="font-bannerSuperSquareCaps text-white text-xs">
                <!-- <span  v-if="index == 0">{{ $t('home_resume') }}</span>
                    <span  v-if="index == 1">{{ $t('home_job') }}</span>
                    <span  v-if="index == 2">{{ $t('home_company') }}</span>
                    <span  v-if="index == 3">{{ $t('home_matched') }}</span>
                    <span  v-if="index == 4">{{ $t('home_waiting') }}</span> -->
                {{ slide.title }}
              </div>
            </div>
          </Slide>
          <template #addons>
            <!-- <navigation class="lg:hidden mx-2" /> -->
            <!-- <pagination class="hidden"/> -->
          </template>
        </Carousel>
      </div>
    </div>
  </main>
  <!-- Belinqed features -->
  <div class="bg-default">

    <div class="container items-center m-auto">
      <div class=" pt-9 ">
          <Carousel :items-to-show="1" :autoplay="2500" :breakpoints="aboutBreakpoints" >
            <Slide v-for="slide in aboutBelinqed" :key="slide"
              class="px-2 border-transparent first:border-l-0  last:border-r-0">
              <div class="p-4  bg-white pb-16 text-center h-full ">
                <div class="  h-full w-full">
                  <img :src="slide.icon" alt="Profile placeholder" class="mx-auto py-5">
                  <h2 class="font-bannerExtrasquareCaps text-lg pb-5 text-advh">{{ slide.title }}</h2>
                  <p class="font-bannerExtrasquare text-sm text-justify px-5 text-advp">{{ slide.description }}</p>
                </div>
              </div>
            </Slide>
            <template #addons>
              <!-- <Navigation class="text-main  hidden md:block" /> -->
              <!-- <pagination class="mt-3 lg:hidden" /> -->
            </template>
          </Carousel>
      </div>
      <!-- Stats -->
      <div class=" py-4">
        <div class="">
          <Carousel :items-to-show="1" :breakpoints="jobsBreakPoint" class="">
            <Slide v-for="slide in latestJobs" :key="slide"
              class="px-2 first:border-l-0 border-transparent last:border-r-0 drop-shadow-jobs">
              <div class="p-8 text-center bg-white flex-row relative w-full">
                <h3 class="font-bannerSuperSquareCaps text-xl text-stath">{{ slide.title }}</h3>
                <div class="bg-main text-white text-lg  rounded-3xl  2xl:w-52 h-14 m-auto text-center flex my-7">
                  <a class="m-auto font-roboto" href="#">{{ slide.salary }} GEL</a>
                </div>
                <div class="flex">
                  <a href="#" class="m-auto text-main font-bannerSuperSquareCaps text-lg">{{ slide.number }} {{ $t('home_job')
                  }}</a>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation class="text-main h-8  hidden " />
              <!-- <pagination class="mt-3 lg:hidden"/> -->
            </template>
          </Carousel>
    
    
        </div>
      </div>
      <!-- Player area -->
      <div class=" py-4">
        <div class="">
          <div class="grid grid-cols xl:grid-cols-3">
            <div class="p-5 px-10 bg-white h-80 mb-10 md:mb-auto">
              <h1 class="uppercase font-bannerSuperSquareCaps text-2xl text-secondary-title pt-14">How we work</h1>
              <p class="font-roboto text-lg text-secondary-description py-7">Create A Detailed And Complete Profile Of Your
                Company => Introduce Your Company To Interested Candidates Using Innovative Tools And Auto-Fill Information.
                Save Time And Human Resources To Find A New Candidate.</p>
            </div>
            <div class="col-span-2 h-full relative">
              <img src="@/assets/Images/home/banner-2.jpeg" class=" h-80 w-full object-cover" alt="Video banner">
              <div class="w-full h-80 absolute top-0 left-0 bg-player-overlay opacity-70"></div>
              <div class="w-full h-80 absolute top-0 left-0">
                <div class=" pt-10 text-white text-center mt-5">
                  <h3 class="font-bannerExtrasquareCaps text-sm uppercase">Best for your projects</h3>
                  <h1 class="font-bannerExtrasquare text-3xl mt-1">Watch Our <span class="text-main">Video</span></h1>
                </div>
                <div class="flex mt-3">
                  <a href="#" class="m-auto">
                    <svg class="w-36 h-36" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z">
                      </path>
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- instructions -->
      <div class=" pb-20">
        <div class=" text-center">
    
          <carousel :items-to-show="1" :breakpoints="visionBreakPoint" class="xl:hidden">
            <slide v-for="slide in vision" :key="slide"
              class=" border-x-4 first:border-l-0 border-transparent last:border-r-0">
              <div class="flex-row custom-item ">
                <div class="flex set-item-bg ">
                  <h1 class="m-auto p-10 font-bannerSuperSquareCaps text-secondary-title h-32">{{ slide.title }}</h1>
                </div>
                <div class="flex mt-1 set-item-bg py-4  text-center h-80">
                  <p class="p-10 font-roboto text-secondary-description">{{ slide.description }}</p>
                </div>
              </div>
            </slide>
            <template #addons>
              <navigation class="hidden" />
              <pagination class="mt-3 xl:hidden" />
            </template>
          </carousel>
    
          <div class="grid-cols xl:grid-cols-3 gap-3 hidden xl:grid">
            <div class="flex-row custom-item " v-for="slide in vision" :key="slide">
              <div class="flex set-item-bg ">
                <h1 class="m-auto p-10 font-bannerSuperSquareCaps text-secondary-title h-32">{{ slide.title }}</h1>
              </div>
              <div class="flex mt-1 set-item-bg py-4  text-center h-80">
                <p class="p-10 font-roboto text-secondary-description">{{ slide.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import 'vue3-carousel/dist/carousel.css';
import axios from 'axios';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'HomeView',
  data() {
    return {
      aboutCarousel: [
        { number: '3 282', icon: require('@/assets/Images/home/resumeIcon.png'), title: `${this.$t('home_resume')}` },
        { number: '3 282', icon: require('@/assets/Images/home/vacansy-icon.png'), title: this.$t('home_job') },
        { number: '3 282', icon: require('@/assets/Images/home/company-icon.png'), title: this.$t('home_company') },
        { number: '3 282', icon: require('@/assets/Images/home/connected-icon.png'), title: this.$t('home_matched') },
        { number: '3 282', icon: require('@/assets/Images/home/waiting-icon.png'), title: this.$t('home_waiting') },
      ],
      aboutBelinqed: [
        { icon: require('@/assets/Images/shared/profilePlaceholder.png'), title: 'ბელინკდის ხედვა', description: 'დაინტერესებულ ორივე მხარის - დამსაქმებელსა და აპლიკანტს, მოქნილი პლატფორმის მეშვეობით, აძლევს შესაძლებლობას, განაცხადის განთავსებისა და კანდიდატურის შერჩევის პროცესი იყოს მეტად მარტივი, ეფექტური და მხოლოდ მიზნობრივი ჯგუფისთვის საინტერესო.' },
        { icon: require('@/assets/Images/shared/profilePlaceholder.png'), title: 'ბელინკდის მისია', description: 'დამსაქმებელისა და აპლიკანტს შორის იმ ნიადაგის (პლატფორმის) მომზადება, რომელიც ხელს შეუწყობს კადრების შერჩევის არსებული ტექნოლოგიის გაუმჯობესებასა და განვითარებას.' },
        { icon: require('@/assets/Images/shared/profilePlaceholder.png'), title: 'ბელინკდის მიზნები', description: 'პროექტის მიზანია, შეიქმნას ისეთი ტიპის ინოვაციური და ფინანსურად მომგებიანი პლატფორმა, რომელიც რადიკალურ ცვლილებებს მოახდენს რეგიონში არსებულ სტანდარტებზე, რაც თავის მხრის, მრავალ საკითხს მოიცავს.' },
        { icon: require('@/assets/Images/shared/profilePlaceholder.png'), title: 'ბილინკდის მომავალი', description: 'მოთხოვნიდან გამომდინარა არის მომავალში შესაძლებელი რომ აპლიკანტმა და დამსაქმებელმა ფილტრების მეშვეიბით მოიძიოს მისთვის შესაფერისი კანდიდატი ან კომპანია.' },
      ],
      latestJobs: [
        { title: 'იურისტი', salary: '2000 - 6000', number: '150' },
        { title: 'დიზაინერი', salary: '2000 - 6000', number: '250' },
        { title: 'ბუღალტერი', salary: '2000 - 6000', number: '140' },
        { title: 'პროგრამისტი', salary: '2000 - 6000', number: '50' },
        { title: 'ბუღალტერი', salary: '2000 - 6000', number: '140' },
        { title: 'პროგრამისტი2', salary: '1000 - 6000', number: '150' },
      ],
      vision: [
        { title: 'დეშბორდის გამოყენება', description: 'საკომუნიკაციო და ვიდეო ხელსაწყოები - Communication And Video Tool დეშბორდის მეშვეობით, ორივე მხარეს შესაძლებლობას აძლევს, მეტი ეფექტისთვის, მოამზადონ საკუთარი თავის საპრეზენტაციოდ ვიდეორგოლები.' },
        { title: 'ავტომატური ძიების მექანიზმი', description: 'სრულიად ინოვაციური ავტომატური ძიების შესაძლებლობა, გვაძლებს უპირატესობას რომ მოხდეს დაკავშირება განმცხადებელსა და აპლიკანტს შორის.' },
        { title: 'კომპანიას შეუძლია განათავსოს მრავალფეროვანი ვაკანსია', description: 'კომპანიას შესაძლებლობა აქვს, სწრაფად, მარტივად და ინტერესებიდან გამომდინარე => თანამედროვე ტექნოლოგიებისა და ავტომატურად შევსებადი ინფორმაციის გამოყენებით მოიძიოს ახალი კანდიდატები.' },
        { title: 'კომპანიის პროფილის შექმნა', description: 'შექმენი დეტალური და სრული პროფილი საკუთარ კომპანიაზე => თანამედროვე ხელსაწყოების და ავტომატურად შევსებადი ინფორმაციის გამოყენებით გააცანი საკუთარი კომპანია დაინტერესებულ კანდიდატებს. დაზოგე დრო და ადამიანური რესურსი ახალი კანდიდატის შესარჩევად.' },
        { title: 'კანდიდატის პროფილის შექმნა', description: 'შექმენი დეტალური და სრული პროფილი => თანამედროვე ტექნოლოგიებისა და ავტომატურად შევსებადი ინფორმაციის გამოყენებით გააცანი საკუთარი თავი შერჩეულ კომპანიებს.' },
        { title: 'იპოვნეთ სწორი კანდიდატი ან კომპანია', description: 'დაზოგე დრო და ფინანსები => გაფილტრე და აირჩიე თქვენს ინტერესებზე მორგებული კანდიდატები ან ვაკანსია.' },
      ],
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 4,
          snapAlign: 'center-even',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'center-even'
        },
      },
      aboutBreakpoints: {
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
        },
      },
      jobsBreakPoint: {
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: 'center-even',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'center-even',
        },
      },
      visionBreakPoint: {
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
        },

      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    document.title = 'Belinqed Home';
    if (this.$route.params.id) {
      this.verify();
    }
  },
  methods: {
    verify() {
      axios.get(this.$route.fullPath)
        .then(
          res => {
            if (res.data.success) {
              this.$notify({
                group: "foo",
                title: "Success",
                text: "Email verification success!👌 <br> Now you can sign in to your account",
                success: true
              }, 10000) // 10s
            } else {
              this.$notify({
                group: "foo",
                title: "Fail",
                text: res.data.error,
                success: false
              }, 10000) // 10s
            }
          }
        ).catch(
          err => {
            if (err.response) {
              this.errors = err.response.data.errors;
            }
            console.log("fail")
            this.$notify({
              group: "foo",
              title: "Fail",
              text: "Something went wrong",
              success: false
            }, 10000) // 10s
          }
        )
    }
  }
};
</script>
<style></style>
