import { createLogger, createStore } from 'vuex';
import authStore from './modules/auth';
import commonsStore from './modules/commons';
import axios from 'axios';

export default createStore({
  plugins: [createLogger()],
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {
    
  },
  modules: {
    auth:authStore,
    commons:commonsStore 
  },
});
