<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36">{{
        $t("company_team_photos") }}</a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2"></a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
        <span>{{ $t("edit") }}</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">{{ $t("company_team_photos") }}</h1>
      </div>
      <div class="my-6">
        <h1 class="text-xss text-edit-light">{{ $t("company_photos") }}</h1>
      </div>
      <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
          <div class="relative col-span-1 w-full group "
            v-for="(img, index) in $root.store.getters['auth/companyData']('office_images')" :key="index">
            <img :src="mainWebsiteUrl + 'storage/' + img.image.replace('public/', '')"
              class="h-full mx-auto max-h-[250px] object-scale-down" />
            <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <button class="hidden group-hover:flex" @click.prevent="removeCompanyImage(img.id)">
                <img src="@/assets/Images/icons/remove-icon.png" class="" />
              </button>
            </div>
          </div>
          <div class="relative col-span-1 bg-white flex py-5 justify-center items-center">
            <button @click="openUploadPopup">
              <img src="@/assets/Images/icons/plus-icon.png" class="m-auto w-12 h-12 sm:w-24 sm:h-24"
                alt="Add Team Member">
            </button>
          </div>
        </div>
        <div class="flex justify-end mt-1">
          <button class="flex items-center">
            <p class="text-main text-[8px]">{{ $t('see_more') }}</p>
            <IconGreaterThen />
          </button>
        </div>
      </div>

      <div class="my-9 mb-6">
        <h1 class="text-xss text-edit-light">{{ $t("photos_of_team") }}</h1>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
        <div class="col-span-1 bg-white" v-for="(member, index) in $root.store.getters['auth/companyData']('members')"
          :key="index">
          <div class="h-16 bg-edit-active m-[2px]"></div>
          <div class="bg-white relative px-3 pb-3">
            <div class="absolute left-1/2 -translate-x-1/2">
              <div class="relative">
                <div class=" relative rounded-3xl">
                  <img class="mx-auto rounded-full  md:w-40 object-cover -translate-y-1/2 w-32 h-32 "
                    :src="mainWebsiteUrl + 'storage/' + member.image" alt="" />
                </div>
                <div class="absolute top-1/2 -translate-y-9 left-1/2 -translate-x-1/2">
                  <button class="bg-main pl-5 py-3 pr-3 rounded-full cursor-pointer"
                    @click="toggleVideo(mainWebsiteUrl + 'storage/' + member.video)">
                    <IconVideoPlay />

                  </button>


                  <!-- Conditionally render the video -->
                  <!-- Video Popup Modal -->
                  <!-- <div v-if="isVideoPopupVisible"
                    class="fixed inset-0 bg-black bg-opacity-70 z-50 flex justify-center items-center">
                    <div class="bg-white p-6 w-[90%] sm:w-[60%] md:w-[50%] rounded-lg">
                      <div class="flex justify-end">
                        <button @click="closeVideoPopup" class="text-white text-2xl">&times;</button>
                      </div>
                      <div class="flex justify-center mt-4">
                        <video :src="member.video" controls class="w-full h-auto rounded-lg" autoplay />
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="pt-24 text-center">
              <p class="text-[10px] text-nav font-bannerExtrasquare">{{ member.name }} {{ member.last_name }} </p>
              <p class="text-[10px] text-team-pos font-bannerExtrasquare h-5">{{ member.about }}</p>
              <div class="flex justify-end items-center gap-1">
                <a class="text-[10px] text-main cursor-pointer" @click.prevent="editTeamMember(member)">Edit</a>
                <button @click.prevent="removeTeamMember(member)">
                  <IconDelete class="w-3 h-3 [&_path]:fill-black cursor-pointer" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="relative col-span-1 bg-white flex py-5 justify-center items-center">
          <button @click="addTeamMember()">
            <img src="@/assets/Images/icons/plus-icon.png" class="m-auto w-12 h-12 sm:w-24 sm:h-24"
              alt="Add Team Member">
          </button>
        </div>
      </div>

      <div class="flex justify-end mt-1">
        <button class="flex items-center">
          <p class="text-main text-[8px]">{{ $t('see_more') }}</p>
          <IconGreaterThen />
        </button>
      </div>
      <div class="flex mt-6">
        <!-- <button
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="updateProfile()">{{ $t('update') }}</button> -->
      </div>
    </div>

    <!-- team member popup component -->
    <OpenAddTeamMemberFormPopup ref="teamMemberFormPopup" :isOpen="openAddTeamMemberForm"
      :teamMember="editingTeamMemberData" @submit-form="handleTeamMemberUpload" />
    <ImageUploadPopup :isOpen="isUploadPopupOpen" @close="closeUploadPopup" @upload-images="handleImageUpload" />

    <VideoPlayerPopup :isVisible="isVideoPopupVisible" :videoUrl="selectedVideoUrl"
      @update:isVisible="isVideoPopupVisible = $event" />
  </div>
</template>

<script>
import InputFieldComponent from "@/components/common/InputFieldComponent.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { QuillEditor } from "@vueup/vue-quill";
import IconAvatar from "@/components/icons/IconAvatar.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconVideoPlay from "@/components/icons/IconVideoPlay.vue";
import IconUpload from "@/components/icons/IconUpload.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import IconGreaterThen from "@/components/icons/IconGreaterThen.vue"
import ImageUploadPopup from '@/components/company/sub-pages/edit/ImageUpload.vue';
import OpenAddTeamMemberFormPopup from '@/components/company/sub-pages/edit/TeamMemberFormPopup.vue';
import { useStore } from 'vuex';
import VideoPlayerPopup from "@/components/company/sub-pages/edit/VideoPlayerPopup.vue";

export default {
  name: "TeamPhotos",

  components: {
    OpenAddTeamMemberFormPopup,
    VideoPlayerPopup,
    ImageUploadPopup,
    QuillEditor,
    IconClose,
    InputFieldComponent,
    IconAvatar,
    IconEdit,
    IconVideoPlay,
    IconUpload,
    IconDelete,
    IconGreaterThen
  },
  data() {
    return {
      isVideoPopupVisible: false,
      selectedVideoUrl: "",
      store: useStore(),
      isUploadPopupOpen: false,
      editing: false,
      openAddTeamMemberForm: false,
      editingTeamMemberData: {},
      name: '',
      lastName: '',
      position: '',
      aboutTeamMember: '',
      mainWebsiteUrl: process.env.VUE_APP_AXIOS_BASE_DOMAIN,
      // companyImages: this.$root.store.getters['auth/companyData']('office_images')
    };
  },
  mounted() {
    console.log(this.$root.store.getters['auth/companyData']('office_images'));
    // this.companyImages = this.$root.store.getters['auth/companyData']('office_images');
  },
  methods: {

    toggleVideo(videoUrl) {
      this.selectedVideoUrl = videoUrl;
      this.isVideoPopupVisible = !this.isVideoPopupVisible; // Toggle the visibility state
    },
    openUploadPopup() {
      this.isUploadPopupOpen = true;
    },
    closeUploadPopup() {
      this.isUploadPopupOpen = false;
    },
    activate() {
      this.editing = !this.editing;

      if (this.editing) {
        this.$parent.activatePhotos();
      }
    },
    addOfficePhotos() {

    },
    addTeamMember() {
      this.openAddTeamMemberForm = true
    },
    editTeamMember(member) {
      this.editingTeamMemberData = member;
      setTimeout(() => {
        this.openAddTeamMemberForm = true
      }, 100);
    },
    updateProfile() {
      this.editing = false
      this.$emit('showProfileUpdateMessage')
    },
    removeCompanyImage(imgId) {

      console.log('removing company image ' + imgId);

      this.store.dispatch('commons/removeCompanyImage', { 'imageId': imgId })
        .then(res => {
          if (res.data.success) {
            this.store.dispatch('auth/getPackages')
            // this.editing = false

            this.$notify({
              group: "foo",
              title: "Success Message",
              text: res.data.result,
              success: true
            }, 4000) // 10s

          } else {
            console.log("Something went wrong")
          }

        })
        .catch((err) => {
          const errors = {
            tags: err.response.data.errors
          }
          // merge the errors into the validation results
          Object.assign(this.vuelidateExternalResults, errors)
        });

    },

    async handleTeamMemberUpload(data) {

      try {
        // Initialize FormData
        const formData = new FormData();

        // Append form fields
        formData.append("id", data.id || ""); // Use empty string if no ID
        formData.append("name", data.name);
        formData.append("lastName", data.lastName);
        formData.append("position", data.position);
        formData.append("aboutTeamMember", data.aboutTeamMember);

        // Append image file if selected
        if (data.image) {
          formData.append("image", data.image);
        }

        // Append video file if selected
        if (data.video) {
          formData.append("video", data.video);
        }

        // Send the form data to the API

        if (data.isEdit) {
          this.store.dispatch('commons/editCompanyTeamMember', formData)
            .then(res => {

              if (res.data.success) {
                this.store.dispatch('auth/getPackages')
                // this.editing = false

                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result,
                  success: true
                }, 4000) // 10s
                this.openAddTeamMemberForm = false;
                this.$refs.teamMemberFormPopup.resetForm();
              } else {
                console.log("Something went wrong")
              }

            })
            .catch((err) => {
              if (err.response && err.response.status === 422) {
                // Extract Laravel validation errors
                const validationErrors = err.response.data.errors || {};

                // Convert errors into a readable message
                const errorMessages = Object.values(validationErrors).flat().join("\n");

                this.$notify({
                  group: "foo",
                  title: "Validation Error",
                  text: errorMessages,
                  success: false
                }, 24000);

                // Assign errors to Vue validation (if using Vuelidate)
                Object.assign(this.vuelidateExternalResults, validationErrors);
              } else {
                console.error("Unexpected error:", err);

                this.$notify({
                  group: "foo",
                  title: "Something went wrong",
                  text: "An unexpected error occurred.",
                  success: false
                }, 4000);
              }
            });
        } else {
          this.store.dispatch('commons/addCompanyTeamMember', formData)
            .then(res => {

              if (res.data.success) {
                this.store.dispatch('auth/getPackages')
                // this.editing = false

                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result,
                  success: true
                }, 4000) // 10s
                this.openAddTeamMemberForm = false;
                this.$refs.teamMemberFormPopup.resetForm();
              } else {
                console.log("Something went wrong")
              }

            })
            .catch((err) => {
              if (err.response && err.response.status === 422) {
                // Extract Laravel validation errors
                const validationErrors = err.response.data.errors || {};

                // Convert errors into a readable message
                const errorMessages = Object.values(validationErrors).flat().join("\n");

                this.$notify({
                  group: "foo",
                  title: "Validation Error",
                  text: errorMessages,
                  success: false
                }, 24000);

                // Assign errors to Vue validation (if using Vuelidate)
                Object.assign(this.vuelidateExternalResults, validationErrors);
              } else {
                console.error("Unexpected error:", err);

                this.$notify({
                  group: "foo",
                  title: "Something went wrong",
                  text: "An unexpected error occurred.",
                  success: false
                }, 4000);
              }
            });

        }




      } catch (error) {
        console.error("Error submitting form:", error);
        this.$notify({
          group: "foo",
          title: "Error",
          text: error.message || "Failed to save team member. Please try again.",
          type: "error",
        });
      }


    },

    async removeTeamMember(member) {

      this.store.dispatch('commons/removeCompanyTeamMember', { id: member.id })
        .then(res => {

          if (res.data.success) {
            this.store.dispatch('auth/getPackages')
            // this.editing = false

            this.$notify({
              group: "foo",
              title: "Success Message",
              text: res.data.result,
              success: true
            }, 4000) // 10s
            this.openAddTeamMemberForm = false;
            this.$refs.teamMemberFormPopup.resetForm();
          } else {
            console.log("Something went wrong")
          }

        })
        .catch((err) => {
          const errors = {
            tags: err.response.data.errors
          }
          // merge the errors into the validation results
          Object.assign(this.vuelidateExternalResults, errors)
        });

    },

    async handleImageUpload(files) {

      const formData = new FormData();

      for (const file of files) {
        console.log('Appending file:', file.name); // Debugging
        formData.append('images[]', file);
      }

      // console.log('FormData contents:', [...formData.entries()]); // Check contents of FormData



      this.store.dispatch('commons/setCompanyImages', formData)
        .then(res => {

          if (res.data.success) {
            this.store.dispatch('auth/getPackages')
            // this.editing = false

            this.$notify({
              group: "foo",
              title: "Success Message",
              text: res.data.result,
              success: true
            }, 4000) // 10s
          } else {
            console.log("Something went wrong")
          }

        })
        .catch((err) => {
          const errors = {
            tags: err.response.data.errors
          }
          // merge the errors into the validation results
          Object.assign(this.vuelidateExternalResults, errors)
        });
      console.log("triggering changes");
    }

  },
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
