<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20.661" height="27.548" viewBox="18.8 11.191 20.661 27.548">
        <g data-name="Group 233">
            <path d="M38.6 24.965 19.661 37.88V12.052l18.94 12.913Z" fill="#eef7ff" fill-rule="evenodd"
                data-name="Path 74" />
            <path
                d="M19.661 38.74a.86.86 0 0 1-.86-.86V12.05a.86.86 0 0 1 1.346-.71l18.939 12.912a.862.862 0 0 1-.001 1.422L20.145 38.59a.862.862 0 0 1-.484.15Zm.861-25.058V36.25l16.55-11.285-16.55-11.283Z"
                fill="#eef7ff" fill-rule="evenodd" data-name="Path 75" />
        </g>
    </svg>
</template>