<template>
  <!-- About Belinqed -->
    <div class="bg-default p-5 ">
        <div class="container items-center m-auto">
            <!-- heding -->
            <div class="bg-white my-5 flex px-5 md:px-9 py-5">
                <div>
                    <h1 class="font-bannerExtrasquareCaps text-2xl">{{ $t('about_company') }}</h1>
                </div>
                <span class="block ml-1">
                  <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="">.</a>
                </span>
            </div>
            <!-- about content -->
            <div class="bg-white my-5  px-3 md:px-9 py-3 md:py-8 flex-row">
                <div class="block  ">
                    <a href="#"><img src="@/assets/Images/about/testimg1.png" alt="">.</a>
                </div>
                <!-- title -->
                <h1 class="font-bannerExtrasquareCaps text-lg py-8">ლილო პროდუქტ სერვისი Lilo Product Service International</h1>
                <!-- time -->
                <div class="flex py-2">
                    <span class="">
                        <svg class="w-4 mr-1 h-6" fill="none" stroke="#707070" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </span>
                    <h3 class=" text-clock">2019-11-12 15:39</h3>
                    <span class="flex">
                        <svg class="w-4 m-auto ml-2 h-4" fill="none" stroke="#707070" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path></svg>
                    </span>
                </div>

                <!-- description -->
                <p class="font-bannerExtrasquare text-xs text-blog leading-6">ლილო პროდუქტ სერვისი Lilo Product Service International არის ახალგაზრდა და შემოქმედებითი სავაჭრო აუთსორსინგის კომპანია. ჩვენი სტრატეგია აგებულია ჩვენი მომხმარებლის სურვილზე, სასურველი პროდუქტის მოძიებაზე კონკურენტული ფასის მოთხოვნის გათვალისწინებით. ჩვენ გთავაზობთ სრულ პაკეტს: მომწოდებლის შერჩევა, პროდუქციის წარმოების ფართო მაშტაბი, მუდმივი ხარისხის კონტროლი, სრული სატრანსპორტო საშუალების გამოყენება, შეკვეთის დასრულება თქვენი სურვილების დასაკმაყოფილებლად. </p>

                <div class="py-5">
                    <a href="#" class="font-bannerExtrasquare text-xxs  text-main  underline flex text-center">ნახე მეტი
                        <svg class="w-1.5 h-2 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </a>
                </div>
            </div>
            <!-- content end -->
            <!-- about content -->

            <div class="bg-white my-5  px-3 md:px-9 py-3 md:py-8 flex-row">
                <div class="block  ">
                    <a href="#"><img src="@/assets/Images/about/testimg1.png" alt=""><span class="hidden">.</span></a>
                </div>
                <!-- title -->
                <h1 class="font-bannerExtrasquareCaps text-lg py-8">ლილო პროდუქტ სერვისი Lilo Product Service International</h1>
                <!-- time -->
                <div class="flex py-2">
                    <span class="">
                        <svg class="w-4 mr-1 h-6" fill="none" stroke="#707070" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </span>
                    <h3 class=" text-clock">2019-11-12 15:39</h3>
                    <span class="flex">
                        <svg class="w-4 m-auto ml-2 h-4" fill="none" stroke="#707070" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path></svg>
                    </span>
                </div>

                <!-- description -->
                <p class="font-bannerExtrasquare text-xs text-blog leading-6">ლილო პროდუქტ სერვისი Lilo Product Service International არის ახალგაზრდა და შემოქმედებითი სავაჭრო აუთსორსინგის კომპანია. ჩვენი სტრატეგია აგებულია ჩვენი მომხმარებლის სურვილზე, სასურველი პროდუქტის მოძიებაზე კონკურენტული ფასის მოთხოვნის გათვალისწინებით. ჩვენ გთავაზობთ სრულ პაკეტს: მომწოდებლის შერჩევა, პროდუქციის წარმოების ფართო მაშტაბი, მუდმივი ხარისხის კონტროლი, სრული სატრანსპორტო საშუალების გამოყენება, შეკვეთის დასრულება თქვენი სურვილების დასაკმაყოფილებლად. </p>

                <div class="py-5">
                    <a href="#" class="font-bannerExtrasquare text-xxs  text-main  underline flex text-center">ნახე მეტი
                        <svg class="w-1.5 h-2 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </a>
                </div>
            </div>
            <!-- content end -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'AboutView',
  components: {
    // HelloWorld,
  },
  mounted() {
    document.title = 'Belinqed About Us';
  },
};
</script>
