<template>
     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1446.999 339.95 21 21">
                <g data-name="Group 2295">
                    <path d="M1446.999 350.4a10.5 10.5 0 1 1 0 .1z" fill="#36a8e0" fill-rule="evenodd"
                        data-name="Ellipse 23" />
                    <g data-name="Group 257">
                        <g data-name="Group 256">
                            <g data-name="Path 85">
                                <path
                                    d="M1461.09 344.6a1.443 1.443 0 0 0-1.102-.145c-.375.1-.688.341-.882.677l-4.352 7.537a.346.346 0 0 0-.044.135l-.306 2.744a.346.346 0 0 0 .548.316l2.223-1.637a.345.345 0 0 0 .095-.105l4.351-7.537c.4-.694.162-1.584-.531-1.984Zm-5.911 10.239.16-1.433 1 .578-1.16.855Zm1.665-1.363-1.318-.76 3.492-6.049 1.318.761-3.492 6.048Zm4.178-7.237-.34.59-1.318-.76.34-.591a.756.756 0 0 1 .463-.355.756.756 0 0 1 .577.076.756.756 0 0 1 .354.462.756.756 0 0 1-.076.578Z"
                                    fill="#fff" fill-rule="evenodd" />
                                <path
                                    d="M1461.09 344.6a1.443 1.443 0 0 0-1.102-.145c-.375.1-.688.341-.882.677l-4.352 7.537a.346.346 0 0 0-.044.135l-.306 2.744a.346.346 0 0 0 .548.316l2.223-1.637a.345.345 0 0 0 .095-.105l4.351-7.537c.4-.694.162-1.584-.531-1.984Zm-5.911 10.239.16-1.433 1 .578-1.16.855Zm1.665-1.363-1.318-.76 3.492-6.049 1.318.761-3.492 6.048Zm4.178-7.237-.34.59-1.318-.76.34-.591a.756.756 0 0 1 .463-.355.756.756 0 0 1 .577.076.756.756 0 0 1 .354.462.756.756 0 0 1-.076.578Z"
                                    stroke-linejoin="round" stroke-linecap="round" stroke-width=".5" stroke="#fff"
                                    fill="transparent" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
</template>