<template lang="">
<nav class="justify-between px-6 py-0 hidden lg:flex container items-center m-auto">
  <div class="flex my-auto">
    <router-link to="/">
      <img class="w-40" src="@/assets/Images/shared/logo.png" alt="logo" />
    </router-link>
  </div>
  <ul class="flex justify-between items-center">
    <li class="relative group">
      <a class="nav-item flex items-center h-20" href="#">
        <span>{{ $t('about_company') }}</span>
        <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </a>
      <ul class="aboutBelinqedUl">
        <li class="aboutBelinqedUlLi">
          <router-link to="/about" class="p-4 text-sm">{{ $t('about_us') }}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/team" href="#" class="p-4 text-sm">{{ $t('our_team') }}</router-link>
        </li>
        <!-- <li class="aboutBelinqedUlLi">
          <router-link to="/pricing" href="#" class="p-4 text-sm">{{ $t('our_pricing') }}</router-link>
        </li> -->
        <li class="aboutBelinqedUlLi"><a href="#" class="p-4 text-sm">{{ $t('newses') }}</a></li>
        <li class="aboutBelinqedUlLi">
          <a class="p-4 text-sm" :href="'/signed/terms-'+$cookies.get('lang')+'.pdf'" target="_blank">{{ $t('customer_terms') }}</a>
        </li>
        <li class="aboutBelinqedUlLi"><a href="#" class="p-4 text-sm">{{ $t('confidentiality_terms') }}</a></li>
        <li class="aboutBelinqedUlLiNoBorder"><a href="#" class="p-4 text-sm">{{ $t('faq') }}</a></li>
      </ul>
    </li>
    <li class="relative group" v-if="!$root.store.getters['auth/isLoggedIn'] || $root.$store.getters['auth/isCompany']">
      <a class="nav-item flex items-center h-20" href="#">
        <span>{{ $t('for_company') }}</span>
        <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </a>
      <ul class="aboutBelinqedUl">
        <!-- <li class="aboutBelinqedUlLi">
          <a href="#" class="py-6 text-sm">{{ $t('nav_create_a_job') }}</a>
        </li> -->
        <!-- <li class="aboutBelinqedUlLi">
            <a href="#" class="py-6 text-sm">რეგისტრირებული კომპანიები</a>
          </li> -->
        <!-- <li class="aboutBelinqedUlLi">
          <a href="#" class="py-6 text-sm">{{ $t('tariff') }}</a>
        </li> -->
        
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm">{{$t('vacancy_placement')}}</a>
        </li>
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm">{{$t('registered_companies')}}</a>
        </li>
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm">{{$t('rates')}}</a>
        </li>
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm" >{{$t('site_terms')}}</a>
        </li>
        <!-- <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/created-service" class="p-4 text-sm">{{$t('created_job')}}</router-link>
        </li> -->
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/packages" class="p-4 text-sm">{{$t('our_pricing')}}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/job" class="p-4 text-sm">Job</router-link>
        </li>


        <!-- <li class="aboutBelinqedUlLiNoBorder">
            <a href="#" class="py-6 text-sm">{{ $t('საიტის წესები') }}</a>
          </li> -->
      </ul>
    </li>
    <li class="relative group" v-if="!$root.store.getters['auth/isLoggedIn'] || !$root.$store.getters['auth/isCompany']">
      <a class="nav-item flex items-center h-20" href="#">
        <span>{{ $t('for_candidate') }}</span>
        <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </a>
      <ul class="aboutBelinqedUl">
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm">{{ $t('create_resume') }}</a>
        </li>
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm">{{$t('rates')}}</a>
        </li>
        <li class="aboutBelinqedUlLi">
          <a href="#" class="p-4 text-sm">{{$t('site_terms')}}</a>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/resume" class="p-4 text-sm">{{ $t('home_resume') }}</router-link>
        </li>
        <!-- <li class="aboutBelinqedUlLi"><a href="#" class="py-6 text-sm">{{ $t('tariff') }}</a></li> -->
        <!-- <li class="aboutBelinqedUlLiNoBorder"> -->
        <!-- <a href="#" class="py-6 text-sm">{{ $t('terms') }}</a> -->
        <!-- </li> -->
      </ul>
    </li>
    <li class="group">
      <!-- auth btn here -->
      <a href="/" class="auth-btn leading-none group-hover:bg-main group-hover:text-white" v-if="!$root.store.getters['auth/isLoggedIn']" @click.prevent="$emit('signInEvent')">
        <span><img src="@/assets/Images/shared/log-in.png" class="w-4 mr-2 group-hover:hidden" alt="" /></span>
        <span><img src="@/assets/Images/shared/log-in-white.png" class="w-4 mr-2 hidden group-hover:block" alt="" /></span>
        <span class="items-center pt-1">{{ $t('sign_in') }}</span>
      </a>
    </li>
    <li class="group" v-if="$root.store.getters['auth/isLoggedIn']">
      <div v-if="$root.store.getters['auth/isCompany']" class="flex-inline">
        <!-- <a href="/dashboard/create-service" class="leading-none w-48 ml-4 bg-main font-bannerExtrasquareCaps text-xs text-white px-5 py-3 rounded hover:bg-button-icon">
          {{ $t('nav_create_a_job') }}
        </a> -->
        <router-link to="/dashboard/create-service">
          <button class="bg-main text-white ml-4 px-5 py-3 text-xs rounded hover:bg-button-icon">
            {{ $t('nav_create_a_job') }}
          </button>
        </router-link>
      </div>
      <div v-else class="flex-inline">
        <a href="#" class="leading-none w-48 ml-4 bg-main font-bannerExtrasquareCaps text-xs text-white px-5 py-3 rounded hover:bg-button-icon uppercase">
          {{ $t('submit_resume') }}
        </a>
      </div>
    </li>
    <li class="relative group" v-if="$root.store.getters['auth/isLoggedIn'] && $root.store.getters['auth/isCompany']">
      <a class="nav-item items-center h-20 flex" href="#">
        <img src="@/assets/Images/shared/profilesample.png" alt="" class="rounded w-11 h-11 object-contain" />
        <div class="mx-4">
          <h3 class="text-main font-bannerExtrasquareCaps text-sm block">{{$root.store.getters['auth/companyData']('company_name') }}</h3>
          <h3 class="text-main font-segoeui text-xs block">{{$root.store.getters['auth/companyUserData']('name') }} {{$root.store.getters['auth/companyUserData']('lastname') }}</h3>
        </div>

        <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24
    24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>

        <div class="w-1 h-3/4 flex ml-5 border-r border-black"></div>
      </a>
      <ul class="aboutBelinqedUl">
        <li class="aboutBelinqedUlLi">
          <img src="" alt="" class="rounded" />
          <router-link to="/dashboard/company" class="p-4 text-sm">{{ $t('company_details') }}</router-link>
        </li>

        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/edit-company" class="p-4 text-sm">{{$t('nav_edit_company')}}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/edit-company-user" class="p-4 text-sm"> {{$t('nav_edit_user')}} </router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/candidates" class="p-4 text-sm">{{$t('nav_candidates')}}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/create-service" class="p-4 text-sm">{{$t('nav_create_a_job')}}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/company-messages" class="p-4 text-sm">{{$t('nav_messages')}} <span class="my-auto ml-2 bg-red-600 text-white px-1 py-px text-center rounded text-xs">4</span></router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/myPackage" class="p-4 text-sm">{{$t('nav_my_package')}}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/packages" class="p-4 text-sm">{{$t('pricing')}}</router-link>
        </li>

        <li class="p-7">
          <button  @click.prevent="logout" class="w-full border-2 border-main p-3  text-xs rounded font-bannerExtrasquareCaps font-medium text-main">{{$t('sign_out')}}</button>
        </li>
      </ul>
    </li>
    <li class="relative group" v-if="$root.store.getters['auth/isLoggedIn'] && !$root.store.getters['auth/isCompany']">
      <a class="nav-item items-center h-20 flex" href="#">
        <img src="@/assets/Images/shared/profilesample.png" alt="" class="rounded w-11 h-11 object-contain" />
        <div class="mx-4">
          <h3 class="text-main font-bannerExtrasquareCaps text-sm block">{{ $root.store.getters['auth/fullUserName'] }}</h3>
        </div>

        <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>

        <div class="w-1 h-3/4 flex ml-5 border-r border-black"></div>
      </a>
      <ul class="aboutBelinqedUl">
        <li class="aboutBelinqedUlLi">
          <img src="" alt="" class="rounded" />
          <router-link to="/dashboard/candidate" class="py-6 text-sm">{{ $t('home') }}</router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/edit-candidate"  class="py-6 text-sm"> {{ $t('nav_edit_profile') }}
            </router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/candidate-jobs"  class="py-6 text-sm"> {{ $t('nav_matched_jobs') }}
            </router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/my-resume"  class="py-6 text-sm"> {{ $t('nav_my_resume') }}
            </router-link>
        </li>
        <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/messages"  class="py-6 text-sm"> {{$t('nav_my_messages') }}<span class="my-auto ml-2 bg-red-600 text-white px-1
                  py-px text-center rounded text-xs">4</span>
            </router-link>
        </li>
        <!-- <li class="aboutBelinqedUlLi">
                    <a href="#" class="py-6 text-sm">
                      {{ $t('nav_my_package') }}
                    </a>
                  </li> -->
        <li class="p-7">
          <button  @click.prevent="logout" class="w-full border-2 border-main p-3  text-xs rounded font-bannerExtrasquareCaps font-medium text-main">{{$t('sign_out')}}</button>
        </li>
        <!-- <li class="aboutBelinqedUlLi"><a href="#" class="py-6 text-sm">{{ $t('resume') }}</a></li> -->
        <!-- <li class="aboutBelinqedUlLi"><a href="#" class="py-6 text-sm">{{ $t('tariff') }}</a></li> -->
      </ul>
    </li>
    <li class="relative group">
      <div  class="nav-item font-bannerExtrasquare flex items-center h-20">
        <span class="text-sm w-7" v-if="$root.$i18n.locale == 'ka'">GEO</span>
        <span class="text-sm w-7" v-else>EN</span>
        <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
      <ul class="absolute bg-white right-0 z-10 border w-32 top-full hidden group-hover:block">
        <li class="border-b hover:bg-slate-50 hover:cursor-pointer  font-bannerExtrasquare">
          <a href="#" class="p-4 block text-sm" @click="changeLang('en')">EN</a>
        </li>
        <li class="border-b hover:bg-slate-50 hover:cursor-pointer  font-bannerExtrasquare">
          <a href="#" class="p-4 block text-sm" @click="changeLang('ka')">GEO</a>
        </li>
        <!-- <li class="hover:bg-slate-50 hover:cursor-pointer  font-bannerExtrasquare">
            <a href="#" class="px-4 py-6 block text-sm" @click="changeLang('ge')">RU</a>
          </li> -->
      </ul>
    </li>
  </ul>
</nav>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
  useStore
} from 'vuex';
export default {
  name: 'NavDesktop',
  methods: {
    changeLang(lang) {
      if (this.$root.$i18n.locale !== lang) {
        this.$root.$i18n.locale = lang
        $cookies.set('lang', lang)
        console.log('setting lang', lang);
        this.$root.store.dispatch('commons/setLocale',{locale:lang});
      }
      // localStorage.lang = lang;
    },
    logout() {
      this.$root.store.dispatch('auth/logout');
    }
  },

};
</script>
