export default {


  setNewLocale(state, payLoad){
    console.log("newstate",payLoad);
    state.locale = payLoad;
  },

  setJobCreationParams(state, params) {
    console.log('job creation params loaded');
    state.jobCreationParams = params;
  },
  setError(state, error) {
    state.error = error;
  },
  
};
