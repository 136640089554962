<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="50" viewBox="-14324.5 1411.5 52 50">
        <g data-name="Group 1318">
            <path d="M4 0h45a4 4 0 0 1 4 4v43a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" stroke-linejoin="round"
            stroke-linecap="round" stroke="#4f4f4f" fill="transparent"
            transform="matrix(.98113 0 0 .9804 -14324.5 1411.5)" data-name="rectangle-1" />
        <path
            d="m-14307.91 1440.026-1.176 4.392-4.3.09a16.824 16.824 0 0 1-2.014-8.008c0-2.802.682-5.445 1.89-7.772l3.829.702 1.677 3.805a10.046 10.046 0 0 0-.542 3.265c0 1.24.225 2.43.637 3.526Z"
            fill="#fbbb00" fill-rule="evenodd" data-name="Path 1053" />
        <path
            d="M-14281.895 1433.343c.194 1.022.295 2.078.295 3.157 0 1.21-.127 2.39-.37 3.529a16.896 16.896 0 0 1-5.95 9.65l-4.823-.247-.682-4.26a10.072 10.072 0 0 0 4.333-5.143h-9.036v-6.686h16.233Z"
            fill="#518ef8" fill-rule="evenodd" data-name="Path 1054" />
        <path
            d="M-14287.92 1449.678v.001a16.829 16.829 0 0 1-10.58 3.72c-6.436 0-12.031-3.596-14.886-8.89l5.477-4.483a10.049 10.049 0 0 0 9.409 6.52c1.852 0 3.586-.5 5.075-1.374l5.504 4.506Z"
            fill="#28b446" fill-rule="evenodd" data-name="Path 1055" />
        <path
            d="m-14287.713 1423.49-5.475 4.483a9.99 9.99 0 0 0-5.312-1.52c-4.405 0-8.148 2.837-9.504 6.782l-5.505-4.507h-.001c2.812-5.423 8.478-9.128 15.01-9.128 4.1 0 7.86 1.46 10.787 3.89Z"
            fill="#f14336" fill-rule="evenodd" data-name="Path 1056" />
    </g>
</svg></template>