export default {

  login(state, data) {
    state.email = data.email;
    state.loginToken = data.token;
    state.loggedIn = true;
    state.isCompany = data.isCompany;

    this.dispatch('auth/getUserData');
    // localStorage.setItem('email', email);
  },

  logout(state) {
    state.email = '';
    state.loggedIn = false;
    state.isCompany = false;
    state.companyData = null;
    state.companyUserData = null;
    state.userData = null;
    // state.loggedIn = false;
    // state.loginToken = null;
    // localStorage.removeItem('email');
  },

  resetToken(state){
    state.loggedIn = false;
    state.loginToken = null;
  },

  setCompanyUserData(state, data) {
    state.userData = null;
    state.companyUserData = data;
    state.loggedIn = true;
    state.loginToken = localStorage.token;
    state.isCompany = localStorage.isCompany == 'true';
  },

  setUserData(state, data) {
    state.userData = data;
    state.companyUserData = null;
    state.loggedIn = true;
    state.loginToken = localStorage.token;
    state.isCompany = localStorage.isCompany == 'true';
  },


  setCompanyData(state, data) {
    state.isCompany = '';
    state.companyData = data;
    state.userData = null;
    state.loggedIn = true;
    state.loginToken = localStorage.token;
    state.isCompany = localStorage.isCompany == 'true';
  },

  initalizeStore(state) {
    if (localStorage.getItem('email')) {
      state.email = localStorage.getItem('email');
      if (localStorage.getItem('isCompany')) {
        state.isCompany = localStorage.getItem('isCompany');
      }
    }
  },
};
