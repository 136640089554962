<template>
    <div class="w-full">
        <AboutMe :is-resume-preview="false"/>
    </div>
</template>
<script>
import AboutMe from './my-resume-components/AboutMe.vue'

export default {
    components: {AboutMe}
}
</script>