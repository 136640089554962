<template>
  <div>
    <NotificationGroup group="foo" class="z-50">
      <div class="fixed inset-0 flex top-16 items-start justify-end p-6 px-4 py-6 pointer-events-none z-50">
        <div class="w-full max-w-sm">
          <Notification v-slot="{ notifications }" enter="transform ease-out duration-300 transition"
            enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-500"
            leave-from="opacity-100" leave-to="opacity-0" move="transition duration-500" move-delay="delay-300">
            <div class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
              v-for="notification in notifications" :key="notification.id">
              <template v-if="notification.success">
                <div class="flex items-center justify-center w-12 ">
                  <svg class="w-6 h-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>

                <div class="px-4 py-2 -mx-3">
                  <div class="mx-3">
                    <span class="font-semibold text-green-500">{{ notification.title }}</span>
                    <p class="text-sm text-gray-600"> <span v-html="notification.text"></span></p>
                  </div>
                </div>

              </template>
              <template v-else>
                <div class="flex items-center justify-center w-12 ">
                  <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="#f87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.16998 14.83L14.83 9.17004" stroke="#f87171" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M14.83 14.83L9.16998 9.17004" stroke="#f87171" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>

                <div class="px-4 py-2 -mx-3">
                  <div class="mx-3">
                    <span class="font-semibold text-red-500">{{ notification.title }}</span>
                    <p class="text-sm text-gray-600"> <span v-html="notification.text"></span> </p>
                  </div>
                </div>

              </template>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
  </div>
</template>
