import axios from 'axios';

// Base url for Axios
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

axios.defaults.withCredentials = true;
// axios.defaults.headers.common['content-type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://belinqed-new.test'; 



if (localStorage.token) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`;
}
