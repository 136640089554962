<template>
    <div>
        <div class="bg-white">
            <div class="p-4">
                <SelectInputComponent :value="field" v-model:updateValue="field" label="კანდიდატის პროფესია"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="სამსახურის სამუშაო სექტორი"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="თანამდებობის დონე"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="სამსახურის ადგილმდებარეობა"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3">
                    <template v-slot:icon>
                        <div class="bg-main py-[15px] px-4 rounded-r-md ">
                            <IconStreetView />
                        </div>
                    </template>
                </SelectInputComponent>
                <SelectInputComponent :value="field" v-model:updateValue="field" label="კანდიდატის ანაზღაურება"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="კანდიდატის გამოცდილება"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="კანდიდატის სამუშაო საათები"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="სამსახურის დაწყების პერიოდი"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="კანდიდატის დასაქმების ტიპი"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                <SelectInputComponent :value="field" v-model:updateValue="field" label="კანდიდატის ასაკი"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
            </div>

        </div>
        <!--Education Start-->
        <div class="mt-3 bg-white">
            <div class="p-4">
                <HeadingComponent heading="განათლება" />
                <div v-for="(field, index) in education" :key="index">
                    <div class="flex justify-end mb-1" v-if="education.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index, 'education')">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>
                    <SelectInputComponent :value="field.directionOnEducation"
                        v-model:updateValue="field.directionOnEducation" label="განათლების მიმართულება"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                    <SelectInputComponent :value="field.educationLevel" v-model:updateValue="field.educationLevel"
                        label="აირჩიე კანდიდატის განათლების დონე"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                    <div class="mt-9">
                        <RadioButton :value="field.isGraduated" v-model:updateValue="field.isGraduated" :button1="{
                            label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                        }"
                            :button2="{
                                label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
                            }" />
                    </div>
                </div>
                <div class="mt-7">
                    <button class="bg-main p-2 rounded" @click="addEducation()">
                        <IconPlus class="[&_path]:fill-white w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
        <!--Education End-->

        <!--Software Knowledge Start-->
        <div class="mt-3 bg-white">
            <div class="p-4">
                <HeadingComponent heading="პროგრამული ცოდნა" />
                <div v-for="(field, index) in softwareKnowledge" :key="index">
                    <div class="flex justify-end mb-1" v-if="softwareKnowledge.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index, 'softwareKnowledge')">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>
                    <SelectInputComponent :value="field.softwareKnowledge"
                        v-model:updateValue="field.softwareKnowledge" label="პროგრამული ცოდნა"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                    <SelectInputComponent :value="field.levelOfSoftwareKnowledge" v-model:updateValue="field.levelOfSoftwareKnowledge"
                        label="პროგრამული ცოდნის დონე"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                    <div class="mt-9">
                        <RadioButton :value="field.isGraduated" v-model:updateValue="field.isGraduated" :button1="{
                            label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                        }"
                            :button2="{
                                label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
                            }" />
                    </div>
                </div>
                <div class="mt-7">
                    <button class="bg-main p-2 rounded" @click="addSoftwareKnowledge()">
                        <IconPlus class="[&_path]:fill-white w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
        <!--Software Knowledge End-->

        <!--Knowledge of Languages Start-->
        <div class="mt-3 bg-white">
            <div class="p-4">
                <HeadingComponent heading="ენების ცოდნა" />
                <div v-for="(field, index) in knowledgeOfLanguages" :key="index">
                    <div class="flex justify-end mb-1" v-if="knowledgeOfLanguages.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index, 'knowledgeOfLanguages')">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>
                    <SelectInputComponent :value="field.knowledgeOfLanguages"
                        v-model:updateValue="field.knowledgeOfLanguages" label="ენების ცოდნა"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                    <SelectInputComponent :value="field.conversationalLevel" v-model:updateValue="field.conversationalLevel"
                        label="სასუბრო დონე"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                        <SelectInputComponent :value="field.writingLevel" v-model:updateValue="field.writingLevel"
                        label="წერის დონე"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                    <div class="mt-9">
                        <RadioButton :value="field.isGraduated" v-model:updateValue="field.isGraduated" :button1="{
                            label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                        }"
                            :button2="{
                                label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
                            }" />
                    </div>
                </div>
                <div class="mt-7">
                    <button class="bg-main p-2 rounded" @click="addKnowledgeOfLanguages()">
                        <IconPlus class="[&_path]:fill-white w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
        <!--Knowledge of Languages End-->

        <!--Driving License Start-->
        <div class="mt-3 bg-white">
            <div class="p-4">
                <HeadingComponent heading="მართვის მოწმობა" />
                <div v-for="(field, index) in drivingLicense" :key="index">
                    <div class="flex justify-end mb-1" v-if="drivingLicense.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index, 'drivingLicense')">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>
                    <SelectInputComponent :value="field.drivingLicenseRequirement"
                        v-model:updateValue="field.drivingLicenseRequirement" label="მართვის მოწმობა"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                </div>
                <div class="mt-7">
                    <button class="bg-main p-2 rounded" @click="addDrivingLicenseREquirement()">
                        <IconPlus class="[&_path]:fill-white w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
        <!--Driving License End-->

        <!--Competencies Start-->
        <div class="mt-3 bg-white">
            <div class="p-4">
                <HeadingComponent heading="კომპეტენციები" />
                <div v-for="(field, index) in competencies" :key="index">
                    <div class="flex justify-end mb-1" v-if="competencies.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index, 'competencies')">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>
                    <SelectInputComponent :value="field.competencies"
                        v-model:updateValue="field.competencies" label="კომპეტენციები"
                        :options="['option 1', 'option 2', 'option 3', 'option 4']" class="mb-3" />
                </div>
                <div class="mt-7">
                    <button class="bg-main p-2 rounded" @click="addCompetencies()">
                        <IconPlus class="[&_path]:fill-white w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
        <!--Competencies End-->
    </div>
</template>
<script>
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconStreetView from '../icons/IconStreetView.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import IconMinus from '../icons/IconMinus.vue';
import IconPlus from '../icons/IconPlus.vue';
import RadioButton from '@/components/common/RadioButton.vue';
export default {
    name: "CandidateFilterComponent",
    components: {
        SelectInputComponent,
        IconStreetView,
        HeadingComponent,
        IconMinus,
        RadioButton,
        IconPlus
    },
    data() {
        return {
            field: '',
            education: [],
            softwareKnowledge: [],
            knowledgeOfLanguages: [],
            drivingLicense: [],
            competencies: []
        }
    },
    methods: {
        addEducation() {
            this.education.push({
                directionOnEducation: '',
                educationLevel: '',
                isGraduated: null
            })
        },
        addSoftwareKnowledge(){
            this.softwareKnowledge.push({
                softwareKnowledge: '',
                levelOfSoftwareKnowledge: '',
                isGraduated: null
            })
        },
        addKnowledgeOfLanguages(){
            this.knowledgeOfLanguages.push({
                knowledgeOfLanguages: '',
                conversationalLevel: '',
                writingLevel: '',
                isGraduated: null
            })
        },
        addDrivingLicenseREquirement() {
            this.drivingLicense.push({
                drivingLicenseRequirement: '',
            })
        },
        addCompetencies(){
            this.competencies.push({
                competencies: '',
            });
        },
        /**@param index: Remove element number
        * @param field: On which field you want to remove element. 
        *               must note that field name must be same as data property.
        */
        remove(index, field) {
            if (this[`${field}`].length > 1) {
                this[`${field}`].splice(index, 1)
            }

        },
    },
    mounted() {
        this.addEducation();
        this.addSoftwareKnowledge();
        this.addKnowledgeOfLanguages();
        this.addDrivingLicenseREquirement();
        this.addCompetencies();
    }
}
</script>