import mutations from './mutations';
import getters from './getters';
import actions from './actions';


const state = {
  lang: 'en',
  jobCreationParams: {}, // State to hold job creation parameters
};


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
