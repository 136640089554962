<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('marital_status')"/>
        <div class="mt-7 mb-12">
            <!--Preview Start-->
            <div v-if="editing === false">
                <p class="text-xs text-granite-gray">დაოჯახებული</p>
            </div>
            <!--Preview End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div>
                    <SelectInputComponent :label="$t('marital_status')" :showBorder="true" :value="maritalStatus"
                        v-model:updateValue="maritalStatus" :options="['Married', 'Unmarried']" class="md:max-w-[50%]"/>
                </div>
            </div>
            <!--Edit Mode End-->
        </div>
        <button v-if="isResumePreview === false" @click="editing = !editing" class="edit-update-btn">
            {{ editing === true ? $t('update') : $t('edit') }}</button>
    </div>
</template>
<script>
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconStar from '@/components/icons/IconStar.vue';
import IconStarFilled from '@/components/icons/IconStarFiled.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
export default {
    name: 'MaritalStatus',
    components: { SelectInputComponent, IconStar, IconStarFilled, IconPlus, IconMinus, HeadingComponent },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,
            maritalStatus: ''

        }
    },
}
</script>