<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-40">{{
        $t("name_surname_contact_information") }}</a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2  hidden md:block">{{ $t("name_surname")
        }}</a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1">
        <span class="">{{ $t("edit") }}</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-40">{{ $t("name_surname_contact_information") }}
        </h1>
      </div>
      <div class="form flex-row mt-5 max-w-sm mx-auto">
        <div class="mb-2">
          <InputFieldComponent v-model="name" :label="$t('name')" type="text" :error="v$.name.$error"
            @onBlur="v$.name.$touch()" :errorMessage="v$.name.$errors.length > 0 ? v$.name.$errors[0].$message : ''" />
        </div>
        <div class="mb-2">
          <InputFieldComponent v-model="lastName" :label="$t('last_name')" type="text" :error="v$.lastName.$error"
            @onBlur="v$.lastName.$touch()"
            :errorMessage="v$.lastName.$errors.length > 0 ? v$.lastName.$errors[0].$message : ''" />
        </div>
        <div class="mb-2">
          <InputFieldComponent v-model="telephoneNumber" :label="$t('telephone_number')" type="text"
            :error="v$.telephoneNumber.$error" @onBlur="v$.telephoneNumber.$touch()"
            :errorMessage="v$.telephoneNumber.$errors.length > 0 ? v$.telephoneNumber.$errors[0].$message : ''" />
        </div>
        <div class="mb-2">
          <div class="mt-4" v-if="isOtpRequired">
            <a @click.prevent="saveInfo"
              class=" cursor-pointer flex justify-end text-xss text-main font-bannerExtrasquare hover:underline">
              Send OTP Again
            </a>
          </div>
          <InputFieldComponent v-if="isOtpRequired" v-model="otp" label="OTP" type="text" :error="v$.otp.$error"
            :errorMessage="v$.otp.$errors.length > 0 ? v$.otp.$errors[0].$message : ''" @onBlur="" />
        </div>
      </div>

      <div class="flex mt-6">
        <button :class="{ 'hidden': isOtpRequired }" :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click="saveInfo">{{ $t("update") }}</button>

        <button :class="{ 'hidden': !isOtpRequired }" :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click="saveOtp">{{ $t("update") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import useValidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { useStore } from 'vuex';

export default {
  name: "AboutInfo",
  components: {
    QuillEditor,
    InputFieldComponent
  },
  data() {
    return {
      v$: useValidate(),
      store: useStore(),
      editing: false,
      isOtpRequired: false,
      name: '',
      lastName: '',
      otp: null,
      telephoneNumber: ''
    };
  },
  methods: {
    saveInfo() {
      this.v$.$validate(); // Check all inputs
      if (!this.v$.$error || this.isOtpRequired) {
        this.isProcessing = true;
        this.store.dispatch('commons/setCompanyUserDetails', {
          'name': this.name,
          'lastname': this.lastName,
          'phone_number': this.telephoneNumber
        })
          .then(res => {
            console.log("Response", res);
            if (res.data.success) {
              if (res.data.result.extras === 'otp') {
                this.isOtpRequired = true;
                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result.message,
                  success: true
                }, 10000); // 10s
              } else {
                this.editing = false;
                this.isOtpRequired = false;
                this.store.dispatch('auth/getUserData');
                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result,
                  success: true
                }, 10000); // 10s
              }
            } else {
              this.isProcessing = false;
              this.$notify({
                group: "foo",
                title: "Something went wrong",
                text: res.data.error,
                success: false
              }, 10000); // 10s
            }
          });
      }
    },
    saveOtp() {
      this.v$.$validate(); // Check all inputs
      if (!this.v$.$error) {
        this.isProcessing = true;
        this.store.dispatch('commons/CompanyUserDetailsOtpVerification', {
          'otp': this.otp
        })
          .then(res => {
            console.log("Response", res);
            if (res.data.success) {
              this.editing = false;
              this.store.dispatch('auth/getUserData');
              this.isOtpRequired = false;
              this.$notify({
                group: "foo",
                title: "Success Message",
                text: res.data.result,
                success: true
              }, 10000); // 10s
            } else {
              this.isProcessing = false;
              this.$notify({
                group: "foo",
                title: "Something went wrong",
                text: res.data.error,
                success: false
              }, 10000); // 10s
            }
          });
      }
    },
    activate() {
      this.editing = !this.editing;
      if (this.editing) {
        this.$parent.activateAbout();
      }
      this.getExistingValues();
    },
    getExistingValues() {
      const user = this.store.state.auth.companyUserData;
      this.name = user.name;
      this.lastName = user.lastname;
      this.telephoneNumber = user.phone_number;
    }
  },
  validations() {
    return {
      name: { required },
      lastName: { required },
      telephoneNumber: { required },
      otp: {
        required: requiredIf(this.isOtpRequired)
      }
    };
  }
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
