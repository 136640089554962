<template>
    <div class="w-full">

        <div class="bg-white py-4 px-6 gap-2 lg:px-12 flex items-center mb-3">
            <h1 class="heading-h1">{{ $t('nav_create_a_job') }}</h1>
            <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span>
        </div>

        <!--Info Block Start-->
        <div class="mt-3 p-3 lg:p-7 bg-white">
            <textarea v-model="jobDescription" class="textarea-field" :placeholder="$t('new_job_description')" />

            <div class="hidden lg:block h-[1px] my-10 bg-gray-300"></div>
            <div class="lg:grid lg:grid-cols-2 gap-6">
                <div class="group" :class="{ 'has-input-error': v$.jobDetails.jobTitle.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Job title</label>
                    <multiselect v-model="jobDetails.jobTitle" :options="jobTitleOptions" :searchable="true"
                        :close-on-select="true" placeholder="Choose a job title" label="title" track-by="id"
                        @blur="v$.jobDetails.jobTitle.$touch()" />

                    <transition name="fade">
                        <p v-if="v$.jobDetails.jobTitle.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.jobDetails.jobTitle.$errors[0].$message }}
                        </p>
                    </transition>

                </div>
                <div class="group" :class="{ 'has-input-error': v$.jobDetails.jobSector.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Job Sector</label>
                    <multiselect v-model="jobDetails.jobSector" :options="jobSectorOptions" :searchable="true"
                        :close-on-select="true" placeholder="Choose a Job Sector" label="title" track-by="id" />
                    <transition name="fade">
                        <p v-if="v$.jobDetails.jobSector.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.jobDetails.jobSector.$errors[0].$message }}
                        </p>
                    </transition>
                </div>
                <div class="group" :class="{ 'has-input-error': v$.jobDetails.workingHours.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Working Hours</label>
                    <multiselect v-model="jobDetails.workingHours" :options="jobHourOptions" :searchable="true"
                        :close-on-select="true" placeholder="Choose Working Hours" label="name" track-by="id" />
                    <transition name="fade">
                        <p v-if="v$.jobDetails.workingHours.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.jobDetails.workingHours.$errors[0].$message }}
                        </p>
                    </transition>
                </div>
                <div class="group" :class="{ 'has-input-error': v$.jobDetails.salary.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Salary</label>
                    <multiselect v-model="jobDetails.salary" :options="salaryOptions" :searchable="true"
                        :close-on-select="true" placeholder="Choose Job Salary" label="name" track-by="id" />
                    <transition name="fade">
                        <p v-if="v$.jobDetails.salary.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.jobDetails.salary.$errors[0].$message }}
                        </p>
                    </transition>
                </div>
                <div class="group" :class="{ 'has-input-error': v$.jobDetails.workLocation.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Location</label>
                    <multiselect v-model="jobDetails.workLocation" :options="placeOptions" :searchable="true"
                        :close-on-select="true" placeholder="Search for a location" label="name" track-by="id"
                        @search-change="fetchLocationAutocomplete" @select="onLocationSelect" />
                    <transition name="fade">
                        <p v-if="v$.jobDetails.workLocation.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.jobDetails.workLocation.$errors[0].$message }}
                        </p>
                    </transition>
                </div>
                <div class="group" :class="{ 'has-input-error': v$.jobDetails.numberOfYears.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Number Of Years (experience)</label>
                    <multiselect v-model="jobDetails.numberOfYears" :options="numberOfYearsOptions" :searchable="true"
                        :close-on-select="true" placeholder="Number Of Years (Experience)" label="name" track-by="id" />
                    <transition name="fade">
                        <p v-if="v$.jobDetails.numberOfYears.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.jobDetails.numberOfYears.$errors[0].$message }}
                        </p>
                    </transition>
                </div>
                <div class="group" :class="{ 'has-input-error': v$.placementDate.$error }">
                    <label for="select" class="block text-gray-700 mb-2">Select Start and End dates</label>
                    <DatePickerComponent class="w-full" :min-date="new Date()" @updateDate="handleDateUpdate" />
                    <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    <transition name="fade">
                        <p v-if="v$.placementDate.$error" class="text-red-500 text-sm mt-1">
                            {{ v$.placementDate.$errors[0].$message }}
                        </p>
                    </transition>
                </div>

            </div>
        </div>
        <!--Info Block End-->

        <!-- Tailwind CSS container for map -->
        <div v-if="jobDetails.workLocation" class="relative w-full h-96 my-10">
            <div id="googleMapInVue" class="absolute inset-0 w-full h-full rounded-lg shadow-lg"></div>
        </div>


        <!--Education Level Block Start-->
        <div class="mt-3 bg-white p-7">
            <HeadingComponent :heading="$t('minimum_education_level_of_candidate')" />
            <div v-for="(education, index) in jobDetails.educationField" :key="index" class="mb-3">
                <div class="flex justify-end mb-1">
                    <button class="bg-main p-1 rounded " :class="{ 'hidden': jobDetails.educationField.length <= 1 }"
                        @click="remove(index, 'educationField')">
                        <IconMinus class="[&_path]:fill-white w-6 h-6" />
                    </button>
                </div>
                <div class="flex flex-col lg:flex-row gap-3 lg:gap-6 w-full">
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('Education Level Of Candidate') }}</label>
                        <multiselect v-model="jobDetails.educationField[index]['education_field_id']"
                            :options="educationFieldOptions" :searchable="true" :close-on-select="true"
                            placeholder="Education Level" label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('Education Level Of Candidate') }}</label>
                        <multiselect v-model="jobDetails.educationField[index]['education_level_id']"
                            :options="educationLevelOptions" :searchable="true" :close-on-select="true"
                            placeholder="Education Level" label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>
                </div>

                <div class="mt-9">
                    <!-- <RadioButton :value="jobDetails.educationField[index]['graduated']"
                        v-model:updateValue="education.isGraduated" :button1="{
                            label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                        }" :button2="{
                            label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
                        }" /> -->

                    <RadioButton :button1="{ id: 'job-eductation-field-' + index, label: 'Graduated', value: true }"
                        :button2="{ id: 'job-eductation-field-not-graduated-' + index, label: 'Not Graduated', value: false }"
                        v-model="jobDetails.educationField[index]['graduated']"
                        :name="'job-eductation-field-group' + index" />
                </div>
            </div>
            <div class="mt-7 lg:mt-14">
                <AddButton @addMore="addEducation()" />
            </div>
        </div>
        <!--Education Level Block End-->

        <!--Software Knowledge Start-->
        <div class="mt-3 bg-white p-7">
            <HeadingComponent :heading="$t('software_knowledge')" />
            <div v-for="(knowledge, index) in jobDetails.softwareKnowledge" :key="index" class="mb-3">
                <div class="flex justify-end mb-1" v-if="jobDetails.softwareKnowledge.length > 1">
                    <button class="bg-main p-1 rounded " :class="{ 'hidden': jobDetails.softwareKnowledge.length <= 1 }"
                        @click="remove(index, 'educationField')">
                        <IconMinus class="[&_path]:fill-white w-6 h-6" />
                    </button>
                </div>
                <div class="flex flex-col lg:flex-row gap-3 lg:gap-6 w-full">
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('Software Knowledge') }}</label>
                        <multiselect v-model="jobDetails.softwareKnowledge[index]['software_id']"
                            :options="softwareOptions" :searchable="true" :close-on-select="true" placeholder="Software"
                            label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('Software Knowledge Level') }}</label>
                        <multiselect v-model="jobDetails.softwareKnowledge[index]['software_knowledge_level_id']"
                            :options="softwareKnowledgeLevelOptions" :searchable="true" :close-on-select="true"
                            placeholder="Software Knowledge Level" label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>

                </div>

                <div class="mt-9">
                    <RadioButton
                        :button1="{ id: 'software-knowledge-graduated-' + index, label: 'Graduated', value: true }"
                        :button2="{ id: 'software-knowledge-not-graduated-' + index, label: 'Not Graduated', value: false }"
                        v-model="jobDetails.softwareKnowledge[index]['graduated']"
                        :name="'job-software-knowledge-group' + index" />
                </div>



            </div>
            <div class="mt-7 lg:mt-14">
                <AddButton @addMore="addSoftwareKnowledge()" />
            </div>
        </div>
        <!--Software Knowledge End-->


        <!--Language Knowledge Start-->
        <div class="mt-3 bg-white p-7">
            <HeadingComponent :heading="$t('knowledge_of_languages')" />
            <div v-for="(knowledge, index) in jobDetails.knowledgeOfLanguages" :key="index" class="mb-3">
                <div class="flex justify-end mb-1" v-if="jobDetails.knowledgeOfLanguages.length > 1">
                    <button class="bg-main p-1 rounded "
                        :class="{ 'hidden': jobDetails.knowledgeOfLanguages.length <= 1 }"
                        @click="remove(index, 'knowledgeOfLanguages')">
                        <IconMinus class="[&_path]:fill-white w-6 h-6" />
                    </button>
                </div>
                <div class="flex flex-col lg:flex-row gap-3 lg:gap-6 w-full">
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('Language Knowledge') }}</label>
                        <multiselect v-model="jobDetails.knowledgeOfLanguages[index]['language']"
                            :options="languageOptions" :searchable="true" :close-on-select="true" placeholder="Software"
                            label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>
                    <div class=" w-full">

                        <div class="flex  flex-col lg:flex-row gap-5">
                            <div class="flex flex-col flex-1">
                                <label for="select" class="block text-gray-700 mb-2">{{
                                    $t('Speaking Level') }}</label>
                                <multiselect v-model="jobDetails.knowledgeOfLanguages[index]['speaking_level_id']"
                                    :options="softwareKnowledgeLevelOptions" :searchable="true" :close-on-select="true"
                                    placeholder="Speaking Level" label="name" track-by="id" />
                            </div>
                            <div class="flex flex-col flex-1">
                                <label for="select" class="block text-gray-700 mb-2">{{
                                    $t('Writing Level') }}</label>
                                <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                                <multiselect v-model="jobDetails.knowledgeOfLanguages[index]['writing_level_id']"
                                    :options="softwareKnowledgeLevelOptions" :searchable="true" :close-on-select="true"
                                    placeholder="Writing Level" label="name" track-by="id" />
                                <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt-9">
                    <RadioButton
                        :button1="{ id: 'language-knowledge-graduated-' + index, label: 'Graduated', value: true }"
                        :button2="{ id: 'language-knowledge-not-graduated-' + index, label: 'Not Graduated', value: false }"
                        v-model="jobDetails.knowledgeOfLanguages[index]['graduated']"
                        :name="'language-knowledge-group' + index" />
                </div>
            </div>
            <div class="mt-7 lg:mt-14">
                <AddButton @addMore="addKnowledgeOfLanguages()" />
            </div>
        </div>
        <!--Language Knowledge End-->


        <!--Driving license block Start-->
        <div class="mt-3 bg-white p-7">
            <HeadingComponent :heading="$t('create_job_driving_license_title')" />
            <div v-for="(education, index) in jobDetails.drivingLicense" :key="index" class="mb-3">
                <div class="flex justify-end mb-1">
                    <button class="bg-main p-1 rounded " :class="{ 'hidden': jobDetails.drivingLicense.length <= 1 }"
                        @click="remove(index, 'drivingLicense')">
                        <IconMinus class="[&_path]:fill-white w-6 h-6" />
                    </button>
                </div>
                <div class="flex flex-col lg:flex-row gap-3 lg:gap-6 w-full">
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('create_job_driving_license_title') }}</label>
                        <multiselect v-model="jobDetails.drivingLicense[index]['drivingLicense']"
                            :options="drivingLicenseOptions" :searchable="true" :close-on-select="true"
                            placeholder="Driving License" label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>
                </div>

                <div class="mt-9">
                    <RadioButton
                        :button1="{ id: 'driving-license-graduated-' + index, label: 'Graduated', value: true }"
                        :button2="{ id: 'driving-license-not-graduated-' + index, label: 'Not Graduated', value: false }"
                        v-model="jobDetails.drivingLicense[index]['graduated']"
                        :name="'driving-license-group' + index" />
                </div>
            </div>
            <div class="mt-7 lg:mt-14">
                <AddButton @addMore="addDrivingLicenseREquirement()" />
            </div>
        </div>
        <!--Driving license block End-->



        <!--Competencies block Start-->
        <div class="mt-3 bg-white p-7">
            <HeadingComponent :heading="$t('my_competencies')" />
            <div v-for="(education, index) in jobDetails.competencies" :key="index" class="mb-3">
                <div class="flex justify-end mb-1 mt-10">
                    <button class="bg-main p-1 rounded " :class="{ 'hidden': jobDetails.competencies.length <= 1 }"
                        @click="remove(index, 'competencies')">
                        <IconMinus class="[&_path]:fill-white w-6 h-6" />
                    </button>
                </div>
                <div class="flex flex-col lg:flex-row gap-3 lg:gap-6 w-full">
                    <div class="group w-full">
                        <label for="select" class="block text-gray-700 mb-2">{{
                            $t('my_competencies') }}</label>
                        <multiselect v-model="jobDetails.competencies[index]['education_field_id']"
                            :options="competencyOptions" :searchable="true" :close-on-select="true"
                            placeholder="Competencies" label="name" track-by="id" />
                        <!-- <span v-if="!$v.selectedJob.$pending && !$v.selectedJob.$model">Please select a job title.</span> -->
                    </div>
                </div>
            </div>
            <div class="mt-7 lg:mt-14">
                <AddButton @addMore="addCompetencies()" />
            </div>
        </div>
        <!--Competencies block End-->




        <div class="my-16 flex justify-center">
            <button @click="submitForm" class="bg-main text-white text-base py-3 px-12 rounded">{{
                $t('create_job_submit') }}</button>
        </div>
    </div>
</template>
<script>
import DatePickerComponent from '@/components/common/DatePickerComponent.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import RadioButton from '@/components/common/RadioButton.vue';
import IconStreetView from '@/components/icons/IconStreetView.vue';

import { useStore } from 'vuex';
// import useVuelidate from '@vuelidate/core'
import useValidate from '@vuelidate/core'
import { required, numeric, email, requiredIf, minLength, sameAs, minValue, helpers } from '@vuelidate/validators'
import Multiselect from 'vue-multiselect';
import { mapActions } from 'vuex';

export default {
    name: 'Create Job Component',
    components: {
        Multiselect,
        SelectInputComponent,
        HeadingComponent,
        AddButton,
        IconMinus,
        RadioButton,
        IconStreetView,
        DatePickerComponent
    },
    data() {
        return {
            v$: useValidate(),
            store: useStore(),
            jobTitle: '', // Container for a single job title, could be an array if there are multiple
            jobDescription: '', // Description field (not in the returned list, manually added)
            placementDate: '', // Date field (not in the list, manually added)
            completionDate: '', // Date field (not in the list, manually added)
            professionOfCandidate: '', // Container for candidate profession (job titles or job sectors)
            jobSelectorOfNewService: '', // Container for selecting new service (linked to job sectors)
            positionLevelOfCandidate: '', // Container for career level of candidate (career_levels)
            workingHours: '', // Container for working hours (job_hours)
            workLocation: null, // Container for work location (could be added if needed)
            autocompleteService: null, // Container for work location (could be added if needed)
            placeOptions: [], // Container for work location (could be added if needed)
            salary: '', // Container for salary information (job_salaries)
            experience: '', // Container for experience (could be tied to competencies or job sectors)
            startWorkingPeriod: '', // Container for start period (available_from_times)
            employmentType: '', // Container for employment type (employment_types)
            age: '', // Container for age (no direct match, but could be added as needed)
            educationLevel: [], // Array to hold education levels (education_levels)
            softwareKnowledge: [], // Array for software knowledge (software)

            knowledgeOfLanguages: [], // Array for languages (languages)
            drivingLicense: [], // Array for driving licenses (driver_licenses)
            competencies: [], // Array for competencies (competencies)
            jobTitleOptions: [], // Array for available job titles (job_titles)
            jobSectorOptions: [], // Array for available job sectors (job_sectors)
            careerLevelOptions: [], // Array for available career levels (career_levels)
            jobHourOptions: [], // Array for available working hours (job_hours)
            salaryOptions: [], // Array for available salary options (job_salaries)
            availableFromTimeOptions: [], // Array for available start times (available_from_times)
            employmentTypeOptions: [], // Array for available employment types (employment_types)
            educationLevelOptions: [], // Array for education levels (education_levels)
            educationFieldOptions: [], // Array for education levels (education_levels)
            softwareOptions: [], // Array for software options (software)
            softwareKnowledgeLevelOptions: [], // Array for software options (software)
            languageOptions: [], // Array for languages (languages)
            drivingLicenseOptions: [], // Array for driving licenses (driver_licenses)
            competencyOptions: [], // Array for competencies (competencies)
            numberOfYearsOptions: [], // Array for number of years experience
            jobCreationParameters: null, // Placeholder object for job creation parameters (can hold all data as needed),
            map: null,
            marker: null,
            jobDetails: {
                workLocation: null,
                jobTitle: null, // Initially set to null, can be updated later with selected job title
                jobSector: null, // Initially set to null, can be updated with selected job sector(s)
                // professionOfCandidate: null, // Set to null initially
                // jobSelectorOfNewService: null, // Set to null initially
                // positionLevelOfCandidate: null, // Set to null initially
                workingHours: null, // Set to null initially
                salary: null, // Set to null initially
                // experience: null, // Set to null initially
                startWorkingPeriod: null, // Set to null initially
                // employmentType: null, // Set to null initially

                educationField: [
                    { 'education_field_id': null, 'education_level_id': null, 'graduated': false }
                ], // Set to null initially
                softwareKnowledge: [
                    { 'software_id': null, 'software_knowledge_level_id': null, 'graduated': false }
                ], // Set to null initially
                knowledgeOfLanguages: [
                    { 'language': null, 'speaking_level_id': null, 'writing_level_id': null, 'graduated': false }
                ], // Set to null initially
                drivingLicense: [
                    { 'drivingLicense': null, 'graduated': false }
                ], // Set to null initially
                competencies: [
                    { minimumCompetencies: null }
                ], // Set to null initially
                numberOfYears: null, // Set to null initially
            },
        }
    },


    methods: {
        ...mapActions({
            createJobForCompany: 'commons/createJobForCompany'
        }),

        addEducation() {

            // this.educationLevel.push({
            //     minimumEducationLevel: '',
            //     educationLevel: '',
            //     isGraduated: null
            // })
            this.jobDetails.educationField.push({ 'education_field_id': null, 'education_level_id': null, 'graduated': false });
        },
        addSoftwareKnowledge() {
            // this.softwareKnowledge.push({
            //     minimumSoftwareKnowledge: '',
            //     levelOfSoftwareKnowledge: '',
            //     isGraduated: null
            // })

            this.jobDetails.softwareKnowledge.push({ 'software_id': null, 'software_knowledge_level_id': null, 'graduated': false });
        },
        addKnowledgeOfLanguages() {
            // this.jobDetails.knowledgeOfLanguages.push({
            //     language_id: null,
            //     speaking_level_id: '',
            //     writing_level_id: '',
            //     graduated: null
            // })


            this.jobDetails.knowledgeOfLanguages.push({ 'language': null, 'speaking_level_id': '', 'writing_level_id': '', 'graduated': false });
        },
        addDrivingLicenseREquirement() {
            // this.drivingLicense.push({
            //     drivingLicense: null,
            //     graduated: null
            // })

            this.jobDetails.drivingLicense.push({ 'drivingLicense': null, 'graduated': false });
        },
        addCompetencies() {
            // this.competencies.push({
            //     minimumCompetencies: ''
            // })

            this.jobDetails.competencies.push({ 'minimumCompetencies': null });
        },
        addJobTitles() {
            this.store.dispatch('commons/fetchJobCreationParams').then(() => {
                // Safely access the updated state after the action is completed
                this.jobCreationParameters = this.store.state.commons.jobCreationParams;

            }).catch(error => {
                console.error('Failed to fetch job creation params:', error);
            });
        },

        /**@param index: Remove element number
         * @param field: On which field you want to remove element. 
         *               must note that field name must be same as data property.
         */
        remove(index, field) {
            if (this.jobDetails[`${field}`].length > 1) {
                this.jobDetails[`${field}`].splice(index, 1)
            }
        },

        // Update fetchLocationAutocomplete to include lat and lng
        fetchLocationAutocomplete(query) {
            if (!query || !this.autocompleteService) return;

            this.autocompleteService.getPlacePredictions(
                { input: query, types: ["geocode"] },
                (predictions, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        this.placeOptions = predictions.map((place) => ({
                            location_id: place.place_id,
                            name: place.description,
                            lat: null, // Initialize lat and lng to null
                            lng: null,
                        }));
                    }
                }
            );
        },

        onLocationSelect() {
            // Ensure selectedPlace contains the 'id' (place_id)
            setTimeout(() => {
                if (this.jobDetails.workLocation && this.jobDetails.workLocation.location_id) {
                    this.fetchPlaceDetails(this.jobDetails.workLocation.location_id);
                }
            }, 1000)

        },
        // When a location is selected, fetch the place details and update workLocation
        fetchPlaceDetails(placeId) {
            console.log("Fetching actual details??");
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails({ placeId: placeId }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    // Extract lat and lng from the selected place details
                    const { lat, lng } = place.geometry.location;

                    // Update jobDetails.workLocation with the full object
                    this.jobDetails.workLocation = {
                        location_id: place.place_id,
                        name: place.name,
                        lat: lat(),
                        lng: lng(),
                    };

                    this.loadMap();
                }
            });
        },

        loadMap() {
            if (!this.jobDetails.workLocation) return;

            // Set up map options (centered at the selected location)
            const mapOptions = {
                center: new google.maps.LatLng(this.jobDetails.workLocation.lat, this.jobDetails.workLocation.lng),
                zoom: 16, // Zoom level
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };

            // Initialize the map
            this.map = new google.maps.Map(document.getElementById("googleMapInVue"), mapOptions);

            // Place the marker
            this.marker = new google.maps.Marker({
                position: new google.maps.LatLng(this.jobDetails.workLocation.lat, this.jobDetails.workLocation.lng),
                map: this.map,
                title: this.jobDetails.workLocation.name, // You can customize the marker's title
            });
        },

        scrollToError() {
            // If there's an error, scroll to the first invalid field
            // Find the first element with the error class
            setTimeout(() => {
                const firstErrorField = document.querySelector('.has-input-error');
                if (firstErrorField) {
                    firstErrorField.scrollIntoView({
                        behavior: "smooth",
                        block: "center", // Scroll to the center of the page
                    });
                }
            }, 50);

        },

        handleDateUpdate(dateArray) {
            this.placementDate = dateArray[0];
            this.completionDate = dateArray[1];
            // console.log(dateArray[0]);

        },

        submitForm() {

            // this.$v.$touch();
            this.v$.$validate(); // checks all inputs
            console.log("Form preparing to submit", this.jobDetails);
            if (this.v$.$error) {
                // If the form is invalid, show a validation error
                // alert('Please fill out all required fields.');
                this.scrollToError();
                console.log("Form is invalid", this.v$.$error);
                console.log("listing errors", this.v$.$errors);
                return;
            }

            this.jobDetails.jobDescription = this.jobDescription;

            console.log('Job payload:', JSON.stringify(this.jobDetails, null, 2));


            console.log("Form submitted", this.jobDetails);

            // Create a simplified payload with only the necessary IDs
            const optimizedPayload = {
                workLocation: {
                    location_id: this.jobDetails.workLocation.location_id,
                    lat: this.jobDetails.workLocation.lat,
                    lng: this.jobDetails.workLocation.lng
                },
                jobTitle_id: this.jobDetails.jobTitle.id,
                jobSector_id: this.jobDetails.jobSector.id,
                workingHours_id: this.jobDetails.workingHours.id,
                salary_id: this.jobDetails.salary.id,
                startWorkingPeriod: this.jobDetails.startWorkingPeriod,

                // Add jobDetails object containing jobDescription
                jobDetails: {
                    jobDescription: this.jobDetails.jobDescription
                    // Add other job details here if needed
                },

                // Simplify arrays to include only necessary IDs
                educationField: this.jobDetails.educationField.map(item => ({
                    education_field_id: item.education_field_id.id,
                    education_level_id: item.education_level_id.id,
                    graduated: item.graduated
                })),

                softwareKnowledge: this.jobDetails.softwareKnowledge.map(item => ({
                    software_id: item.software_id.id,
                    software_knowledge_level_id: item.software_knowledge_level_id.id,
                    graduated: item.graduated
                })),

                knowledgeOfLanguages: this.jobDetails.knowledgeOfLanguages.map(item => ({
                    language_id: item.language.id,
                    speaking_level_id: item.speaking_level_id.id,
                    writing_level_id: item.writing_level_id.id,
                    graduated: item.graduated
                })),

                drivingLicense: this.jobDetails.drivingLicense.map(item => ({
                    drivingLicense_id: item.drivingLicense.id,
                    graduated: item.graduated
                })),

                competencies: this.jobDetails.competencies.map(item =>
                    item.education_field_id.id
                ),

                numberOfYears_id: this.jobDetails.numberOfYears.id,
                job_description: this.jobDetails.jobDescription,
                placement_date: this.placementDate,
                completion_date: this.completionDate
            };

            // Then dispatch the optimized payload

            // console.log('Optimized payload:', JSON.stringify(optimizedPayload, null, 2));
            this.createJobForCompany(optimizedPayload)
                .then(
                    (response) => {
                        if (response.data.success) {
                            // console.log("Job created successfully", response);

                            this.$router.push({ name: 'dashboard' });
                        } else {
                            console.error("Error creating job", response);
                        }
                    }
                )
                .catch(
                    (error) => {
                        console.error("Error creating job", error);
                    }
                );
        },

        fillRandomValues() {
            // Helper function to get random item from array
            const getRandomItem = (array) => {
                if (!array || array.length === 0) return null;
                return array[Math.floor(Math.random() * array.length)];
            };

            // Sample job descriptions
            const descriptions = [
                "Looking for an experienced professional to join our team.",
                "Great opportunity for career advancement in a dynamic environment.",
                "Join our growing company and work with cutting-edge technology.",
                "We need a detail-oriented individual for this critical role.",
                "Exciting position with competitive compensation and benefits."
            ];

            // Set random job description
            this.jobDescription = getRandomItem(descriptions);

            // Set basic fields
            this.jobDetails.jobTitle = getRandomItem(this.jobTitleOptions);
            this.jobDetails.jobSector = getRandomItem(this.jobSectorOptions);
            this.jobDetails.workingHours = getRandomItem(this.jobHourOptions);
            this.jobDetails.salary = getRandomItem(this.salaryOptions);
            this.jobDetails.numberOfYears = getRandomItem(this.numberOfYearsOptions);

            // Create completely new array objects to ensure reactivity
            const newEducationField = [];
            this.jobDetails.educationField.forEach(() => {
                newEducationField.push({
                    education_field_id: getRandomItem(this.educationFieldOptions),
                    education_level_id: getRandomItem(this.educationLevelOptions),
                    graduated: Math.random() >= 0.5
                });
            });
            this.jobDetails.educationField = newEducationField;

            const newSoftwareKnowledge = [];
            this.jobDetails.softwareKnowledge.forEach(() => {
                newSoftwareKnowledge.push({
                    software_id: getRandomItem(this.softwareOptions),
                    software_knowledge_level_id: getRandomItem(this.softwareKnowledgeLevelOptions),
                    graduated: Math.random() >= 0.5
                });
            });
            this.jobDetails.softwareKnowledge = newSoftwareKnowledge;

            const newKnowledgeOfLanguages = [];
            this.jobDetails.knowledgeOfLanguages.forEach(() => {
                newKnowledgeOfLanguages.push({
                    language: getRandomItem(this.languageOptions),
                    speaking_level_id: getRandomItem(this.softwareKnowledgeLevelOptions),
                    writing_level_id: getRandomItem(this.softwareKnowledgeLevelOptions),
                    graduated: Math.random() >= 0.5
                });
            });
            this.jobDetails.knowledgeOfLanguages = newKnowledgeOfLanguages;

            const newDrivingLicense = [];
            this.jobDetails.drivingLicense.forEach(() => {
                newDrivingLicense.push({
                    drivingLicense: getRandomItem(this.drivingLicenseOptions),
                    graduated: Math.random() >= 0.5
                });
            });
            this.jobDetails.drivingLicense = newDrivingLicense;

            // Handle competencies
            const newCompetencies = [];
            this.jobDetails.competencies.forEach(() => {
                newCompetencies.push({
                    education_field_id: getRandomItem(this.competencyOptions)
                });
            });
            this.jobDetails.competencies = newCompetencies;

            // Set dates
            const today = new Date();
            const futureDate = new Date();
            futureDate.setDate(today.getDate() + 30 + Math.floor(Math.random() * 60)); // 30-90 days
            this.handleDateUpdate([today, futureDate]);

            // Force Vue to reevaluate the bindings
            this.$nextTick(() => {
                console.log("Random values filled", this.jobDetails);
            });
        },
    },

    mounted() {
        // this.addEducation();
        // this.addSoftwareKnowledge();
        // this.addKnowledgeOfLanguages();
        // this.addDrivingLicenseREquirement();
        // this.addCompetencies();
        this.addJobTitles();
        if (window.google) {
            this.autocompleteService = new google.maps.places.AutocompleteService();
        } else {
            console.error("Google Maps API is not loaded!");
        }

        // Automatically fill random values when component loads
        setTimeout(() => {
            this.fillRandomValues();
        }, 1500);
    },
    watch: {
        'jobCreationParameters': {
            handler(newValue) {
                // Ensure job_creation_parameters is populated before using it
                if (newValue) {
                    console.log('Job params Fetched:', newValue);

                    // Populate jobTitleOptions
                    if (newValue.job_titles) {
                        this.jobTitleOptions = newValue.job_titles.map(job => ({
                            ...job,
                            label: job.title, // Add a label for vue-multiselect
                        }));
                    }

                    // Populate jobSectorOptions
                    if (newValue.job_sectors) {
                        this.jobSectorOptions = newValue.job_sectors.map(sector => ({
                            ...sector,
                            label: sector.name, // Assuming 'name' is the label for job sector
                        }));
                    }

                    // Populate careerLevelOptions
                    if (newValue.career_levels) {
                        this.careerLevelOptions = newValue.career_levels.map(level => ({
                            ...level,
                            label: level.name, // Assuming 'name' is the label for career level
                        }));
                    }

                    // Populate jobHourOptions
                    if (newValue.job_hours) {
                        this.jobHourOptions = newValue.job_hours.map(hour => ({
                            ...hour,
                            label: hour.range, // Assuming 'range' is the label for job hours
                        }));
                    }

                    // Populate salaryOptions
                    if (newValue.job_salaries) {
                        this.salaryOptions = newValue.job_salaries.map(salary => ({
                            ...salary,
                            label: salary.amount, // Assuming 'amount' is the label for salary
                        }));
                    }

                    // Populate availableFromTimeOptions
                    if (newValue.available_from_times) {
                        this.availableFromTimeOptions = newValue.available_from_times.map(time => ({
                            ...time,
                            label: time.time, // Assuming 'time' is the label for available start time
                        }));
                    }

                    // Populate employmentTypeOptions
                    if (newValue.employment_types) {
                        this.employmentTypeOptions = newValue.employment_types.map(type => ({
                            ...type,
                            label: type.type, // Assuming 'type' is the label for employment type
                        }));
                    }

                    // Populate educationLevelOptions
                    if (newValue.education_levels) {
                        this.educationLevelOptions = newValue.education_levels.map(level => ({
                            ...level,
                            label: level.name, // Assuming 'level' is the label for education level
                        }));
                    }
                    // Populate educationLevelOptions
                    if (newValue.education_fields) {
                        this.educationFieldOptions = newValue.education_fields.map(field => ({
                            ...field,
                            label: field.name, // Assuming 'level' is the label for education level
                        }));
                    }

                    // Populate softwareOptions
                    if (newValue.software) {
                        this.softwareOptions = newValue.software.map(software => ({
                            ...software,
                            label: software.name, // Assuming 'name' is the label for software
                        }));
                    }

                    // Populate languageOptions
                    if (newValue.languages) {
                        this.languageOptions = newValue.languages.map(language => ({
                            ...language,
                            label: language.name, // Assuming 'name' is the label for language
                        }));
                    }

                    // Populate drivingLicenseOptions
                    if (newValue.driver_licenses) {
                        this.drivingLicenseOptions = newValue.driver_licenses.map(license => ({
                            ...license,
                            label: license.license_type, // Assuming 'license_type' is the label for driving licenses
                        }));
                    }

                    // Populate competencyOptions
                    if (newValue.competencies) {
                        this.competencyOptions = newValue.competencies.map(competency => ({
                            ...competency,
                            label: competency.name, // Assuming 'name' is the label for competencies
                        }));
                    }
                    // Populate competencyOptions
                    if (newValue.number_of_years_experience) {
                        this.numberOfYearsOptions = newValue.number_of_years_experience.map(numberOfYears => ({
                            ...numberOfYears,
                            label: numberOfYears.name, // Assuming 'name' is the label for competencies
                        }));
                    }
                    // Populate software knowledge levels
                    if (newValue.software_knowledge_levels) {
                        this.softwareKnowledgeLevelOptions = newValue.software_knowledge_levels.map(softwareKnowledgeLvl => ({
                            ...softwareKnowledgeLvl,
                            label: softwareKnowledgeLvl.name, // Assuming 'name' is the label for competencies
                        }));
                    }
                }
            },
            deep: true // Deep watch to observe changes within the nested object
        }
    },

    validations() {
        return {
            placementDate: { required },
            jobDetails: {
                jobTitle: { required },
                jobSector: { required },
                // positionLevelOfCandidate: { required },
                workingHours: { required },
                salary: { required },
                // experience: { required, numeric },
                // startWorkingPeriod: { required },
                // employmentType: { required },
                numberOfYears: { required },

                educationField: {
                    $each: helpers.forEach({
                        education_level_id: { required },
                        education_field_id: { required },
                        graduated: { required }
                    })
                },
                softwareKnowledge: {
                    $each: helpers.forEach({
                        software_id: { required },
                        software_knowledge_level_id: { required },
                        graduated: { required }
                    })
                },
                knowledgeOfLanguages: {
                    $each: helpers.forEach({
                        language: { required },
                        speaking_level_id: { required },
                        writing_level_id: { required },
                        graduated: { required }
                    })
                },
                drivingLicense: {
                    $each: helpers.forEach({
                        drivingLicense: { required },
                        graduated: { required }
                    })
                },
                competencies: {
                    $each: helpers.forEach({
                        minimumCompetencies: { required }
                    })
                },

                workLocation: {
                    location_id: { required },
                    name: { required },
                    lat: { required, numeric },
                    lng: { required, numeric }
                },
            },
        };
    },

}
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";
</style>

<style>
/* Highlighted option in dropdown */
.multiselect__option--highlight {
    background-color: #36a8e0;
    /* Ensure the background changes */
    color: #fff;
    /* Ensure the text color changes */
}

.multiselect__option--highlight.multiselect__option::after {
    background-color: #36a8e0;
    /* Ensure the background changes */
    color: #fff;
}
</style>



<!-- AIzaSyDPGU9ztMbIl00z1nQtQfWGDXf3rrRDxy8 key -->

<!-- 9pHSgFrqhXzpS1cM-BIYLKy8SwY=  secret -->