<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('company_job_detail_software_education_level')" />

        <div>
            <!--Preview Start-->
            <div v-if="editing === false" class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('start_date') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('end_date') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_institution_name') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_education_level') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('faculty') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('specialty') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('diploma_certificate') }}</td>
                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in education" :key="index">
                            <td class="jobsTableRow table-border">{{ data.startDate }}</td>
                            <td class="jobsTableRow table-border">{{ data.completionDate }}</td>
                            <td class="jobsTableRow table-border">{{ data.nameOfTheSchool }}</td>
                            <td class="jobsTableRow table-border">{{ data.theLevelOfEducation }}</td>
                            <td class="jobsTableRow table-border">{{ data.faculty }}</td>
                            <td class="jobsTableRow table-border">{{ data.specialty }}</td>
                            <td class="jobsTableRow table-border">{{ data.diplomaCertificate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Preview End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index" class="mb-6">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>

                    <div class="flex flex-col lg:flex-row gap-4 lg:gap-7 ">
                        <div class="flex-1">
                            <InputFieldComponent :value="field.nameOfTheSchool"
                                :label="$t('resume_general_institution_name')" type="text"
                                v-model="field.nameOfTheSchool" class="flex-1 h-full" />
                        </div>
                        <div class="flex-1 flex flex-col sm:flex-row gap-4">
                            <SelectInputComponent :value="field.startDate"
                                :label="$t('edit_resume_employment_history_start_day')" :showBorder="true"
                                v-model="field.startDate" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                                class="flex-1" />
                            <SelectInputComponent :value="field.completionDate"
                                :label="$t('create_job_education_end_date_placeholder')" :showBorder="true"
                                v-model="field.completionDate"
                                :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row items-center gap-4 mt-4 lg:gap-7 lg:mt-7">
                        <SelectInputComponent :value="field.levelOfEducation"
                            :label="$t('company_job_detail_education_level')" :showBorder="true"
                            v-model="field.levelOfEducation" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                            class="flex-1 " />
                        <SelectInputComponent :value="field.direction" :label="$t('direction')" :showBorder="true"
                            v-model="field.direction" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                            class="flex-1" />
                    </div>

                    <div class="mt-9">
                        <RadioButton :value="field.isGraduated" v-model="field.isGraduated" :button1="{
                            label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                        }" :button2="{
                        label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
                    }" />
                    </div>
                </div>
                <div class="my-7 lg:my-14">
                    <AddButton @addMore="addMore()" />
                </div>

            </div>
            <!--Edit Mode End-->

        </div>
        <div class="mt-[30px] flex items-center gap-3">
            <div>
                <IconPdfFile />
                <p class="text-auth text-[8px] text-center">CV.pdf</p>
            </div>
            <div>
                <IconWordFile />
                <p class="text-auth text-[8px] text-center">CV.docx</p>
            </div>
            <div v-if="isResumePreview === true">
                <IconDownload />
            </div>
            <div v-if="editing === true">
                <IconAddDocument />
                <p class="text-main text-xs text-center">{{ $t('add_field') }}</p>
            </div>
        </div>
        <div v-if="isResumePreview === false" class="mt-8">
            <button @click="editing = !editing" class="edit-update-btn">
                {{ editing === true ? $t('update') : $t('edit') }}</button>
        </div>
    </div>
</template>
<script>
import IconPdfFile from '@/components/icons/IconPdfFile.vue';
import IconWordFile from '@/components/icons/IconWordFile.vue';
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import IconAddDocument from '@/components/icons/IconAddDocument.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';
import RadioButton from '@/components/common/RadioButton.vue';
export default {
    name: "Education",
    components: { IconPdfFile, IconWordFile, InputFieldComponent, SelectInputComponent, IconPlus, IconMinus, IconAddDocument, IconDownload, HeadingComponent, AddButton, RadioButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,
            fieldsArray: []

        }
    },
    computed: {
        education() {
            return [
                {
                    startDate: '05-05-2018',
                    completionDate: '05-05-2018',
                    nameOfTheSchool: 'ილიას სახელმწიფო უნივერსიტეტი',
                    theLevelOfEducation: 'უნივერსიტეტი',
                    faculty: 'ბიზნესის, ტექნოლოგიისა და განათლების ფაკულტეტი',
                    specialty: 'ბიზნესის ადმინისტრირება',
                    diplomaCertificate: 'დიახ'
                },
                {
                    startDate: '05-05-2019',
                    completionDate: '05-05-2019',
                    nameOfTheSchool: 'ილიას სახელმწიფო უნივერსიტეტი',
                    theLevelOfEducation: 'უნივერსიტეტი',
                    faculty: 'ბიზნესის, ტექნოლოგიისა და განათლების ფაკულტეტი',
                    specialty: 'ბიზნესის ადმინისტრირება',
                    diplomaCertificate: 'დიახ'
                },
                {
                    startDate: '05-05-2017',
                    completionDate: '05-05-2019',
                    nameOfTheSchool: 'ილიას სახელმწიფო უნივერსიტეტი',
                    theLevelOfEducation: 'უნივერსიტეტი',
                    faculty: 'ბიზნესის, ტექნოლოგიისა და განათლების ფაკულტეტი',
                    specialty: 'ბიზნესის ადმინისტრირება',
                    diplomaCertificate: 'დიახ'
                },
            ]
        },
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    nameOfTheSchool: '',
                    startDate: '',
                    completionDate: '',
                    levelOfEducation: '',
                    direction: '',
                    isGraduated: null
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    },
    mounted() {
        this.addMore();
    }
}
</script>
<style></style>