<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('current_situation_of_my_work')" />

        <div>
            <!--Preview Start-->
            <div v-if="editing === false" class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('employer_company_or_organization') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('position') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('sector') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_career_level') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_availability') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_employment_types') }}</td>

                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in myWork" :key="index">
                            <td class="jobsTableRow table-border">{{ data.employerCompanyOrganization }}</td>
                            <td class="jobsTableRow table-border">{{ data.position }}</td>
                            <td class="jobsTableRow table-border">{{ data.sector }}</td>
                            <td class="jobsTableRow table-border">{{ data.servicePositionLevel }}</td>
                            <td class="jobsTableRow table-border">{{ data.opportunity }}</td>
                            <td class="jobsTableRow table-border">{{ data.TypesOfEmployment }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Preview End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index" class="mb-6">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>

                    <div class="flex flex-col lg:flex-row  gap-4 lg:gap-7  ">
                        <InputFieldComponent :value="field.employerCompanyOrganization"
                            :label="$t('employer_company_or_organization')" type="text"
                            v-model="field.employerCompanyOrganization" class="flex-1" />
                        <SelectInputComponent :value="field.position" :label="$t('position')" :showBorder="true"
                            v-model="field.position" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                            class="flex-1" />
                    </div>
                    <div class="flex flex-col lg:flex-row items-center gap-4 mt-4 lg:gap-7 lg:mt-7">
                        <SelectInputComponent :value="field.sector" :label="$t('sector')" :showBorder="true"
                            v-model="field.sector" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                            class="flex-1 " />
                        <SelectInputComponent :value="field.servicePositionLevel"
                            :label="$t('resume_general_career_level')" :showBorder="true"
                            v-model="field.servicePositionLevel"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
                    </div>
                    <div class="flex flex-col lg:flex-row items-center gap-4 mt-4 lg:gap-7 lg:mt-7">
                        <SelectInputComponent :label="$t('resume_general_availability')" :showBorder="true"
                            v-model="field.opportunity" :value="field.opportunity"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1 " />
                        <SelectInputComponent :label="$t('resume_general_employment_types')" :showBorder="true"
                            v-model="field.TypesOfEmployment" :value="field.TypesOfEmployment"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
                    </div>
                </div>
                <div class="my-7 lg:my-14">
                    <AddButton @add-more="addMore()" />
                </div>

            </div>
            <!--Edit Mode End-->
        </div>
        <div v-if="editing === false" class="mt-[30px] flex items-center gap-3">
            <div>
                <IconPdfFile />
                <p class="text-auth text-[8px] text-center">CV.pdf</p>
            </div>
            <div>
                <IconWordFile />
                <p class="text-auth text-[8px] text-center">CV.docx</p>
            </div>

        </div>
        <div v-if="isResumePreview === false" class="mt-8">
            <button @click="editing = !editing" class="edit-update-btn">
                {{ editing === true ? $t('update') : $t('edit') }}</button>
        </div>
    </div>
</template>
<script>
import IconPdfFile from '@/components/icons/IconPdfFile.vue';
import IconWordFile from '@/components/icons/IconWordFile.vue';
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';

export default {
    name: "MyWork",
    components: { IconPdfFile, IconWordFile, InputFieldComponent, SelectInputComponent, IconPlus, IconMinus, HeadingComponent, AddButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,

            fieldsArray: [
                {
                    employerCompanyOrganization: '',
                    position: '',
                    sector: '',
                    servicePositionLevel: '',
                    opportunity: '',
                    TypesOfEmployment: ''
                },
            ]

        }
    },
    computed: {
        myWork() {
            return [
                {
                    employerCompanyOrganization: 'ABN',
                    position: 'გრაფიკული დიზაინერი',
                    sector: 'ონლაინ პლატფორმა',
                    servicePositionLevel: 'მენეჯერი',
                    opportunity: 'ხელმისაწვდომია',
                    TypesOfEmployment: 'მუდმივი სამსახური'
                }
            ]
        }
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    employerCompanyOrganization: '',
                    position: '',
                    sector: '',
                    servicePositionLevel: '',
                    opportunity: '',
                    TypesOfEmployment: ''
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    }
}
</script>
<style></style>