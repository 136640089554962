<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('experience')" />
        <div>
            <!--Preview Start-->
            <div v-if="editing === false" class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('start_date') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('end_date') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('resume_general_years_months') }}</td>
                            <td class="table-border jobsTableHeader">{{
                                $t('company_dashboard_edit_company_company_name') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('position') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('sector') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('location') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('job_description') }}</td>
                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in workExperience" :key="index">
                            <td class="jobsTableRow table-border">{{ data.startDate }}</td>
                            <td class="jobsTableRow table-border">{{ data.completionDate }}</td>
                            <td class="jobsTableRow table-border">{{ data.workingTime }}</td>
                            <td class="jobsTableRow table-border">{{ data.companyName }}</td>
                            <td class="jobsTableRow table-border">{{ data.position }}</td>
                            <td class="jobsTableRow table-border">{{ data.sector }}</td>
                            <td class="jobsTableRow table-border">{{ data.location }}</td>
                            <td class="jobsTableRow table-border">{{ data.jobDescription }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Preview End-->

            <!--Editor Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index" class="mb-6">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>
                    <InputFieldComponent :value="field.companyName" :label="$t('company_name')" type="text"
                        v-model="field.companyName" />
                    <div class="flex flex-col lg:flex-row items-center gap-4 mt-4 lg:gap-7 lg:mt-7">
                        <SelectInputComponent :value="field.selector" :label="$t('sector')" :showBorder="true"
                            v-model="field.selector" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                            class="flex-1 " />
                        <SelectInputComponent :value="field.location" :label="$t('location')" :showBorder="true"
                            v-model="field.location" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                            class="flex-1" />
                    </div>
                    <div class="flex flex-col lg:flex-row  gap-4 mt-4 lg:gap-7 lg:mt-7">
                        <div class="flex-1">
                            <SelectInputComponent :value="field.position" :label="$t('position')" :showBorder="true"
                                v-model="field.position" :options="['option 1', 'option 2', 'option 3', 'option 4']"
                                class="flex-1" />
                        </div>
                        <div class="flex-1 flex flex-col sm:flex-row gap-4">
                            <SelectInputComponent :label="$t('edit_resume_employment_history_start_day')"
                                :showBorder="true" :value="field.startDate" v-model="field.startDate"
                                :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1" />
                            <InputFieldComponent :value="field.endDate" :label="$t('end_date')" type="text"
                                v-model="field.endDate" class="flex-1 h-full" />
                        </div>
                    </div>
                    <div>
                        <textarea :placeholder="$t('job_description')" v-model="field.jobDescription"
                            class="h-52 font-light text-gray-900 text-sm font-bannerExtrasquare border-opacity-80 hide-resize rounded  max-h-52 mt-4 lg:mt-7 p-3 overflow-y-auto w-full focus:outline-none bg-white border border-form" />
                    </div>
                </div>
                <div class="my-7 lg:my-14">
                    <AddButton @add-more="addMore()" />
                </div>

            </div>
            <!--Editor Mode End-->

        </div>
        <div v-if="editing === false" class="mt-[30px] flex items-center gap-3">
            <div>
                <IconPdfFile />
                <p class="text-auth text-[8px] text-center">CV.pdf</p>
            </div>
            <div>
                <IconWordFile />
                <p class="text-auth text-[8px] text-center">CV.docx</p>
            </div>
            <div v-if="isResumePreview === true">
                <IconDownload />
            </div>
        </div>
        <div v-if="isResumePreview === false" class="mt-8">
            <button @click="editing = !editing" class="edit-update-btn">
                {{ editing === true ? $t('update') : $t('edit') }}</button>
        </div>
    </div>
</template>
<script>
import IconPdfFile from '@/components/icons/IconPdfFile.vue';
import IconWordFile from '@/components/icons/IconWordFile.vue';
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';
export default {
    name: "WorkExperience",
    components: { IconPdfFile, IconWordFile, InputFieldComponent, SelectInputComponent, IconPlus, IconMinus, IconDownload, HeadingComponent, AddButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,
            fieldsArray: []

        }
    },
    computed: {
        workExperience() {
            return [
                {
                    startDate: '05-05-2018',
                    completionDate: 'ამჟამად დასაქმებული',
                    workingTime: '5 წელი',
                    companyName: 'შ.პ.ს. ლილო პროდაქტ სერვისი',
                    position: 'გრაფიკული დიზაინერი',
                    sector: 'ონლაინ პლატფორმა',
                    location: 'ქ. თბილისი',
                    jobDescription: 'ციფრული და ბეჭვდითი ვიზუალების შექმნა'
                },
                {
                    startDate: '05-05-2019',
                    completionDate: 'ამჟამად დასაქმებული',
                    workingTime: '5 წელი',
                    companyName: 'შ.პ.ს BelinQed',
                    position: 'UI UX დიზაინერი',
                    sector: 'HR პლატფორმა',
                    location: 'ქ. თბილისი',
                    jobDescription: 'საიტის ვებ დიზაინი'
                },
                {
                    startDate: '05-05-2017',
                    completionDate: '05-05-2019',
                    workingTime: '5 წელი',
                    companyName: 'შ.პ.ს. ლილო პროდაქტ სერვისი',
                    position: 'გრაფიკული დიზაინერი',
                    sector: 'ონლაინ პლატფორმა',
                    location: 'ქ. თბილისი',
                    jobDescription: 'ციფრული და ბეჭვდითი ვიზუალების შექმნა'
                },
                {
                    startDate: '05-05-2016',
                    completionDate: '05-05-2017',
                    workingTime: '5 წელი',
                    companyName: 'შ.პ.ს BelinQed',
                    position: 'UI UX დიზაინერი',
                    sector: 'HR პლატფორმა',
                    location: 'ქ. თბილისი',
                    jobDescription: 'საიტის ვებ დიზაინი'
                },
                {
                    startDate: '05-05-2015',
                    completionDate: '05-05-2016',
                    workingTime: '5 წელი',
                    companyName: 'შ.პ.ს. ლილო პროდაქტ სერვისი',
                    position: 'გრაფიკული დიზაინერი',
                    sector: 'ონლაინ პლატფორმა',
                    location: 'ქ. თბილისი',
                    jobDescription: 'ციფრული და ბეჭვდითი ვიზუალების შექმნა'
                },
            ]
        },
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    companyName: '',
                    selector: '',
                    location: '',
                    position: '',
                    startDate: '',
                    completionDate: '',
                    jobDescription: '',
                    endDate: ''
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    },
    mounted() {
        this.addMore();
    }
}
</script>
<style></style>