<template>
    <div>
        <WorkExperience :is-resume-preview="false"/>
        <Education :is-resume-preview="false"/>
        <SoftwareKnowledge :is-resume-preview="false"/>
        <KnowledgeOfLanguages :is-resume-preview="false"/>
        <DrivingLicense :is-resume-preview="false"/>
        <MyCompetencies :is-resume-preview="false"/>
        <MySkills :is-resume-preview="false"/>
        <MyHobbiesAndInterest :is-resume-preview="false"/>
        <MaritalStatus :is-resume-preview="false"/>
        <MyWork :is-resume-preview="false"/>
    </div>
</template>
<script>
import IconStar from '@/components/icons/IconStar.vue';
import IconStarFilled from '@/components/icons/IconStarFiled.vue';
import WorkExperience from '@/components/candidate/sub-pages/my-resume/my-resume-components/WorkExperience.vue';
import Education from '@/components/candidate/sub-pages/my-resume/my-resume-components/Education.vue';
import SoftwareKnowledge from '@/components/candidate/sub-pages/my-resume/my-resume-components/SoftwareKnowledge.vue';
import KnowledgeOfLanguages from '@/components/candidate/sub-pages/my-resume/my-resume-components/KnowledgeOfLanguages.vue';
import DrivingLicense from '@/components/candidate/sub-pages/my-resume/my-resume-components/DrivingLicense.vue';
import MyCompetencies from '@/components/candidate/sub-pages/my-resume/my-resume-components/MyCompetencies.vue';
import MySkills from '@/components/candidate/sub-pages/my-resume/my-resume-components/MySkills.vue';
import MyHobbiesAndInterest from '@/components/candidate/sub-pages/my-resume/my-resume-components/MyHobbiesAndInterest.vue';
import MaritalStatus from '@/components/candidate/sub-pages/my-resume/my-resume-components/MaritalStatus.vue';
import MyWork from '@/components/candidate/sub-pages/my-resume/my-resume-components/MyWork.vue'
export default {
    name: 'OtherDetails',
    components: {
        IconStar,
        IconStarFilled,
        WorkExperience,
        Education,
        SoftwareKnowledge,
        KnowledgeOfLanguages,
        MyWork,
        DrivingLicense,
        MyCompetencies,
        MySkills,
        MyHobbiesAndInterest,
        MaritalStatus
    },
    data() {
        return {
        }
    },
    computed: {
        situationOfMyWork() {
            return [
                {
                    employerCompanyOrganization: 'ABN',
                    position: 'გრაფიკული დიზაინერი',
                    sector: 'ონლაინ პლატფორმა',
                    servicePositionLevel: 'მენეჯერი',
                    opportunity: 'ხელმისაწვდომია',
                    TypesOfEmployment: 'მუდმივი სამსახური'
                }
            ]
        }
    }
}
</script>