<template>
    <div v-if="openConfirmEmailAddress === true">
        <div class="fixed top-0 right-0 bottom-0 left-0 bg-jet-black bg-opacity-60 z-[60] w-screen"
            @click="$emit('toggleMessage')"></div>
        <div
            class="bg-white max-w-xs sm:max-w-[600px]  md:max-w-2xl z-[70]  m-auto fixed top-2/4 -translate-y-2/4  -translate-x-2/4 left-2/4 w-full p-3 sm:p-7">
            <div class="flex justify-end">
                <button @click="$emit('toggleMessage')">
                    <IconClose class="[&_path]:fill-dark-grey sm:w-4 sm:h-4" />
                </button>
            </div>
            <h3 class="text-nav text-lg  md:text-2xl font-bannerExtrasquareCaps md:px-4">Confirm Your Email Address</h3>
            <div class="mt-9 md:mt-16 font-bannerExtrasquareCaps text-xs sm:text-sm font-normal text-[#6f6f6f]">
                <p>We've sent an email to <span class="text-nav text-sm font-semibold">{{ email }}</span></p>
                <p>Please check you email and confirm</p>
                <div class="mt-[52px] sm:mt-12 pb-4">
                    <p>Didn't receive our confirmation email?</p>
                    <p>1. Check your spam box.</p>
                    <p>2. Resend confirmation email to <span class="text-nav text-sm font-semibold">{{ email }}</span> <span
                            class="text-main cursor-pointer font-semibold">Resend Now</span></p>
                    <p>3. Change another email address? <span class="text-main cursor-pointer font-semibold">Change
                            now</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IconClose from '../icons/IconClose.vue';
export default {
    name: "ConfirmYourEmailComponent",
    props: ["openConfirmEmailAddress"],
    emits: ["toggleMessage"],
    components: { IconClose }
}
</script>
