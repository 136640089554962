<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36  hidden md:block">{{
        $t('applicant_dashboard_edit_profile_form_email_address') }}</a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2">
        <span v-if="storedUserData">
          {{ storedUserData.email }}
        </span>
      </a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
        <span class="">Edit</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">
          {{ $t('applicant_dashboard_edit_profile_form_email_address') }}
        </h1>
      </div>
      <div class="form flex-row mt-5">
        <div class="relative z-0 w-full max-w-sm mx-auto">
          <InputFieldComponent :value="email" :label="$t('applicant_dashboard_edit_profile_form_email_address')"
            type="email" v-model="email" class="" :error="v$.email.$errors.length > 0" @onBlur="v$.email.$touch()"
            :errorMessage="v$.email.$errors.length > 0 ? v$.email.$errors[0].$message : ''" />
          <div class="mb-2 mt-6 group">
            <a href="#"
              class="text-right font-bannerExtrasquare flex justify-end text-main text-[10px] font-normal mb-2 hover:underline">დაგავიწყდათ
              პაროლი?</a>
            <InputFieldComponent :value="password" :label="$t('company_dashboard_edit_company_current_password')"
              type="password" v-model="password" class="" :error="v$.password.$errors.length > 0"
              @onBlur="v$.password.$touch()"
              :errorMessage="v$.password.$errors.length > 0 ? v$.password.$errors[0].$message : ''" />
          </div>
        </div>
      </div>

      <div class="flex mt-6">
        <button :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="updateEmail()">{{ $t('applicant_dashboard_edit_profile_form_update') }}</button>
      </div>
    </div>
    <Transition>
      <ConfirmYourEmailComponent :openConfirmEmailAddress="openConfirmEmailAddress"
        @toggleMessage="openConfirmEmailAddress = false" />
    </Transition>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import IconClose from "@/components/icons/IconClose.vue";
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import { email as emailValid, required } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import ConfirmYourEmailComponent from "@/components/common/ConfirmYourEmailComponent.vue";

export default {
  name: 'email',
  components: {
    QuillEditor,
    IconClose,
    InputFieldComponent,
    ConfirmYourEmailComponent
  },
  data() {
    return {
      v$: useValidate(),
      email: "",
      password: '',
      editing: false,
      showPassword: false,
      openConfirmEmailAddress: false
    };
  },
  computed: {
    storedUserData() {
      return this.$root.store.getters['auth/getUserDataObject'];
    },
  },
  methods: {
    activate() {
      this.editing = !this.editing;

      if (this.editing) {
        this.$parent.activateEmail();
      }
    },
    updateEmail() {
      console.log('v$.email.error', this.v$.email.$error);
      this.v$.$validate()
      if (!this.v$.$error) {
        this.openConfirmEmailAddress = true
        // this.editing = false

      }
    }
  },
  validations() {
    return {
      email: { emailValid, required },
      password: { required }
    }
  }
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
