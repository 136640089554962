<template>
    <div>
        <!-- Password Strength Indicator -->
        <div v-if="currentValueStrength > 0" class="grid grid-cols-6 gap-1 max-w-xs mt-3 mb-7">
            <div v-if="currentValueStrength > 33" class="w-5 md:w-10 h-1 md:h-1.5 bg-cyan-100"></div>
            <div v-if="currentValueStrength > 33" class="w-5 md:w-10 h-1 md:h-1.5 bg-cyan-200"></div>
            <div v-if="currentValueStrength > 66" class="w-5 md:w-10 h-1 md:h-1.5 bg-cyan-300"></div>
            <div v-if="currentValueStrength > 66" class="w-5 md:w-10 h-1 md:h-1.5 bg-cyan-400"></div>
            <div v-if="parseInt(currentValueStrength) === 100" class="w-10 h-1 md:h-1.5 bg-cyan-500"></div>
            <div v-if="parseInt(currentValueStrength) === 100" class="w-10 h-1 md:h-1.5 bg-main"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isValueLengthValid: false,
            isValueHaveNumbers: false,
            isValueHaveLetters: false,
            currentValueStrength: 0,
        };
    },
    watch: {
        isValueLengthValid(newValue, oldValue) {
            this.adjustStrength(newValue, oldValue, 33.34);
        },
        isValueHaveNumbers(newValue, oldValue) {
            this.adjustStrength(newValue, oldValue, 33.34);
        },
        isValueHaveLetters(newValue, oldValue) {
            this.adjustStrength(newValue, oldValue, 33.34);
        },
    },
    methods: {
        updateValue(newValue) {
            if (!newValue) {
                this.reset(); // Reset all validations if the value is null or undefined
                return;
            }
            this.isValueLengthValid = newValue.length >= 8;
            this.isValueHaveNumbers = /\d/.test(newValue);
            this.isValueHaveLetters = /[a-zA-Z]/.test(newValue);
        },
        reset() {
            this.isValueLengthValid = false;
            this.isValueHaveNumbers = false;
            this.isValueHaveLetters = false;
            this.currentValueStrength = 0;
        },
        adjustStrength(newValue, oldValue, increment) {
            if (newValue !== oldValue) {
                this.currentValueStrength += newValue ? increment : -increment;
            }
        },
    },
};
</script>
