<template>
  <div>
    <div class=" mx-8 flex-row py-8 sm:flex  modal-dialog-scrollable">
      <div class="my-4 h-5 flex items-center ">
        <div class="w-5 my-auto">
          <input type="radio" value="user"
            class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" id="userRadio"
            v-model="userType">
        </div>
        <label for="userRadio" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
          {{ $t('applicant') }}
        </label>
      </div>
      <div class="my-4 h-5 flex items-center sm:ml-5">
        <div class="w-5 my-auto">
          <input type="radio" class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer"
            value="company" id="companyRadio" v-model="userType">
        </div>
        <label for="companyRadio" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
          {{ $t('company') }}
        </label>
      </div>
    </div>
    <div class="pb-5 mx-8 flex-row">
      <form action="">
        <div :class="{ 'hidden': userType != 'user' }">
          <div class="mb-9">
            <InputFieldComponent :value="signUp.name" label="სახელი" type="text" v-model="signUp.name" class=""
              :error="v$.signUp.name.$error" @onBlur="v$.signUp.name.$touch()"
              :errorMessage="v$.signUp.name.$errors.length > 0 ? v$.signUp.name.$errors[0].$message : ''" />
          </div>
          <div class="mb-9">
            <InputFieldComponent :value="signUp.lastname" label="გვარი" type="text" v-model="signUp.lastname" class=""
              :error="v$.signUp.lastname.$error" @onBlur="v$.signUp.lastname.$touch()"
              :errorMessage="v$.signUp.lastname.$errors.length > 0 ? v$.signUp.lastname.$errors[0].$message : ''" />
          </div>

        </div>
        <div :class="{ 'hidden': userType == 'user' }">
          <div class="mb-9">
            <InputFieldComponent :value="signUp.companyName" :label="$t('company_name')" type="text"
              v-model="signUp.companyName" class="" :error="v$.signUp.companyName.$error"
              @onBlur="v$.signUp.companyName.$touch()"
              :errorMessage="v$.signUp.companyName.$errors.length > 0 ? v$.signUp.companyName.$errors[0].$message : ''" />
          </div>
          <div class="mb-9">
            <InputFieldComponent :value="signUp.companyID" :label="$t('identification_number')" type="text"
              v-model="signUp.companyID" class="" :error="v$.signUp.companyID.$error"
              @onBlur="v$.signUp.companyID.$touch()"
              :errorMessage="v$.signUp.companyID.$errors.length > 0 ? v$.signUp.companyID.$errors[0].$message : ''" />
          </div>

        </div>
        <div class="mb-9">
          <InputFieldComponent :value="signUp.email" :label="$t('email')" type="text" v-model="signUp.email" class=""
            :error="v$.signUp.email.$error" @onBlur="v$.signUp.email.$touch()"
            :errorMessage="v$.signUp.email.$errors.length > 0 ? v$.signUp.email.$errors[0].$message : ''" />
        </div>

        <SelectInputComponent :inputType="'array'" :value="signUp.cityId" v-model="signUp.cityId" :label="$t('city')"
          :options="cities" @onBlur="v$.signUp.cityId.$touch()" :error="v$.signUp.cityId.$error" :showBorder="true"
          :errorMessage="v$.signUp.cityId.$errors.length > 0 ? v$.signUp.cityId.$errors[0].$message : ''" />

        <p class="text-xs md:text-sm text-right text-input mt-6">Minimum 8 Characters, at least 1 number, at least 1
          letter</p>

        <div class="mb-9">
          <InputFieldComponent :value="signUp.password" :label="$t('password')" type="password"
            v-model="signUp.password" class="mb-2" :error="v$.signUp.password.$errors.length > 0"
            @onBlur="v$.signUp.password.$touch()"
            :errorMessage="v$.signUp.password.$errors.length > 0 ? v$.signUp.password.$errors[0].$message : ''" />

          <PasswordStrengthComponent ref="passwordStrength" />

        </div>

        <div class="mb-9">
          <InputFieldComponent :value="signUp.password_confirmation" :label="$t('confirm_password')" type="password"
            v-model="signUp.password_confirmation" :error="v$.signUp.password_confirmation.$errors.length > 0"
            @onBlur="v$.signUp.password_confirmation.$touch()"
            :errorMessage="v$.signUp.password_confirmation.$errors.length > 0 ? v$.signUp.password_confirmation.$errors[0].$message : ''" />
        </div>

        <div class="flex items-center mb-4 text-center">
          <button :disabled="v$.$invalid"
            class="block py-3 w-full font-bannerExtrasquareCaps bg-main text-white rounded hover:bg-button-icon disabled:bg-opacity-60 disabled:cursor-not-allowed"
            @click.prevent="register">რეგისტრაცია</button>
        </div>
        <div class="flex items-center mb-4 text-center font-bannerExtrasquareCaps text-xss md:text-xs">
          <div class="text-input">
            {{ $t('agreement_part1') }}
            <a :href="'/signed/terms-' + $cookies.get('lang') + '.pdf'" target="_blank"
              class="ml-1 text-slate-grey hover:underline font-bannerExtrasquareCaps">
              {{ $t('agreement_part2') }}
            </a>
            {{ $t('agreement_part3') }}
            <a href="#" class="ml-1 text-slate-grey hover:underline font-bannerExtrasquareCaps"> {{
              $t('agreement_part4')
            }}</a>.

          </div>

        </div>
      </form>
    </div>
  </div>
</template>
<script>
import useValidate from '@vuelidate/core';
import { required, email, requiredIf, minLength, sameAs } from '@vuelidate/validators'
import InputFieldComponent from '../common/InputFieldComponent.vue';
import SelectInputComponent from '../common/SelectInputComponent.vue';
import PasswordStrengthComponent from '../common/PasswordStrengthComponent.vue';

import { mapGetters, mapMutations, mapState, useStore } from 'vuex';
import axios from 'axios'

export default {
  name: 'RegisterComponent',
  components: {
    InputFieldComponent,
    SelectInputComponent,
    PasswordStrengthComponent
  },
  data() {
    return {
      v$: useValidate(),
      userType: 'user',
      showPassword: false,
      showConfirmPassword: false,
      store: useStore(),
      cities: [
        { id: 1, title: 'Tbilisi' },
        { id: 2, title: 'Batumi' },
        { id: 3, title: 'Gori' },
      ],

      signUp: {
        name: '',
        lastname: '',
        cityId: 1,
        email: '',
        password: '',
        password_confirmation: '',
        companyName: '',
        companyID: '',
      },

      vuelidateExternalResults: {
        signUp: {
          email: null
        }
      }

    };
  },
  props: {
    msg: String,
  },
  mounted() {
    this.v$.$reset();

  },
  watch: {
    'signUp.password'(newValue) {
      this.$refs.passwordStrength.updateValue(newValue)
    },

  },

  methods: {

    register() {
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error) {
        // this.$store.commit('login', this.signUp.email);
        // this.signUp.email = '';
        // this.signUp.password = '';
        // this.v$.$reset();



        if (this.userType == 'user') {
          this.store.dispatch('auth/registerCandidate', this.signUp)
            .then(res => {

              if (res.data.success) {
                // this.signUp.
                this.v$.$reset();
                this.$parent.showForm = false;

                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result.message.text,
                  success: true
                }, 4000) // 10s
              }
            },)

            .catch((err) => {
              const errors = {
                signUp: err.response.data.errors
              }
              // merge the errors into the validation results
              Object.assign(this.vuelidateExternalResults, errors)
            });
        } else {
          this.store.dispatch('auth/registerCompany', this.signUp)
            .then(res => {
              if (res.data.success) {
                this.v$.$reset();
                this.$parent.showForm = false;

                this.$notify({
                  group: "foo",
                  title: "Success Message",
                  text: res.data.result.message.text,
                  success: true
                }, 7000) // 10s
              }
            })
            .catch((err) => {
              const errors = {
                signUp: err.response.data.errors
              }
              // merge the errors into the validation results
              Object.assign(this.vuelidateExternalResults, errors)
            });

        }
      }
    },

    isUser() {
      return this.userType == 'user';
    },
    isCompany() {
      return this.userType == 'company';
    }
  },
  validations() {
    return {
      signUp: {
        name: {
          required: requiredIf(this.isUser),
        },
        lastname: {
          required: requiredIf(this.isUser)
        },
        companyName: {
          required: requiredIf(this.isCompany)
        },
        companyID: {
          required: requiredIf(this.isCompany),
          $autoDirty: true
        },
        cityId: { required },
        email: { required, email, $autoDirty: true },
        password: { required },
        password_confirmation: { required, sameAs: sameAs(this.signUp.password) },
      }
    }
  }
};
</script>
