<template>
  <div class="w-full">

    <div class="bg-white flex px-9 py-5 mb-3">
      <div>
        <h1 class="font-bannerExtrasquareCaps text-2xl">Current Plan</h1>
      </div>
      <span class="block ml-1">
        <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
      </span>
    </div>

    <div class="bg-white my-5 px-20 py-8 mx-auto flex">
        <div class="mx-auto w-60 ">
          <div class="flex-row border  relative hover:border-main" v-for="pack in packages" :key="packages" :class="{'border-2 border-main':pack.highlited}">
            <div class="bg-pricing-bg h-11 flex">
              <h3 class=" font-bannerExtrasquareCaps m-auto text-nav text-xl">{{ pack.title }}</h3>
            </div>
            <div class="h-11 py-5 flex">
              <h1 class="text-main mx-auto flex text-3xl font-bannerExtrasquareCaps  "> 20 <span class="-mt-1 text-sm">₾</span></h1>
            </div>
            <div class="pb-2 pt-5 flex">
              <h1 class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps"> {{pack.vacancy}} {{ $t('home_job') }} </h1>
            </div>
            <div class="pb-2 flex">
              <h1 class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps"> {{pack.candidates}} {{ $t('applicant') }} </h1>
            </div>
            <div class="pb-2 flex">
              <h1 class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps"> {{pack.vacancy}} {{ $t('day') }} </h1>
            </div>
            <div class="pb-2 flex ">
              <div class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps relative">
                <span class="absolute -left-4 top-1"> <img class="w-3" src="@/assets/Images/icons/tick.png" alt=""></span>
                {{ $t('email') }}
              </div>
            </div>
            <div class="flex pb-32">
              <div class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps relative">
                <span class="absolute -left-4 top-1"> <img class="w-3" src="@/assets/Images/icons/tick.png" alt=""></span>
                {{ $t('sms') }}
              </div>
            </div>

            <div class="pb-2 flex absolute bottom-3 w-full border-t border-main-faded pt-5">
              <div class="mx-auto rounded-md py-2 px-10 uppercase  border font-bannerExtrasquareCaps text-base flex items-center bg-main text-white hover:cursor-pointer hover:border-main hover:bg-white hover:text-main "> სხვა პაკეტი </div>
            </div>
          </div>
        </div>
    </div>

    <!-- content end -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      packages: [
        {
          id: 1, title: 'ერთჯერადი', price: 20, vacancy: 1, candidates: 5, days: 25, highlited: false,
        },
      ],

    };
  },

};
</script>

<style lang="scss" scoped>

</style>
