<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'Not Found',
  mounted() {
    window.location.href = '/';
  },
};

</script>

<style lang="scss" scoped>

</style>
