<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('my_skills')"/>
        
        <div class="mt-7 mb-12">
            <!--Preview Start -->
            <div v-if="editing === false">
                <p class="text-xs text-granite-gray">ადაპტირება</p>
                <p class="text-xs text-granite-gray mt-3">ამბიცია</p>
            </div>
            <!--Preview End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index" class="mb-6">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>

                    <div>
                        <SelectInputComponent :value="field.skill" :label="$t('edit_resume_skills_placeholder')" :showBorder="true" v-model:updateValue="field.skill"
                            :options="['option 1', 'option 2', 'option 3', 'option 4']" class="md:max-w-[50%]"/>
                    </div>
                </div>
                <div class="my-7 lg:my-14">
                    <AddButton @addMore="addMore()"/>
                </div>
            </div>
            <!--Edit Mode End-->
        </div>
        <button v-if="isResumePreview === false" @click="editing = !editing"
            class="edit-update-btn">
            {{ editing === true ? $t('update') : $t('edit') }}</button>
    </div>
</template>
<script>
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';
export default {
    name: 'MySkills',
    components: { SelectInputComponent, IconPlus, IconMinus, HeadingComponent, AddButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,

            fieldsArray: [
                {
                    skill: '',
                }
            ]
        }
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    skill: '',
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    }
}
</script>