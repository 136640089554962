<template>
    <div class="w-full pb-9">
        <SubHeader :checkedAll="checkedAll" @updateSearch="updateSearch" @updateDate="getDate" :show-delete-vacancy="true">
            <div class="flex gap-2 items-center">
                <h1 class="heading-h1">
                    {{ $t("messages") }} </h1>
                <span class="text-main font-medium -mt-3 cursor-pointer text-xs flex-1">[?]</span>
            </div>
        </SubHeader>

        <div>
            <div class="bg-white mt-3 border border-gray-300 p-4 sm:p-6" v-for="(message, index) in messages" :key="index">
                <div class="flex items-center">
                    <div class=" flex items-center  w-8/12">
                        <div class="pb-[25px]">
                            <label class="relative inline-block cursor-pointer" @click="toggleAddToFavorites(message.id)">
                                <input type="checkbox" class="input-checkbox" v-model="message.addToFavorites">
                                <span class="input-checkmark"></span>
                            </label>
                        </div>
                        <div class="flex-1 flex items-center justify-between ml-5 sm:ml-7">
                            <div class=" mr-2">
                                <div>
                                    <img src="@/assets/Images/temp/temp-user-1.png"
                                        class="rounded  w-11 h-11 lg:w-[66px] lg:h-[66px] object-contain" />
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="sm:hidden flex items-center gap-2 pl-2">
                                    <svg v-if="message.requestIs === 'consent'" fill="#36A8E0" width="15px" height="15px"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
                                    </svg>
                                    <p class="text-xs text-main font-medium font-bannerExtrasquare"
                                        :class="{ 'text-bright-red': message.requestIs === 'video', 'text-advp': message.requestIs === 'read' }">
                                        {{ message.request }}</p>
                                </div>
                                <div class="flex items-center">
                                    <div class="pl-2 sm:pl-3 pr-2">
                                        <p
                                            class="text-[8px] sm:text-xs text-light-grey  font-medium font-bannerExtrasquare">
                                            {{ message.heading }}
                                        </p>
                                        <p
                                            class="text-[8px] sm:text-[10px] text-light-grey font-normal font-bannerExtrasquare">
                                            {{
                                                message.subHeading }}</p>
                                    </div>
                                    <div class="m-auto">
                                        <div class="hidden sm:flex items-center gap-2 mb-1">
                                            <svg v-if="message.requestIs === 'consent'" fill="#36A8E0" width="15px"
                                                height="15px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
                                            </svg>
                                            <p class="text-xs text-main font-medium font-bannerExtrasquare"
                                                :class="{ 'text-bright-red': message.requestIs === 'video', 'text-advp': message.requestIs === 'read' }">
                                                {{ message.request }}</p>
                                        </div>
                                        <div class="flex items-center gap-1">
                                            <div class="hidden sm:block"><svg fill="#ADADAD" version="1.1" id="Capa_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" class="w-[10px] h-[15px] "
                                                    viewBox="0 0 395.71 395.71" xml:space="preserve">
                                                    <g>
                                                        <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
                            C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
                            c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" />
                                                    </g>
                                                </svg></div>
                                            <p class="hidden sm:block text-light-grey font-medium text-xs">{{
                                                message.address }}
                                            </p>
                                            <div class="hidden sm:block"><svg class="h-3 w-3" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V12C11 12.2652 11.1054 12.5196 11.2929 12.7071L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13 11.5858V6Z"
                                                        fill="#ADADAD" />
                                                </svg></div>
                                            <p class="text-light-grey font-medium text-[8px] sm:text-xs whitespace-nowrap">
                                                {{ message.date }}</p>
                                        </div>
                                        <div>
                                            <p class="text-light-grey font-medium text-[8px] sm:text-xs mt-1 sm:mt-2">{{
                                                message.time }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ml-auto lg:m-auto ">
                        <p class="text-dim-grey font-black text-xs mb-4 text-right">ID: {{ message.id }}</p>
                        <button
                            class="font-bannerExtrasquare bg-very-light-gray py-2 px-1 sm:py-[10px] sm:px-3 text-dim-grey rounded text-[7px] sm:text-xs">
                            {{ $t('see_details') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center gap-2 mt-6">
            <div class="w-3 h-3 bg-slider-btn-inactive rounded-full " v-for="(page, index) in pages"></div>
        </div>
    </div>
</template>
<script>
import SubHeader from '@/components/common/SubHeader.vue';

export default {
    components: { SubHeader },
    name: 'Messages',
    data() {
        return {
            checkedAll: false,
            messages: [],
            date: [],
            search: '',
            pages: [1, 2, 3, 4]

        }
    },
    methods: {
        getMessages() {
            this.messages = [
                {
                    id: '123567',
                    heading: 'ლილო პროდაქტ',
                    subHeading: 'სერვისი',
                    address: 'Tbilisi, Georgia',
                    date: '2019-11-12 ',
                    time: '15:39:38',
                    request: 'თანხმობა',
                    requestIs: 'consent',
                    addToFavorites: false

                },
                {   
                    id: '123568',
                    heading: 'ლილო პროდაქტ',
                    subHeading: 'სერვისი',
                    address: 'Tbilisi, Georgia',
                    date: '2019-11-12 ',
                    time: '15:39:38',
                    addToFavorites: false,
                    request: 'თანხმობა',
                    requestIs: 'consent',
                },
                {
                    id: '123569',
                    heading: 'ლილო პროდაქტ',
                    subHeading: 'სერვისი',
                    address: 'Tbilisi, Georgia',
                    date: '2019-11-12 ',
                    time: '15:39:38',
                    addToFavorites: false,
                    request: 'წაკითხვა',
                    requestIs: 'read',
                },
                {
                    id: '123570',
                    heading: 'ლილო პროდაქტ',
                    subHeading: 'სერვისი',
                    address: 'Tbilisi, Georgia',
                    date: '2019-11-12 ',
                    time: '15:39:38',
                    addToFavorites: false,
                    request: 'წაკითხვა',
                    requestIs: 'read',
                },
                {
                    id: '123571',
                    heading: 'ლილო პროდაქტ',
                    subHeading: 'სერვისი',
                    address: 'Tbilisi, Georgia',
                    date: '2019-11-12 ',
                    time: '15:39:38',
                    request: 'ვიდეოს მოთხოვნა',
                    requestIs: 'video',
                    addToFavorites: false
                },

            ]
        },
        toggleAddToFavorites(objId) {

            this.messages.map(obj => {
                if (obj.id === objId) {
                    return { ...obj, addToFavorites: !obj.addToFavorites };
                }
                return obj;
            });
        },
        getDate(date) {
            this.date = date;
        },
        updateSearch(search) {
            this.search = search;
        }
    },
    mounted() {
        this.getMessages();
    },

}
</script>
<style scoped></style>