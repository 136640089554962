<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="9.788" height="10.118" viewBox="3565.828 -3127.785 9.788 10.118">
        <g data-name="icon">
            <path
            d="M3567.187-3124.115v-2.311h7.07v2.311h1.359v-2.99a.68.68 0 0 0-.68-.68h-8.427a.68.68 0 0 0-.68.68v2.99h1.358Z"
            fill="#fff" fill-rule="evenodd" data-name="Path 1059" />
        <path
            d="m3570.847-3123.886 1.945 2.351s.296.28-.025.28h-1.096V-3117.834s.043.166-.208.166h-1.543c-.18 0-.177-.14-.177-.14v-3.378h-1.012c-.39 0-.096-.293-.096-.293s1.655-2.197 1.886-2.427c.166-.167.326.02.326.02Z"
            fill="#fff" fill-rule="evenodd" data-name="Path 1060" />
    </g>
</svg></template>