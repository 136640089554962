<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="50" viewBox="-14389.5 1411.5 52 50">
        <g data-name="Group 1317">
            <g data-name="facebook-social-symbol">
            <g data-name="Group 1313">
                <g data-name="Group 1312">
                    <path
                        d="m-14361.643 1432.23.009-2.853c0-1.487.103-2.283 2.233-2.283h3.94v-5.702h-5.646c-5.48 0-6.74 2.83-6.74 7.48l.005 3.358-4.158.003v5.7h4.158v16.542h6.201l.004-16.542 5.63-.002.607-5.7h-6.243Z"
                        fill="#305296" fill-rule="evenodd" data-name="Path 1052" />
                </g>
            </g>
        </g>
        <path d="M4 0h45a4 4 0 0 1 4 4v43a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" stroke-linejoin="round"
            stroke-linecap="round" stroke="#4f4f4f" fill="transparent"
            transform="matrix(.98113 0 0 .9804 -14389.5 1411.5)" data-name="rectangle-1" />
    </g>
</svg></template>