<template>
  <div v-click-away="handleClickAway" class="">
    <div class="lg:hidden py-6 z-40 sticky top-0 grid grid-cols-3 gap-4  border-b bg-white">
      <div></div>
      <div class="m-auto">
        <router-link to="/" @click="burgerMenuActive = false" @click.native="burgerMenuActive = false">
          <img class="w-40" src="@/assets/Images/shared/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="flex items-center px-4 justify-end">
        <a href="#" class="mr-2" :class="{ hidden: !$store.state.email }">
          <img src="@/assets/Images/shared/profilePlaceholder.png" alt="profile picture" class="w-7" />
        </a>
        <a href="#" @click.prevent="showMenu" class="w-6">
          <img v-if="burgerMenuActive" src="@/assets/Images/shared/burgerActive.png" alt="Burger menu" />
          <img v-else src="@/assets/Images/shared/burgerInactive.png" alt="Burger menu" />
        </a>
      </div>
    </div>
    <div class="lg:hidden bg-white pt-24 pb-6  drop-shadow-lg  top-0 absolute w-full max-h-screen overflow-y-auto"
      :class="{ hidden: !burgerMenuActive }">
      <ul class="flex-col justify-between items-center">
        <!-- <li v-if="$store.state.email && $store.state.isCompany"> -->
        <li v-if="$store.state.auth.loggedIn && $store.state.auth.isCompany">
          <a class="nav-item flex items-center" href="#" @click.prevent="activateAccount">
            <span>{{ $t('account') }}</span>
            <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>
          <ul class="navUlContainer px-4" :class="{ block: forAccountActive, hidden: !forAccountActive }">
            <li class="navUlLiContainer">
              <router-link to="/dashboard/company" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('home') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/edit-company" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('nav_edit_company') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/edit-company-user" class="p-4 text-sm"
                @click.native="burgerMenuActive = false">{{
                  $t('nav_edit_user') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/candidates" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('nav_candidates') }}</router-link>
            </li>
            <!-- <li class="aboutBelinqedUlLi">
                <router-link to="/dashboard/create-job" class="p-4 text-sm" @click.native="burgerMenuActive=false">{{ $t('nav_create_a_job') }}</router-link
                >
              </li> -->
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/create-service" class="p-4 text-sm"
                @click.native="burgerMenuActive = false">{{ $t('nav_create_a_job') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/company-messages" class="p-4 text-sm"
                @click.native="burgerMenuActive = false">{{ $t('nav_messages') }} <span
                  class="my-auto ml-2 bg-red-600 text-white px-1 py-px text-center rounded text-xs">4</span></router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/myPackage" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{ $t('nav_my_package') }}</router-link>
            </li>
            <li class="navUlLiContainerNoBorder">
              <router-link to="/dashboard/packages" class="p-4 text-sm"
                @click.native="burgerMenuActive = false">{{ $t('pricing') }}</router-link>
            </li>
            <!-- <li class="aboutBelinqedUlLi">
                <router-link to="/dashboard/logout" class="py-6 text-sm" @click.native="burgerMenuActive=false">{{ $t('nav_sign_out_btn') }}</router-link
                >
              </li> -->
          </ul>
          <div class="ml-4 w-4 h-px bg-border"></div>
        </li>
        <li v-if="$store.state.auth.loggedIn && !$store.state.auth.isCompany">
          <a class="nav-item flex items-center" href="#" @click.prevent="activateAccount">
            <span>{{ $t('account') }}</span>
            <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>
          <ul class="navUlContainer px-4" :class="{ block: forAccountActive, hidden: !forAccountActive }">
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/candidate" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('home') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/edit-candidate" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('nav_edit_profile') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/candidate-jobs" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('nav_matched_jobs') }}</router-link>
            </li>
            <li class="aboutBelinqedUlLi">
              <router-link to="/dashboard/my-resume" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{
                $t('nav_my_resume') }}</router-link>
            </li>
            <li class="navUlLiContainerNoBorder">
              <router-link to="/dashboard/messages" class="p-4 text-sm" @click.native="burgerMenuActive = false"> {{
                $t('nav_my_messages') }}<span class="my-auto ml-2 bg-red-600 text-white px-1
                  py-px text-center rounded text-xs">4</span>
              </router-link>
            </li>
            <!-- <li class="navUlLiContainerNoBorder"><a href="#" class="py-6 text-sm" @click.native="burgerMenuActive = false">{{ $t('params') }}</a></li> -->
          </ul>
          <div class="ml-4 w-4 h-px bg-border"></div>
        </li>
        <li class="">
          <a class="nav-item flex items-center" href="#" @click.prevent="activateAbout">
            <span>{{ $t('about_company') }}</span>
            <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>
          <ul class="navUlContainer px-4" :class="{ block: aboutBelinqedActive, hidden: !aboutBelinqedActive }">
            <li class="navUlLiContainer">
              <router-link to="/about" class="py-6 text-sm" @click.native="burgerMenuActive = false">{{ $t('about_us') }}</router-link>
            </li>
            <li class="navUlLiContainer">
              <router-link to="/" href="#" class="py-6 text-sm" @click.native="burgerMenuActive = false">{{ $t('our_team') }}</router-link>
            </li>
            <!-- <li class="navUlLiContainer">
              <a href="#" class="py-6 text-sm" @click.native="burgerMenuActive = false">{{ $t('our_pricing') }}</a>
            </li> -->
            <li class="navUlLiContainer">
              <a href="#" class="py-6 text-sm" @click.native="burgerMenuActive = false">{{ $t('newses') }}</a>
            </li>
            <!-- <li class="navUlLiContainer">
              <a href="#" class="py-6 text-xs">{{ $t('confidentiality_terms') }}</a>
            </li> -->
            <li class="navUlLiContainer">
              <a class="py-6 text-sm" :href="'/signed/terms-' + $cookies.get('lang') + '.pdf'" target="_blank">{{
                $t('customer_terms') }}</a>
            </li>
            <li class="navUlLiContainerNoBorder">
              <a href="#" class="py-6 text-xs" @click.native="burgerMenuActive = false">{{ $t('faq') }}</a>
            </li>
          </ul>
          <div class="ml-4 w-4 h-px bg-border"></div>
        </li>
        <li class="" v-if="!$root.store.getters['auth/isLoggedIn'] || $root.$store.getters['auth/isCompany']">
          <a class="nav-item flex items-center" href="#" @click.prevent="activateForCompany">
            <span>{{ $t('for_company') }}</span>
            <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>
          <ul class="navUlContainer px-4" :class="{ block: forCompanyActive, hidden: !forCompanyActive }">
            <!-- <li class="navUlLiContainer">
                <a href="#" class="py-6 text-sm">{{ $t('nav_create_a_job') }}</a>
              </li> -->
            <!-- <li class="navUlLiContainer">
                <a href="#" class="py-6 text-xs">რეგისტრირებული კომპანიები</a>
              </li> -->
            <!-- <li class="navUlLiContainer">
                <a href="#" class="py-6 text-xs">{{ $t('tariff') }}</a>
              </li> -->
            <!-- <li class="navUlLiContainerNoBorder">
                <a href="#" class="py-6 text-xs">საიტის წესები</a>
              </li> -->
            <li class="navUlLiContainer">
              <a href="#" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{$t('vacancy_placement')}}</a>
            </li>
            <li class="navUlLiContainer">
              <a href="#" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{$t('registered_companies')}}</a>
            </li>
            <li class="navUlLiContainer">
              <a href="#" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{$t('rates')}}</a>
            </li>
            <li class="navUlLiContainer">
              <a href="#" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{$t('site_terms')}}</a>
            </li>
            <li class="aboutBelinqedUlLi">
          <router-link to="/dashboard/packages" class="p-4 text-sm">{{$t('our_pricing')}}</router-link>
        </li>
            <li class="navUlLiContainerNoBorder">
              <router-link to="/job" class="p-4 text-sm" @click.native="burgerMenuActive = false">Job</router-link>
            </li>
          </ul>
          <div class="ml-4 w-4 h-px bg-border"></div>
        </li>
        <li v-if="!$root.store.getters['auth/isLoggedIn'] || !$root.$store.getters['auth/isCompany']">
          <a class="nav-item flex items-center" href="#" @click.prevent="activateForCandidate">
            <span>{{ $t('for_candidate') }}</span>
            <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>
          <ul class="navUlContainer px-4" :class="{ block: forCandidateActive, hidden: !forCandidateActive }">
            <li class="navUlLiContainer">
              <a href="#" class="py-6 text-xs" @click.native="burgerMenuActive = false">{{ $t('create_resume') }}</a>
            </li>
            <!-- <li class="navUlLiContainer">
                <a href="#" class="py-6 text-xs">ტარიფი</a>
              </li>
              <li class="navUlLiContainerNoBorder">
                <a href="#" class="py-6 text-xs">საიტის წესები</a>
              </li> -->
            <li class="aboutBelinqedUlLi">
              <a href="#" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{$t('rates')}}</a>
            </li>
            <li class="aboutBelinqedUlLi">
              <a href="#" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{$t('site_terms')}}</a>
            </li>
            <li class="navUlLiContainerNoBorder">
              <router-link to="/resume" class="p-4 text-sm" @click.native="burgerMenuActive = false">{{ $t('home_resume') }}</router-link>
            </li>
          </ul>
          <div class="ml-4 w-4 h-px bg-border"></div>
        </li>
        <li>
          <a href="/" class="nav-item font-bannerExtrasquare flex items-center" @click.prevent="activateLocale">
            <span class="text-sm " v-if="$root.$i18n.locale == 'ka'">GEO</span>
            <span class="text-sm " v-else>EN</span>
            <svg class="w-4 h-4 text-nav ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>
          <ul class="navUlContainer px-4" :class="{ block: localeActive, hidden: !localeActive }">
            <li class="navUlLiContainer">
              <a href="#" class="py-6 text-xs" @click="changeLang('en')">EN</a>
            </li>
            <li class="navUlLiContainer">
              <a href="#" class="py-6 text-xs" @click="changeLang('ka')">GEO</a>
            </li>
            <!-- <li class="navUlLiContainer">
                <a href="#" class="py-6 text-xs">RU</a>
              </li> -->
          </ul>
        </li>
      </ul>
      <button class="auth-btn w-fit ml-4 bg-main mt-2 py-3" @click.prevent="!isLoggedIn ? signIn() : logout()">
        <span v-if="!isLoggedIn">
          <img src="@/assets/Images/shared/log-in-white.png" class="w-4 mr-2" alt="" />
        </span>
        <span v-if="!isLoggedIn" class="items-center pt-1 text-white"> {{ $t('sign_in') }} </span>
        <span v-else class="items-center pt-1 text-white"> {{ $t('sign_out') }} </span>
      </button>

    </div>

  </div>
</template>
  
<script>
export default {
  name: 'NavMobile',
  data() {
    return {
      forAccountActive: false,
      aboutBelinqedActive: false,
      forCompanyActive: false,
      forCandidateActive: false,
      localeActive: false,
      burgerMenuActive: false,
    };
  },
  methods: {
    changeLang(lang) {
      if (this.$root.$i18n.locale !== lang) {
        this.$root.$i18n.locale = lang
        $cookies.set('lang', lang)
      }
      this.burgerMenuActive = false
      // localStorage.lang = lang;
    },
    handleClickAway() {
      if (this.burgerMenuActive) {
        this.burgerMenuActive = false;
      }
    },
    showMenu() {
      setTimeout(() => {
        this.burgerMenuActive = !this.burgerMenuActive;
      }, 100);
    },
    activateAccount() {
      this.forAccountActive = !this.forAccountActive;
      this.forCompanyActive = false;
      this.forCandidateActive = false;
      this.localeActive = false;
      this.aboutBelinqedActive = false;
    },
    activateAbout() {
      this.forAccountActive = false;
      this.forCompanyActive = false;
      this.forCandidateActive = false;
      this.localeActive = false;
      this.aboutBelinqedActive = !this.aboutBelinqedActive;
    },
    activateForCompany() {
      this.forAccountActive = false;
      this.aboutBelinqedActive = false;
      this.forCandidateActive = false;
      this.localeActive = false;
      this.forCompanyActive = !this.forCompanyActive;
    },
    activateForCandidate() {
      this.forAccountActive = false;
      this.forCompanyActive = false;
      this.aboutBelinqedActive = false;
      this.localeActive = false;
      this.forCandidateActive = !this.forCandidateActive;
    },
    activateLocale() {
      this.forAccountActive = false;
      this.forCompanyActive = false;
      this.forCandidateActive = false;
      this.aboutBelinqedActive = false;
      this.localeActive = !this.localeActive;
    },
    logout() {
      this.$root.store.dispatch('auth/logout');
      this.burgerMenuActive = false
    },
    signIn(){
      this.$emit('signInEvent')
      this.burgerMenuActive = false
    }

  },
  computed: {
    isLoggedIn() {
      return this.$root.store.getters['auth/isLoggedIn'] ? true : false
    },
  }
};
</script>
  