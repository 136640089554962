<template>
    <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-70 z-50 flex justify-center items-center">
        <div class="bg-transparent p-0 w-[90%] sm:w-[60%] md:w-[50%] rounded-lg">
            <!-- Close Button -->
            <div class="flex justify-end">
                <button @click="closePopup" class="text-white text-2xl text-blue">
                    &times;
                </button>
            </div>

            <!-- Video Player -->
            <div class="flex justify-center mt-4">
                <video :src="videoUrl" controls class="w-full h-auto rounded-lg" autoplay />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoPlayerPopup',
    props: {
        // Controls whether the popup is visible or not
        isVisible: {
            type: Boolean,
            required: true,
        },
        // URL of the video to play
        videoUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        // Close the popup by emitting an event
        closePopup() {
            this.$emit('update:isVisible', false);
        },
    },
};
</script>

<style scoped>
/* You can add custom styles here */
</style>