<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36">{{ $t('nav_about_me')
        }}</a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2 hidden md:block">
        <span v-if="storedUserData">
          {{ storedUserData.name }} {{ storedUserData.surname }}
        </span>
      </a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
        <span class="">Edit</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">{{ $t('applicant_dashboard_about_me') }}</h1>
      </div>
      <div class="md:grid md:grid-cols-3 md:gap-2 mt-5 flex flex-col-reverse">
        <div class="bg-white overflow-hidden md:col-span-2 md:order-1">
          <div class="block md:hidden">
            <quill-editor theme="snow" toolbar="" class="" :content="content" :content-type="'html'"
              @update:content="onEditorChange($event)" ref="myQuill"></quill-editor>
          </div>
          <div class="hidden md:block">
            <quill-editor theme="snow" toolbar="full" :content="content" :content-type="'html'"
              @update:content="onEditorChange($event)" ref="myQuill"></quill-editor>
          </div>
        </div>
        <div class="pb-5 md:pb-auto md:order-2">
          <img src="@/assets/Images/temp/temp_logo_1.png" class="px-4 mx-auto" alt="" />
          <button @click="openProfilePhotoEditor = true"
            class="text-main text-center mx-auto block mt-2 text-sm font-bannerExtrasquare">{{ $t('upload_photo')
            }}</button>
        </div>
      </div>

      <div class="form grid grid-rows-6 md:grid-rows-none md:grid-cols-2 gap-4 mt-5">
        <InputFieldComponent :value="profile.name" :label="$t('first_name')" type="text" v-model="profile.name" class=""
          :error="v$.profile.name.$error" @onBlur="v$.profile.name.$touch()"
          :errorMessage="v$.profile.name.$errors.length > 0 ? v$.profile.name.$errors[0].$message : ''" />
        <InputFieldComponent :value="profile.surname" :label="$t('last_name')" type="text" v-model="profile.surname"
          class="" :error="v$.profile.surname.$error" @onBlur="v$.profile.surname.$touch()"
          :errorMessage="v$.profile.surname.$errors.length > 0 ? v$.profile.surname.$errors[0].$message : ''" />
        <InputFieldComponent :value="profile.birthday" :label="$t('birth_date')" type="date" v-model="profile.birthday"
          class="" :error="v$.profile.birthday.$error" @onBlur="v$.profile.birthday.$touch()"
          :errorMessage="v$.profile.birthday.$errors.length > 0 ? v$.profile.birthday.$errors[0].$message : ''" />
        <InputFieldComponent :value="profile.personalId" :label="$t('personal_number')" type="number"
          v-model="profile.personalId" class="" :error="v$.profile.personalId.$error"
          @onBlur="v$.profile.personalId.$touch()"
          :errorMessage="v$.profile.personalId.$errors.length > 0 ? v$.profile.personalId.$errors[0].$message : ''" />
        <SelectInputComponent :value="profile.country" v-model="profile.country" :label="$t('country')"
          :options="['Georgia']" @onBlur="v$.profile.country.$touch()" :error="v$.profile.country.$error"
          :showBorder="true"
          :errorMessage="v$.profile.country.$errors.length > 0 ? v$.profile.country.$errors[0].$message : ''" />
        <SelectInputComponent :value="profile.city" v-model="profile.city" :label="$t('city')" :options="['Tbilisi']"
          @onBlur="v$.profile.city.$touch()" :error="v$.profile.city.$error" :showBorder="true"
          :errorMessage="v$.profile.city.$errors.length > 0 ? v$.profile.city.$errors[0].$message : ''" />

        <SelectInputComponent :value="profile.citizenship" v-model="profile.citizenship" :label="$t('citizenship')"
          :options="['Option 1', 'Option 2', 'Option 3']" @onBlur="v$.profile.citizenship.$touch()"
          :error="v$.profile.citizenship.$error" :showBorder="true"
          :errorMessage="v$.profile.citizenship.$errors.length > 0 ? v$.profile.citizenship.$errors[0].$message : ''" />

        <InputFieldComponent :value="profile.mobileNumber"
          :label="$t('applicant_dashboard_edit_profile_form_phone_number')" type="number" v-model="profile.mobileNumber"
          class="" :error="v$.profile.mobileNumber.$error" @onBlur="v$.profile.mobileNumber.$touch()"
          :errorMessage="v$.profile.mobileNumber.$errors.length > 0 ? v$.profile.mobileNumber.$errors[0].$message : ''" />
      </div>
      <InputFieldComponent :value="profile.address" v-model="profile.address" :label="$t('address')" type="text"
        class="mt-5 mb-2" :error="v$.profile.address.$error" @onBlur="v$.profile.address.$touch()"
        :errorMessage="v$.profile.address.$errors.length > 0 ? v$.profile.address.$errors[0].$message : ''">
        <template v-slot:icon>
          <div class="bg-main py-[15px] px-4 rounded-r-md ">
            <IconStreetView />
          </div>
        </template>
      </InputFieldComponent>

      <div class="form grid grid-rows-6 md:grid-rows-none md:grid-cols-2 gap-4 mt-5">
        <InputFieldComponent :value="profile.facebookUrl" :label="$t('company_dashboard_edit_company_facebook_url')"
          type="text" v-model="profile.facebookUrl" class="" :error="false" :stayMainColorLabel="true" />
        <InputFieldComponent :value="profile.twitterUrl" label="Twitter Url" type="text" v-model="profile.twitterUrl"
          class="" :error="false" :stayMainColorLabel="true" />
        <InputFieldComponent :value="profile.linkedInUrl" label="Linkedin Url" type="text" v-model="profile.linkedInUrl"
          class="" :error="false" :stayMainColorLabel="true" />
        <InputFieldComponent :value="profile.googlePlusUrl" label="Google+ Url" type="text"
          v-model="profile.googlePlusUrl" class="" :error="false" :stayMainColorLabel="true" />

      </div>

      <div class="mt-5  flex-row">
        <div class="my-4 h-5 flex items-center ">
          <div class="w-5 my-auto">
            <input type="radio" value="female"
              class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer" id="female"
              v-model="profile.gender">
          </div>
          <label for="female" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
            Female
          </label>
        </div>
        <div class="my-4 h-5 flex items-center ">
          <div class="w-5 my-auto">
            <input type="radio" class="w-5 h-3 checked:w-5 checked:h-5 accent-radio-accent hover:cursor-pointer"
              value="male" id="male" v-model="profile.gender">
          </div>
          <label for="male" class="font-bannerExtrasquare text-auth ml-1 text-sm hover:cursor-pointer">
            Male
          </label>
        </div>
      </div>

      <div class="flex mt-6">
        <button :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="updateProfile()">{{ $t('applicant_dashboard_edit_profile_form_update') }}</button>
      </div>
    </div>
    <Transition>
      <div v-if="openProfilePhotoEditor === true"
        class="fixed top-0 left-0 right-0 bottom-0 bg-[#0b0b0b] bg-opacity-60 z-[60] w-screen">
        <div class="bg-white max-w-2xl m-auto absolute top-2/4 -translate-y-2/4  -translate-x-2/4  left-2/4 w-full p-7">
          <div class="flex justify-end">
            <button @click="openProfilePhotoEditor = false">
              <IconClose class="[&_path]:fill-dark-grey" />
            </button>
          </div>
          <h3 class="text-nav text-lg md:text-xl lg:text-[28px] font-bannerExtrasquareCaps">{{
            $t('applicant_dashboard_edit_profile_form_upload_pic') }}</h3>
          <div class="flex mt-14 justify-center">
            <img src="@/assets/Images/resume/ingaProfil.png" class=" max-w-[315px] max-h-[315px] object-cover" />
          </div>
          <div class="flex justify-center items-center max-w-[200px] mx-auto mt-10">
            <img src="@/assets/Images/icons/img.png" class="w-4" />
            <div class="flex-1 relative h-[1px] bg-black">
              <div class="h-5 w-5 rounded-full border -translate-y-1/2 absolute left-9 border-main bg-white">
              </div>
            </div>
            <img src="@/assets/Images/icons/img.png" class="w-4" />
          </div>
          <div class="flex justify-center mt-10">
            <button class="text-main text-sm py-2 px-7 border-2 border-main rounded"
              @click="openProfilePhotoEditor = false">
              {{ $t('change') }}
            </button>
          </div>

        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { mapGetters, mapMutations, mapState, useStore } from 'vuex';
import IconClose from "@/components/icons/IconClose.vue";
import InputFieldComponent from '@/components/common/InputFieldComponent.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue'
import IconStreetView from "@/components/icons/IconStreetView.vue";
import useValidate from '@vuelidate/core'
import { required, email, requiredIf, minLength, sameAs } from '@vuelidate/validators'
import store from "@/store";
export default {
  name: "AboutInfo",
  emits: ['showProfileUpdateMessage'],
  components: {
    QuillEditor,
    IconClose,
    InputFieldComponent,
    IconStreetView,
    SelectInputComponent
  },
  data() {
    return {
      v$: useValidate(),
      store: useStore(),
      content: "",
      userData: store.state.auth.userData,
      editing: false,
      openProfilePhotoEditor: false,
      selected: {
        gender: 'female'
      },
      profile: {
        name: '',
        surname: '',
        birthday: '',
        personalId: '',
        country: '',
        city: '',
        citizenship: '',
        mobileNumber: '',
        address: '',
        facebookUrl: '',
        twitterUrl: '',
        linkedInUrl: '',
        googlePlusUrl: '',
        gender: null,
        facebookUrl: '',
        twitterUrl: '',
        linkedInUrl: '',
        googlePlusUrl: '',

      }
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuill.quill;
    },
    storedUserData() {
      const store = useStore();
      if (this.$root.store.getters['auth/getUserDataObject'] == 'undefined') {
        return false;
      }
      return this.$root.store.getters['auth/getUserDataObject'];
    },
  },
  created() {
    const store = useStore();
    setTimeout(() => {
      this.content = this.$root.store.getters['auth/userResumeData']('about')


    }, 500);
  },
  methods: {
    onEditorChange(change) {
      // console.log(change);
    },
    activate() {
      this.editing = !this.editing;
      if (this.editing) {
        this.$parent.activateAbout();
        this.profile.name = this.storedUserData.name
        this.profile.surname = this.storedUserData.surname
        this.profile.birthday = this.storedUserData.birthday
        this.profile.personalId = this.storedUserData.personal_id
        this.profile.country = this.storedUserData.city.country.title
        this.profile.city = this.storedUserData.city.title
        // this.profile.citizenship = 'test',
        // this.profile.address = 'test'
        this.profile.mobileNumber = this.storedUserData.mobile_number,
          // this.profile.mobileNumber = Number(this.storedUserData.mobile_number.replace(' ', ""))
          this.profile.gender = this.storedUserData.gender
        this.v$.$reset()

      }
    },
    updateProfile() {
      this.v$.$touch;
      if (!this.v$.$invalid) {
        this.editing = false
        this.$emit('showProfileUpdateMessage')

      }
    }
  },
  validations() {
    return {
      profile: {
        name: { required },
        surname: { required },
        birthday: { required },
        personalId: { required },
        country: { required },
        city: { required },
        citizenship: { required },
        mobileNumber: { required },
        address: { required },
        gender: { required }
      }
    }
  }
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
