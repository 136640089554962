<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('resume_general_driver_licenses')"/>
        
        <div class="mt-7 mb-12">

            <!--Preview Mode Start-->
            <div v-if="editing === false">
                <h5 class="text-main text-xs font-bannerExtrasquare">{{ $t('resume_general_driver_licenses') }}</h5>
                <p class="text-xs text-granite-gray mt-3">B - მსუბუქი მანქანა</p>
            </div>
            <!--Preview Mode End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <SelectInputComponent :value="drivingLicense" :label="$t('resume_general_driver_licenses')" :showBorder="true" v-model:updateValue="drivingLicense"
                    :options="['option 1', 'option 2', 'option 3', 'option 4']" class="md:max-w-[50%]"/>
            </div>
            <!--Edit Mode End-->
        </div>
        <button v-if="isResumePreview === false"  @click="editing = !editing" class="edit-update-btn">
            {{ editing === true ? $t('update') : $t('edit') }}</button>
    </div>
</template>
<script>
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';

export default {
    name: 'DrivingLicense',
    components: { SelectInputComponent,HeadingComponent },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,
            drivingLicense: ''
        }
    }
}
</script>