<template>
    <Transition>
        <div v-if="isOpen">
            <div class="fixed top-0 bottom-0 left-0 right-0 bg-jet-black bg-opacity-60 z-40" @click="closePopup">
            </div>
            <div
                class="bg-white px-7 py-6 fixed left-0 top-32 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 sm:left-1/2 z-50 w-full sm:w-[600px] md:w-[700px] xl:w-[804px] z-50">
                <div class="flex justify-between items-center">
                    <p class="text-nav text-2xl font-normal">
                        <!-- {{ isEdit ? $t('edit_team_member') : $t('add_team_member') }} -->
                        {{ isEdit ? 'Edit Team Member' : 'Add Team Member' }}
                    </p>
                    <button @click="closePopup">
                        <IconClose class="[&_path]:fill-dark-grey sm:w-4 sm:h-4" />
                    </button>
                </div>
                <div class="mt-12 flex flex-col md:flex-row gap-4 md:gap-7">
                    <InputFieldComponent v-model="name" :label="$t('Name')" type="text" :error="false" class="flex-1"
                        :fillBackgroundColor="true" />
                    <InputFieldComponent v-model="lastName" :label="$t('last_name')" type="text" :error="false"
                        class="flex-1" :fillBackgroundColor="true" />
                </div>
                <div class="mt-4">
                    <InputFieldComponent v-model="position" :label="$t('position')" type="text" :error="false"
                        :fillBackgroundColor="true" />
                </div>
                <div class="mt-4">
                    <InputFieldComponent v-model="aboutTeamMember" :label="$t('about_team_member')" type="text"
                        :error="false" :fillBackgroundColor="true" :isTextArea="true" />
                </div>


                <!-- Image Upload Section -->
                <div class="mt-7 flex justify-center gap-7">

                    <div
                        class="bg-light-alice-blue2 pt-[26px] pb-[30px] flex-1 md:flex-none md:w-52 flex flex-col justify-center items-center relative">
                        <input type="file" accept="image/*" class="hidden" id="image-upload"
                            @change="handleImageUpload" />
                        <label for="image-upload" class="cursor-pointer">
                            <IconAvatar class="w-16 h-16" />
                        </label>
                        <div v-if="selectedImage" class="mt-4 relative group">
                            <img :src="selectedImage" alt="Selected Image" class="w-full h-auto rounded" />
                            <button @click="clearImage"
                                class="absolute top-1 right-1 bg-blue-600 px-2 text-white rounded-full p-1 text-sm opacity-0 group-hover:opacity-100">
                                ✕
                            </button>
                        </div>
                    </div>

                    <!-- Video Upload Section -->
                    <div
                        class="bg-light-alice-blue2 pt-[26px] pb-[30px] flex-1 md:flex-none md:w-52 flex flex-col justify-center items-center relative">
                        <input type="file" accept="video/*" class="hidden" id="video-upload"
                            @change="handleVideoUpload" />
                        <label for="video-upload" class="cursor-pointer">
                            <div class="bg-main pl-5 py-3 pr-3 rounded-full">
                                <IconVideoPlay />
                            </div>
                        </label>

                        <div v-if="selectedVideo" class="mt-4 relative group">
                            <video :src="selectedVideo" controls class="w-full h-auto rounded"></video>
                            <button @click="clearVideo"
                                class="absolute top-1 right-1 bg-blue-600 px-2 text-white rounded-full p-1 text-sm opacity-0 group-hover:opacity-100">
                                ✕
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="isEdit" class="flex text-center justify-center mt-5">
                    <p class=" text-xs text-center italic text-gray-400">* If you'd like to update the files, please
                        upload the new ones. If you're happy with the current files, you can leave them as is.
                    </p>
                </div>

                <!-- Submit Button -->
                <div class="mt-14 flex justify-center items-center pb-7">

                    <button @click="submitForm" class="bg-main rounded py-3 px-12 text-white text-base">
                        {{ isEdit ? $t('update') : $t('add') }}
                    </button>


                </div>

            </div>
        </div>
    </Transition>
</template>

<script>
import InputFieldComponent from "@/components/common/InputFieldComponent.vue";
import IconAvatar from "@/components/icons/IconAvatar.vue";
import IconUpload from "@/components/icons/IconUpload.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconVideoPlay from "@/components/icons/IconVideoPlay.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { useStore } from 'vuex';

export default {
    name: "TeamMemberFormPopup Comonent",
    components: {
        IconClose,
        InputFieldComponent,
        IconAvatar,
        IconUpload,
        IconEdit,
        IconVideoPlay,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        teamMember: {
            type: Object,
            default: () => ({}), // Default to an empty object for new members
        },
    },
    data() {
        return {
            store: useStore(),
            name: this.teamMember.name || "1",
            lastName: this.teamMember.lastName || "",
            position: this.teamMember.position || "",
            aboutTeamMember: this.teamMember.description || "",
            selectedImage: null,
            selectedVideo: null,
            imageData: null,
            videoData: null,
        };
    },
    computed: {
        isEdit() {
            return !!this.teamMember.id; // If teamMember has an ID, we're editing
        },
    },
    watch: {
        teamMember: {
            immediate: true, // Run on component mount
            handler(newTeamMember) {
                // Update data fields whenever teamMember changes
                this.name = newTeamMember.name || "";
                this.lastName = newTeamMember.last_name || "";
                this.position = newTeamMember.position || "";
                this.aboutTeamMember = newTeamMember.about || "";
                this.selectedImage = null;
                this.selectedVideo = null;
            },
        },
    },
    methods: {

        resetForm() {
            this.name = "";
            this.lastName = "";
            this.position = "";
            this.aboutTeamMember = "";
            this.clearImage();
            this.clearVideo();
            this.teamMember = {}; // Reset team member data to an empty object
        },

        handleImageUpload(event) {
            const file = event.target.files[0]; // Get the selected file
            if (file) {
                // Release the previous object URL if it exists
                if (this.selectedImage) {
                    URL.revokeObjectURL(this.selectedImage);
                }
                // Create a new object URL for the selected image
                this.selectedImage = URL.createObjectURL(file);
                this.imageData = file; // Store the file data for submission
            } else {
                this.clearImage(); // Clear the image if no file is selected
            }
            event.target.value = ""; // Reset the input value
        },
        clearImage() {
            if (this.selectedImage) {
                URL.revokeObjectURL(this.selectedImage);
            }
            this.selectedImage = null;
            this.imageData = null;
        },
        handleVideoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                if (this.selectedVideo) {
                    URL.revokeObjectURL(this.selectedVideo);
                }
                this.selectedVideo = URL.createObjectURL(file);
                this.videoData = file;
            } else {
                this.clearVideo();
            }
            event.target.value = "";
        },
        clearVideo() {
            if (this.selectedVideo) {
                URL.revokeObjectURL(this.selectedVideo);
            }
            this.selectedVideo = null;
            this.videoData = null;
        },
        closePopup() {
            this.$emit("close");
            this.$parent.openAddTeamMemberForm = false;
            this.$parent.editingTeamMemberData = {};

        },
        submitForm() {
            const submissionData = {
                isEdit: this.isEdit,
                id: this.teamMember.id || null,
                name: this.name,
                lastName: this.lastName,
                position: this.position,
                aboutTeamMember: this.aboutTeamMember,
                // image: this.imageData || this.teamMember.image || null,
                image: this.imageData || null,
                // video: this.videoData || this.teamMember.video || null,
                video: this.videoData || null,
            };
            this.$emit("submit-form", submissionData);
        },
    },
};
</script>
