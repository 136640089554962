<template>
    <div>
        <transition name="fade">
            <div v-if="isOpen" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div class="bg-white p-6 rounded-lg shadow-lg w-96">
                    <h2 class="text-lg font-semibold mb-4">Upload Images</h2>

                    <input type="file" @change="onFileChange" accept="image/*" multiple
                        class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:bg-gray-100 file:text-main hover:file:bg-gray-200" />

                    <div v-if="imagePreviews.length" class="mt-4 grid grid-cols-2 gap-2">
                        <div v-for="(preview, index) in imagePreviews" :key="index" class="relative">
                            <img :src="preview" alt="Image Preview" class="w-full h-auto object-cover rounded-md" />
                            <button @click="removeImage(index)" class="absolute top-1 right-1 text-red-600">✕</button>
                        </div>
                    </div>

                    <div class="flex justify-end mt-4">
                        <button @click="confirmUpload" class="bg-main text-white px-4 py-2 rounded mr-2"
                            :disabled="!imageFiles.length">
                            Confirm
                        </button>
                        <button @click="closePopup"
                            class="text-gray-500 px-4 py-2 rounded border border-gray-300 hover:bg-gray-100">
                            Cancel
                        </button>
                    </div>

                    <div v-if="uploadedImagesCount > 0" class="mt-2 text-gray-500">
                        Uploaded Images: {{ uploadedImagesCount }}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {

        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            imageFiles: [],
            imagePreviews: [],
            uploadedImagesCount: 0,
        };
    },
    methods: {
        onFileChange(event) {
            const files = Array.from(event.target.files);

            // Reset the current list of images
            this.reset();

            for (const file of files) {
                this.imageFiles.push(file);
                this.createHighQualityPreview(file).then(preview => {
                    this.imagePreviews.push(preview);
                });
            }
        },
        createHighQualityPreview(file) {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
                    resolve(dataUrl);
                };
            });
        },
        confirmUpload() {
            // Emit an event with the image files to be uploaded
            this.$emit('upload-images', this.imageFiles);
            this.closePopup();
        },
        removeImage(index) {
            this.imageFiles.splice(index, 1);
            this.imagePreviews.splice(index, 1);
        },
        closePopup() {
            this.$emit('close');
            this.reset();
        },
        reset() {
            this.imageFiles = [];
            this.imagePreviews = [];
            this.uploadedImagesCount = 0;
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>