<template>
    <div class="bg-default p-5 s-nav">
        <div class="container items-center m-auto">
            <AboutMe :is-resume-preview="true" />
            <WorkExperience :is-resume-preview="true" />
            <Education :is-resume-preview="true" />
            <SoftwareKnowledge :is-resume-preview="true"/>
            <KnowledgeOfLanguages :is-resume-preview="true"/>
            <DrivingLicense :is-resume-preview="true"/>
            <MyCompetencies :is-resume-preview="true"/>
            <MySkills :is-resume-preview="true"/>
            <MyHobbiesAndInterest :is-resume-preview="true"/>
            <MaritalStatus :is-resume-preview="true"/>
            <MyWork :is-resume-preview="true"/>
        </div>
    </div>
    <DesiredPosition :is-resume-preview="true"/>
</template>
<script>
import AboutMe from '@/components/candidate/sub-pages/my-resume/my-resume-components/AboutMe.vue';
import WorkExperience from '@/components/candidate/sub-pages/my-resume/my-resume-components/WorkExperience.vue';
import Education from '@/components/candidate/sub-pages/my-resume/my-resume-components/Education.vue';
import SoftwareKnowledge from '@/components/candidate/sub-pages/my-resume/my-resume-components/SoftwareKnowledge.vue';
import KnowledgeOfLanguages from '@/components/candidate/sub-pages/my-resume/my-resume-components/KnowledgeOfLanguages.vue';
import DrivingLicense from '@/components/candidate/sub-pages/my-resume/my-resume-components/DrivingLicense.vue';
import MyCompetencies from '@/components/candidate/sub-pages/my-resume/my-resume-components/MyCompetencies.vue';
import MySkills from '@/components/candidate/sub-pages/my-resume/my-resume-components/MySkills.vue';
import MyHobbiesAndInterest from '@/components/candidate/sub-pages/my-resume/my-resume-components/MyHobbiesAndInterest.vue';
import MaritalStatus from '@/components/candidate/sub-pages/my-resume/my-resume-components/MaritalStatus.vue';
import MyWork from '@/components/candidate/sub-pages/my-resume/my-resume-components/MyWork.vue'
import DesiredPosition from '@/components/candidate/sub-pages/my-resume/my-resume-components/DesiredPosition.vue';
export default {
    components: {
        AboutMe,
        WorkExperience,
        Education,
        SoftwareKnowledge,
        KnowledgeOfLanguages,
        DrivingLicense,
        MyCompetencies,
        MySkills,
        MyHobbiesAndInterest,
        MaritalStatus,
        MyWork,
        DesiredPosition
    }
}

</script>