import router from '@/router';
import axios from 'axios';

export default {
  
  async getLocale({ commit }) {
    return await axios.get('locale').then((res) => {
      commit('setNewLocale',res.data.result)
    });
  },
  async setLocale({ commit }, payLoad) {
    return await axios.post('locale',payLoad).then((res) => {
      commit('setNewLocale',res.data.result)
    });
  },


  async fetchJobCreationParams({ commit }) {
    // commit('setLoading', true);
    try {
      // Replace with your API endpoint
      return await axios.get('company/job-creation-params').then((res) => {
        commit('setJobCreationParams',res.data.result)
      });
      // commit('setJobCreationParams', response.data);
      // commit('setJobCreationParams', "Hey setting");
    } catch (error) {
      commit('setError', error.message || 'Failed to fetch job parameters.');
    } finally {
      // commit('setLoading', false);
    }
  },



  async setCompanyDetails({ commit }, payLoad) {
    return await axios.put('company/update-details',payLoad).then((res) => res);
  },
  async setCompanyTagsAndLinks({ commit }, payLoad) {
    return await axios.put('company/update-tags-and-links',payLoad).then((res) => res);
  },
  async setCompanyImages({ commit }, payLoad) {
    return await axios.post('company/add-photos',payLoad).then((res) => res);
  },
  async addCompanyTeamMember({ commit }, payLoad) {
    return await axios.post('company/add-team-member',payLoad).then((res) => res);
  },
  async updateCompanyUserEmail({ commit }, payLoad) {
    return await axios.post('company/update-company-user-email',payLoad).then((res) => res);
  },
  async removeCompanyTeamMember({ commit }, payLoad) {
    return await axios.delete('company/remove-team-member/'+payLoad.id).then((res) => res);
  },
  async editCompanyTeamMember({ commit }, payLoad) {
    return await axios.post('company/edit-team-member',payLoad).then((res) => res);
  },
  async removeCompanyImage({ commit }, payLoad) {
    return await axios.delete('company/remove-photos/'+payLoad.imageId,).then((res) => res);
  },
  async setCompanyUserDetails({ commit }, payLoad) {
    return await axios.post('company/update-user-details',payLoad).then((res) => res);
  },
  async companyUserEmailOtpVerification({ commit }, payLoad) {
    return await axios.post('company/company-email-otp-verification',payLoad).then((res) => res);
  },
  async companyUserPasswordChange({ commit }, payLoad) {
    return await axios.post('company/company-user-change-password',payLoad).then((res) => res);
  },
  async CompanyUserDetailsOtpVerification({ commit }, payLoad) {
    return await axios.put('company/user-otp-verification',payLoad).then((res) => res);
  },

  async createJobForCompany({ commit }, payLoad) {
    return await axios.post('company/job/create',payLoad).then((res) => res);
  },

};
