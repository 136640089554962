<template>
  <div class="mb-5">
    <div class="flex justify-between border border-edit-title2 bg-edit-bg px-5 py-5 rounded" v-if="!editing">
      <a href="#" class="text-signed text-xs edit-title font-bannerExtrasquare my-auto w-36">
        {{ $t("company_about") }}
      </a>
      <a href="#" class="text-sm my-auto font-bannerExtrasquare text-edit-title2 hidden md:block">
        <!-- {{ editCompany.company_name }} -->
      </a>
      <a href="#" class="text-main text-xs my-auto font-bannerExtrasquare flex" @click.prevent="activate">
        <img src="@/assets/Images/icons/edit-icon.png" class="w-3 h-3 mr-1" />
        <span>{{ $t("edit") }}</span>
      </a>
    </div>

    <div class="flex-row border border-edit-title2 bg-edit-active px-5 py-5 rounded" v-else>
      <div>
        <h1 class="text-signed text-xs font-bannerExtrasquare mt-2 w-36">
          {{ $t("company_about") }}
        </h1>
      </div>

      <!-- Quill Editor Section -->
      <div class="md:grid md:grid-cols-3 md:gap-2 mt-5 flex flex-col-reverse">
        <div class="bg-white overflow-hidden md:col-span-2 md:order-1 block md:hidden">
          <div>
            <quill-editor ref="editor1" v-model="editCompany.about_company" theme="snow" toolbar="full"
              class="quill-editor" />
          </div>
        </div>
        <div class="bg-white overflow-hidden md:col-span-2 md:order-1 hidden md:block">
          <div>
            <quill-editor ref="editor2" v-model="editCompany.about_company" theme="snow" toolbar="full"
              class="quill-editor" />
          </div>
        </div>
        <div class="pb-5 md:pb-auto md:order-2">
          <img src="@/assets/Images/temp/temp_logo_1.png" class="px-4 mx-auto" alt="" />
          <a href="#" class="text-main text-center mx-auto block mt-2 text-sm font-bannerExtrasquare">
            {{ $t("company_edit_change_photo") }}
          </a>
        </div>
      </div>

      <!-- Form Section -->
      <div class="form grid grid-rows-6 md:grid-rows-none md:grid-cols-2 gap-4 mt-5">
        <InputFieldComponent :value="editCompany.company_name" :label="$t('company_name')" type="text"
          v-model="editCompany.company_name" :error="v$.editCompany.company_name.$error"
          @onBlur="v$.editCompany.company_name.$touch()"
          :errorMessage="v$.editCompany.company_name.$errors[0]?.$message" />
        <InputFieldComponent :value="editCompany.company_id_number" :label="$t('company_id_number')" type="text"
          v-model="editCompany.company_id_number" :error="v$.editCompany.company_id_number.$error"
          @onBlur="v$.editCompany.company_id_number.$touch()"
          :errorMessage="v$.editCompany.company_id_number.$errors[0]?.$message" />
        <SelectInputComponent :inputType="'array'" :value="editCompany.city_id" v-model="editCompany.city_id"
          :label="$t('city')" :options="cities" @onBlur="v$.editCompany.city_id.$touch()"
          :error="v$.editCompany.city_id.$error" :errorMessage="v$.editCompany.city_id.$errors[0]?.$message" />
        <InputFieldComponent :value="editCompany.address_1" :label="$t('address_1')" type="text"
          v-model="editCompany.address_1" :error="v$.editCompany.address_1.$error"
          @onBlur="v$.editCompany.address_1.$touch()" :errorMessage="v$.editCompany.address_1.$errors[0]?.$message" />
        <InputFieldComponent :value="editCompany.address_2" :label="$t('address_2')" type="text"
          v-model="editCompany.address_2" :error="v$.editCompany.address_2.$error"
          @onBlur="v$.editCompany.address_2.$touch()" :errorMessage="v$.editCompany.address_2.$errors[0]?.$message" />
      </div>

      <!-- Submit Button -->
      <div class="flex mt-6">
        <button :disabled="v$.$invalid"
          class="bg-main text-white font-bannerExtrasquareCaps mx-auto px-4 py-3 rounded disabled:bg-opacity-60 disabled:cursor-not-allowed"
          @click.prevent="dispatchChanges">
          {{ $t("update") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import InputFieldComponent from "@/components/common/InputFieldComponent.vue";
import SelectInputComponent from "@/components/common/SelectInputComponent.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";

export default {
  name: "AboutInfo",
  components: {
    QuillEditor,
    SelectInputComponent,
    InputFieldComponent,
  },
  data() {
    return {
      store: useStore(),
      editing: false,
      cities: [
        { id: 1, title: "Tbilisi" },
        { id: 2, title: "Batumi" },
        { id: 3, title: "Gori" },
      ],
      editCompany: {
        about_company: "",
        company_name: "",
        company_id_number: "",
        country: "",
        city_id: "",
        address_1: "",
        address_2: "",
      },
    };
  },
  validations() {
    return {
      editCompany: {
        company_name: { required },
        company_id_number: { required },
        city_id: { required },
        address_1: { required },
        address_2: { required },
      },
    };
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  mounted() {
    this.initializeCompanyData();
  },
  methods: {
    initializeCompanyData() {
      const companyData = this.store.state.auth.companyData;
      if (companyData) {
        this.editCompany = {
          about_company: companyData.about_company ?? "",
          company_name: companyData.company_name ?? "",
          company_id_number: companyData.company_id_number ?? "",
          country: companyData.city?.country?.title ?? "",
          city_id: companyData.city?.id ?? "",
          address_1: companyData.address_1 ?? "",
          address_2: companyData.address_2 ?? "",
        };
      }
    },
    activate() {
      this.editing = !this.editing;

      if (this.editing) {
        this.$parent.activateAbout();
        setTimeout(() => {

          let aboutCompany = this.store.state.auth.companyData.about_company;

          this.$refs.editor2.setHTML(aboutCompany);

        }, 300);
        // this.editCompany.company_name = 
        // console.log("comon state")
        // console.log(this.$root)
      }
    },
    dispatchChanges() {

      this.editCompany.about_company = this.$refs.editor2.getHTML();

      this.v$.$validate();
      if (!this.v$.$error) {
        this.store
          .dispatch("commons/setCompanyDetails", this.editCompany)
          .then((res) => {
            if (res.data.success) {
              this.store.dispatch("auth/getPackages");
              this.editing = false;
              this.$notify({
                group: "foo",
                title: "Success Message",
                text: res.data.result,
                success: true,
              });
            }
          })
          .catch((err) => {
            console.error("Error updating company details:", err);
          });
      }
    },
  },
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
