<template>
    <div>
        <VueDatePicker v-model="date" range multi-calendars :enable-time-picker="false" :min-date="minDate"
            :space-confirm="true" placeholder="Select Start and End date" />
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    props: ['minDate'],
    components: { VueDatePicker },
    name: 'DatePickerComponent',
    emits: ['updateDate'],
    data() {
        return {
            date: []
        }
    },
    watch: {
        date() {
            this.$emit('updateDate', this.date)
        }
    },
}
</script>
<style>
.dp__input {
    padding: 8px 12px 8px 30px;
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
    background-color: #36A8E0;
    color: #F1F7FC;
}

.dp__today {
    border-color: #36A8E0;
}

.dp__range_between {
    background: #F1F7FC;
    font-weight: 500;
}

.dp__action_select {
    background: #36A8E0;
}

.dp__arrow_top {
    display: none;
}

.dp__action_button {
    padding: 2px 8px;
    font-size: 12px;
}

.dp__input {
    background-color: #F1F7FC;
    border-color: #2279A5;
    font-size: 12px;
    color: #36A8E0;
}

.dp__clear_icon,
.dp__input_icon {
    color: #2279A5;
}

.dp__action_select:hover {
    background-color: #36A8E0;
}

.dp__action_cancel:hover {
    border-color: #36A8E0;
}

.dp__month_year_select {
    font-weight: 700;
}

.dp__inner_nav svg {
    fill: black;
    stroke: black;
}

@media (max-width: 640px) {
    .dp__cell_inner {
        height: 25px;
        width: 25px;
        font-size: 12px;
    }

    .dp__calendar_header_item {
        height: 25px;
        width: 25px;
        font-size: 12px;
    }

    .dp__month_year_select {
        height: 25px;
        font-size: 14px;
    }

    .dp__month_year_row {
        height: 25px;
    }
}


.dp__theme_dark {
    --dp-background-color: #212121;
    --dp-text-color: #fff;
    --dp-hover-color: #484848;
    --dp-hover-text-color: #fff;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #005cb2;
    --dp-primary-disabled-color: #61a8ea;
    --dp-primary-text-color: #fff;
    --dp-secondary-color: #a9a9a9;
    --dp-border-color: #2d2d2d;
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #aaaeb7;
    --dp-border-color-focus: #aaaeb7;
    --dp-disabled-color: #737373;
    --dp-disabled-color-text: #d0d0d0;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #00701a;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: #959595;
    --dp-danger-color: #e53935;
    --dp-marker-color: #e53935;
    --dp-tooltip-color: #3e3e3e;
    --dp-highlight-color: rgb(0 92 178 / 20%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
    --dp-range-between-border-color: var(--dp-hover-color, #fff);


}

.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1976d2;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-border-color-focus: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}
</style>