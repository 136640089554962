<template>
  <div class="w-full">
    <!-- <div class="bg-white px-9 py-5 mb-3">
    <div class="grid grid-cols-4 gap-2">
      <div class=" flex col-span-2">
        <div>
          <h1 class="font-bannerExtrasquareCaps text-2xl">ფასები</h1>
        </div>
        <span class="block ml-1">
          <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
        </span>
      </div>
    </div>
  </div> -->
    <div class="bg-white flex px-5 md:px-9 pt-5 mb-3">
      <!-- <h1 class="heading-h1">
        {{ $t('our_pricing') }}
      </h1>
      <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span> -->
      <HeadingComponent :heading="$t('our_pricing')"/>
    </div>

    <div class="bg-white my-5 px-8 py-8 mt-5 ">
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3 ">
        <div class="flex-row border  relative hover:border-main" v-for="pack in packages" :key="packages"
          :class="{ 'border-2 border-main': pack.highlited }">
          <div class="bg-pricing-bg h-11 flex">
            <h3 class=" font-bannerExtrasquareCaps m-auto text-nav text-xl">{{ pack.title }}</h3>
          </div>
          <div class="h-11 py-5 flex">
            <h1 class="text-main mx-auto flex text-3xl font-bannerExtrasquareCaps  "> 20 <span
                class="-mt-1 text-sm">₾</span></h1>
          </div>
          <div class="pb-2 pt-5 flex">
            <h1 class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps"> {{ pack.vacancy }} {{ $t('home_job') }}
            </h1>
          </div>
          <div class="pb-2 flex">
            <h1 class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps"> {{ pack.candidates }} {{ $t('applicant') }}
            </h1>
          </div>
          <div class="pb-2 flex">
            <h1 class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps"> {{ pack.vacancy }} {{ $t('day') }} </h1>
          </div>
          <div class="pb-2 flex ">
            <div class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps relative">
              <span class="absolute -left-4 top-1"> <img class="w-3" src="@/assets/Images/icons/tick.png" alt=""></span>
              {{ $t('email') }}
            </div>
          </div>
          <div class="flex pb-32">
            <div class="text-nav ml-11 flex text-sm font-bannerExtrasquareCaps relative">
              <span class="absolute -left-4 top-1"> <img class="w-3" src="@/assets/Images/icons/tick.png" alt=""></span>
              {{ $t('sms') }}
            </div>
          </div>

          <div class="pb-2 flex absolute bottom-3 w-full">
            <div
              class="mx-auto rounded-md py-2 px-10 uppercase  border font-bannerExtrasquareCaps text-base flex items-center bg-main text-white hover:cursor-pointer hover:border-main hover:bg-white hover:text-main ">
              {{ $t('get_started') }} </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeadingComponent from '@/components/common/HeadingComponent.vue';

export default {
  name: "AllPackages",
  components: { HeadingComponent },
  data() {
    return {
      editingAbout: false,
      editing: false,
      packages: [{
        id: 1,
        title: "ერთჯერადი",
        price: 20,
        vacancy: 1,
        candidates: 5,
        days: 25,
        highlited: false
      },
      {
        id: 2,
        title: "ძირითადი",
        price: 80,
        vacancy: 5,
        candidates: 7,
        days: 30,
        highlited: true
      },
      {
        id: 3,
        title: "სტანდარტი",
        price: 140,
        vacancy: 10,
        candidates: 10,
        days: 45,
        highlited: false
      },
      {
        id: 4,
        title: "პრემიუმი",
        price: 360,
        vacancy: 30,
        candidates: 15,
        days: 60,
        highlited: false
      },
      {
        id: 5,
        title: "მორგებული",
        price: 0,
        vacancy: 0,
        candidates: 0,
        days: 25,
        highlited: false
      },
      {
        id: 6,
        title: "საცდელი",
        price: 0,
        vacancy: 5,
        candidates: 7,
        days: 25,
        highlited: false
      },
      ]
    };
  },
  methods: {

  },
};
</script>
