<template>
    <div class="bg-default p-5 s-nav">
        <div class="container items-center m-auto">
            <div class="bg-white py-4 px-6 gap-2 lg:px-12 flex items-center mb-3">
                <h1 class="heading-h1">
                    ხელით მოძიება </h1>
                <span class="text-main font-medium -mt-3 cursor-pointer text-xs">[?]</span>
            </div>
            <div class="bg-white p-6 lg:p-12">
                <!--Main Block Start-->
                <div class="flex flex-col sm:flex-row">
                    <div class="mt-12 sm:mt-0 flex-1 lg:flex-none lg:w-8/12">
                        <p class="text-sonic-silver text-base lg:text-xl font-bannerExtrasquare ">L.P.S. International
                        </p>
                        <div class="mt-6 lg:mt-12">
                            <p class="job-content">
                                experienced by companies
                                in the western capitalist world and deregulated markets.</p>
                            <p class="job-content ">
                                The
                                question of business
                                ethics has long been considered of minor importance with the
                                justification that in the business world it is not always possible to make decisions
                                with
                                the
                                clarity of right and wrong, foresee and decide who will benefit or harmed.</p>
                            <p class="job-content ">
                                The
                                company
                                operates in an environment that is constantly changing due to social, market,
                                financial, technological changes. It is constantly urged to make choices about actions
                                and
                                decisions that trigger consequences for individuals and the environment.</p>

                            <p class="job-content">
                                However,
                                the constant struggle to remain in a highly competitive market is what has guided
                                business decisions. Capitalist countries and enterprises navigate with ease in moral
                                ambiguity since personal interest is seen as the engine of the economy.</p>

                            <button class="flex items-center">
                                <p class="text-main text-[8px]"> ნახე მეტი </p>
                                <div><svg width="15px" height="12px" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 7L15 12L10 17" stroke="#36A8E0" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></div>
                            </button>
                        </div>
                    </div>
                    <div class="order-first sm:order-last sm:pl-12 flex-1">
                        <div class="">
                            <div>
                                <img src="@/assets/Images/shared/profilesample.png"
                                    class="rounded w-[102px] h-[102px] object-contain mx-auto" />
                            </div>
                            <p class="text-sonic-silver font-bannerExtrasquare text-sm text-center mt-4">L.P.S.
                                International</p>
                            <div class="mt-3">

                                <div class="flex justify-start items-center gap-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.668" height="9.375"
                                        viewBox="-23654 -3425.375 10.668 9.375">
                                        <g data-name="portfolio">
                                            <path d="M-23649.604-3420.375h1.875v.625h-1.875v-.625Zm0 0" fill="#939393"
                                                fill-rule="evenodd" data-name="Path 80" />
                                            <path
                                                d="M-23645.346-3419.75h-1.758v.312c0 .173-.14.313-.312.313h-2.5a.312.312 0 0 1-.313-.313v-.312h-1.758a.936.936 0 0 1-.89-.641l-1.123-3.371v6.824c0 .517.42.938.938.938h8.791c.517 0 .938-.42.938-.938v-6.824l-1.124 3.371a.936.936 0 0 1-.889.64Zm0 0"
                                                fill="#939393" fill-rule="evenodd" data-name="Path 81" />
                                            <path
                                                d="M-23647.416-3425.375h-2.5a.939.939 0 0 0-.938.937v.313h-2.608l1.178 3.536a.312.312 0 0 0 .297.214h1.758v-.313c0-.172.14-.312.313-.312h2.5c.172 0 .312.14.312.312v.313h1.758a.312.312 0 0 0 .297-.214l1.178-3.536h-2.608v-.313a.939.939 0 0 0-.937-.937Zm-2.813 1.25v-.313c0-.172.14-.312.313-.312h2.5c.172 0 .312.14.312.312v.313h-3.125Zm0 0"
                                                fill="#939393" fill-rule="evenodd" data-name="Path 82" />
                                        </g>
                                    </svg>
                                    <p class="text-sonic-silver font-bannerExtrasquare text-[10px] text-center">L.P.S.
                                        International</p>
                                </div>
                                <div class="flex justify-start items-center gap-3 mt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.348" height="14.286"
                                        viewBox="70.573 0 10.348 14.286">
                                        <g data-name="Group 237">
                                            <path
                                                d="M75.747 0a5.18 5.18 0 0 0-5.174 5.174c0 3.54 4.63 8.738 4.827 8.958a.466.466 0 0 0 .694 0c.197-.22 4.827-5.418 4.827-8.958A5.18 5.18 0 0 0 75.747 0Zm0 7.777a2.606 2.606 0 0 1-2.603-2.603 2.606 2.606 0 0 1 2.603-2.603 2.606 2.606 0 0 1 2.603 2.603 2.606 2.606 0 0 1-2.603 2.603Z"
                                                fill="#939393" fill-rule="evenodd" data-name="Path 83" />
                                        </g>
                                    </svg>
                                    <p class="text-sonic-silver font-bannerExtrasquare text-[10px] text-center">თბილისი,
                                        საქართველო</p>
                                </div>
                            </div>
                            <div class="border border-main p-3 mt-3">
                                <table class="table-auto w-full">
                                    <tr v-for="(data, index) in details" :key="index" class="">
                                        <td class="text-main text-[10px] lg:text-xs pb-3 lg:pb-4">{{ data.title }}:</td>
                                        <td class="text-signed text-[10px] lg:text-xs pb-3 lg:pb-4">{{ data.value }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="mt-6">
                            <ul class="flex items-center gap-1">
                                <li class="">
                                    <a href="#">
                                        <img src="@/assets/Images/icons/fb-icon.png" alt="" class="w-5 h-5" />
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#">
                                        <img src="@/assets/Images/icons/in-icon.png" alt="" class="w-5 h-5" />
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#">
                                        <img src="@/assets/Images/icons/tw-icon.png" alt="" class="w-5 h-5" />
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#">
                                        <img src="@/assets/Images/icons/ig-icon.png" alt="" class="w-5 h-5" />
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#">
                                        <img src="@/assets/Images/icons/ml-icon.png" alt="" class="w-5 h-5" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex mt-3">
                            <p class="text-main text-[8px] font-roboto">ბოლო ცვლილება: 22-11-2019</p>
                        </div>
                    </div>
                </div>
                <!--Main Block End-->

                <!--Slider Block Start-->
                <div class="mt-12">
                    <div class="bg-white  flex items-center mb-3">
                        <HeadingComponent :heading="$t('pictures_of_office_and_team')" />
                    </div>
                    <div>
                        <div class="flex w-full mb-5">
                            <Carousel :items-to-show="1" :autoplay="2500" :breakpoints="breakpoints" :wrap-around="true"
                                v-model="currentSlide" class="customer-feedback-carousel w-full ">
                                <Slide v-for="(slide, index) in officeImages" :key="index" class="">
                                    <div class="mx-2">
                                        <img class="m-auto" :src="slide.image" alt="" />
                                    </div>
                                </Slide>
                                <template #addons>
                                    <navigation class="text-main h-8 hidden md:block" />
                                </template>
                            </Carousel>
                        </div>
                        <div class="flex justify-center items-center gap-1 mt-3 md:hidden">
                            <div v-for="(slide, index) in officeImages" :key="index"
                                :class="{ 'active': currentSlide === index }" @click="currentSlide = index"
                                class="w-3 h-3 bg-slider-btn-inactive rounded-full cursor-pointer [&.active]:bg-slider-btn-active">
                            </div>
                        </div>
                    </div>

                    <div class="flex w-full">
                        <Carousel :items-to-show="1" :autoplay="2500" :breakpoints="breakpoints" :wrap-around="true"
                            v-model="currentMemberSlide" class="customer-feedback-carousel w-full">

                            <Slide v-for="slide in teamMembers" :key="slide" class="">

                                <div class="w-fit mx-2 h-full">
                                    <div class="h-24"></div>
                                    <div class=" bg-team-slider relative p-6 pt-0 h-full ">
                                        <div class="absolute left-1/2 -translate-x-1/2">
                                            <div class="relative">
                                                <img class="mx-auto w-36 md:w-40 object-contain -translate-y-1/2"
                                                    :src="slide.image" alt="" />
                                                <div class="absolute top-1/2 -translate-y-9 left-1/2 -translate-x-1/2">
                                                    <button class="bg-main pl-5 py-3 pr-3 rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.661"
                                                            height="27.548" viewBox="18.8 11.191 20.661 27.548">
                                                            <g data-name="Group 233">
                                                                <path d="M38.6 24.965 19.661 37.88V12.052l18.94 12.913Z"
                                                                    fill="#eef7ff" fill-rule="evenodd"
                                                                    data-name="Path 74" />
                                                                <path
                                                                    d="M19.661 38.74a.86.86 0 0 1-.86-.86V12.05a.86.86 0 0 1 1.346-.71l18.939 12.912a.862.862 0 0 1-.001 1.422L20.145 38.59a.862.862 0 0 1-.484.15Zm.861-25.058V36.25l16.55-11.285-16.55-11.283Z"
                                                                    fill="#eef7ff" fill-rule="evenodd"
                                                                    data-name="Path 75" />
                                                            </g>
                                                        </svg>
                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="pt-28  h-full">
                                            <p class="text-sm mb-3 text-nav font-bannerExtrasquare">{{ slide.fullName }}
                                            </p>
                                            <p class="text-xs mb-2 text-team-pos font-bannerExtrasquare">{{
                                                slide.position
                                                }}</p>
                                            <p class="text-[10px] text-nav font-bannerExtrasquare">{{ slide.about }}</p>

                                        </div>
                                    </div>
                                </div>
                                <!-- endItem -->
                            </Slide>
                            <template #addons>
                                <Navigation class="text-main mt-12 hidden md:block" />
                            </template>
                        </Carousel>
                    </div>
                    <div class="flex justify-center items-center gap-1 mt-3 md:hidden">
                        <div v-for="(slide, index) in teamMembers" :key="index"
                            :class="{ 'active': currentMemberSlide === index }" @click="currentMemberSlide = index"
                            class="w-3 h-3 bg-slider-btn-inactive rounded-full cursor-pointer [&.active]:bg-slider-btn-active">
                        </div>
                    </div>
                </div>
                <!--Slider Block End-->
            </div>
            <div class="bg-white p-6 lg:p-12 mt-3">
                <div>
                    <h1 class="heading-h1 mb-6">
                        გრაფიკული დიზაინერი</h1>
                    <!--Service Desc. Block Start-->
                    <h3 class="text-sm font-bannerExtrasquareCaps text-nav mb-2">{{ $t('job_description') }}</h3>
                    <p class="job-content">The debate about the ethical behaviour of organizations has taken place in
                        administrative
                        practice as a way to survive paradigm of shifts and the hyper competition environment
                        experienced by companies in the western capitalist world and deregulated markets.</p>
                    <p class="job-content">The question of business ethics has long been considered of minor importance
                        with
                        the
                        justification that in the business world it is not always possible to make decisions with the
                        clarity of right and wrong, foresee and decide who will benefit or harmed.</p>
                    <p class="job-content">The company operates in an environment that is constantly changing due to
                        social,
                        market,
                        financial, technological changes. It is constantly urged to make choices about actions and
                    </p>
                    <button class="flex items-center">
                        <p class="text-main text-[8px]"> {{ $t('see_more') }} </p>
                        <div><svg width="15px" height="12px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 7L15 12L10 17" stroke="#36A8E0" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg></div>
                    </button>
                    <!--Service Desc. Block Start-->
                </div>
                <div class="h-[1px] bg-gray-300 my-7"></div>

                <!--Requirements Block Start-->
                <div>
                    <div class="bg-white  mb-3">
                        <HeadingComponent :heading="$t('requirements')" />
                        <div class="grid grid-cols-2 gap-8 w-full mt-6">
                            <div class="col-span-2 md:col-span-1 text-xs text-nav"
                                v-for="(requirement, index) in requirements" :key="index">
                                <p class="text-xs text-nav">{{ requirement.title }}</p>
                                <p class="mt-2 text-xs text-grey-58">{{ requirement.value }}</p>
                                <div class="h-[1px] bg-main mt-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Requirements Block End-->
            </div>

            <!--Education Block Start-->
            <div class="bg-white p-6 lg:p-12 mt-3">
                <HeadingComponent :heading="$t('minimum_education_level_of_candidate')" />
                <div class="mt-7 overflow-x-auto">
                    <table class="w-full table-auto table-border">
                        <thead>
                            <tr class="table-border">
                                <td class="table-border jobsTableHeader">{{ $t('education_direction') }}</td>
                                <td class="table-border jobsTableHeader">{{ $t('company_job_detail_education_level') }}
                                </td>
                                <td class="table-border jobsTableHeader">{{ $t('having_diploma') }}</td>
                            </tr>
                        </thead>
                        <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                            <tr class="table-border" v-for="(data, index) in education" :key="index">
                                <td class="jobsTableRow table-border">{{ data.directionOfEducation }}</td>
                                <td class="jobsTableRow table-border">{{ data.levelOfEducation }}</td>
                                <td class="jobsTableRow table-border">{{ data.havingOfDiploma }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--Education Block End-->

            <!--Software Knowledge Start-->
            <div class="bg-white p-6 lg:p-12 mt-3">
                <HeadingComponent :heading="$t('software_knowledge')" />
                <div class="mt-7 overflow-x-auto">
                    <table class="w-full table-auto table-border">
                        <thead>
                            <tr class="table-border">
                                <td class="table-border jobsTableHeader">{{ $t('software_knowledge') }}</td>
                                <td class="table-border jobsTableHeader">{{ $t('level_of_knowledge') }}</td>
                                <td class="table-border jobsTableHeader">{{ $t('having_diploma') }}</td>
                            </tr>
                        </thead>
                        <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                            <tr class="table-border" v-for="(data, index) in softwareKnowledge" :key="index">
                                <td class="jobsTableRow table-border">{{ data.knowledge }}</td>
                                <td class="jobsTableRow table-border">{{ data.levelOfKnowledge }}</td>
                                <td class="jobsTableRow table-border">{{ data.havingDiploma }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--Software Knowledge Stop-->

            <!--Knowledge of Languages Start-->
            <div class="bg-white p-6 lg:p-12 mt-3">
                <HeadingComponent :heading="$t('knowledge_of_languages')" />
                <div class="mt-7 overflow-x-auto">
                    <table class="w-full table-auto table-border">
                        <thead>
                            <tr class="table-border">
                                <td class="table-border jobsTableHeader">{{ $t('knowledge_of_languages') }}</td>
                                <td class="table-border jobsTableHeader">{{ $t('level_of_conversation') }}</td>
                                <td class="table-border jobsTableHeader">{{ $t('writing_level') }}</td>
                            </tr>
                        </thead>
                        <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                            <tr class="table-border" v-for="(data, index) in knowledgeOfLanguages" :key="index">
                                <td class="jobsTableRow table-border">{{ data.language }}</td>
                                <td class="jobsTableRow table-border">{{ data.levelOfConversation }}</td>
                                <td class="jobsTableRow table-border">{{ data.writingLevel }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--Knowledge of Languages End-->

            <!--Driving License Block Start-->
            <div class="bg-white p-6 lg:p-12 mt-3">
                <HeadingComponent :heading="$t('create_job_driving_license_title')" />
                <h3
                    class="border-t border-b border-main py-2 px-4 md:py-3 md:px-9 bg-alice-blue text-nav font-medium text-xs md:text-sm font-bannerExtrasquare">
                    {{ $t('create_job_driving_license_title') }}</h3>
                <p class="text-xs font-bannerExtrasquare text-grey-58 px-4 md:px-9 mt-4">B - მსუბუქი მანქანა</p>
            </div>
            <!--Driving License Block End-->

            <!--Competencies Block Start-->
            <div class="bg-white p-6 lg:p-12 mt-3">
                <HeadingComponent :heading="$t('my_competencies')" />
                <div class="mt-6">
                    <h3
                        class="border-t border-b border-main py-2 px-4 md:py-3 md:px-9 bg-alice-blue text-nav font-medium text-xs md:text-sm font-bannerExtrasquare">
                        {{ $t('competencies_of_candidate') }}</h3>
                    <div class="text-xs font-bannerExtrasquare text-grey-58 px-4 md:px-9">
                        <p class="mt-4">ანგარიშვალდებულება</p>
                        <p class="mt-4">პუნკტუალურობა</p>
                        <p class="mt-4">პუნკტუალურობა</p>

                    </div>
                </div>
            </div>
            <!--Competencies Block Start-->

            <!--Location Map Start-->
            <div class="bg-white p-6 lg:p-12 mt-3">

                <HeadingComponent :heading="$t('service_location')" />
                <div class="mt-6">
                    <img src="@/assets/Images/resume/map-img.png" class="w-full" />
                </div>
            </div>
            <!--Location Map End-->
        </div>
    </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import HeadingComponent from "../common/HeadingComponent.vue";
import { ref, Ref } from 'vue';
export default {
    name: 'Job',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        HeadingComponent
    },
    data() {
        return {
            currentSlide: 0,
            currentMemberSlide: 0,
            breakpoints: {
                // 700px and up
                768: {
                    itemsToShow: 2,
                    snapAlign: "center-even",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    snapAlign: "center-even",
                },
            },

            officeImages: [
                { image: require("@/assets/Images/temp/temp-slide-1.png") },
                { image: require("@/assets/Images/temp/temp-slide-1.png") },
                { image: require("@/assets/Images/temp/temp-slide-1.png") },
                { image: require("@/assets/Images/temp/temp-slide-2.png") },
                { image: require("@/assets/Images/temp/temp-slide-2.png") },
                { image: require("@/assets/Images/temp/temp-slide-2.png") },
                { image: require("@/assets/Images/temp/temp-slide-3.png") },
                { image: require("@/assets/Images/temp/temp-slide-4.png") },
            ],

            teamMembers: [
                {
                    fullName: "Name1 Lastname1",
                    position: "Manager",
                    about:
                        "Kierra assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-1.png"),
                },
                {
                    fullName: "Name2 Lastname2",
                    position: "Designer, CEO",
                    about:
                        "Test description assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-2.png"),
                },
                {
                    fullName: "Name1 Lastname1",
                    position: "Manager",
                    about:
                        "Kierra assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries. ",
                    image: require("@/assets/Images/temp/temp-user-1.png"),
                },
                {
                    fullName: "Name2 Lastname2",
                    position: "Designer, CEO",
                    about:
                        "Test description assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-2.png"),
                },
                {
                    fullName: "Name2 Lastname2",
                    position: "Designer, CEO",
                    about:
                        "Test description assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-2.png"),
                },
                {
                    fullName: "Name2 Lastname2",
                    position: "Designer, CEO",
                    about:
                        "Test description assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-2.png"),
                },
                {
                    fullName: "Name2 Lastname2",
                    position: "Designer, CEO",
                    about:
                        "Test description assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-2.png"),
                },
                {
                    fullName: "Name2 Lastname2",
                    position: "Designer, CEO",
                    about:
                        "Test description assists clients who call or write in with questions, comments, or concerns regarding order updates, shipment tracking, returns, and general inquiries.",
                    image: require("@/assets/Images/temp/temp-user-2.png"),
                },
            ],
        }
    },
    computed: {
        details() {
            return [
                {
                    title: 'ფროფესიის დასახელება',
                    value: 'მარკეტინგის მენეჯერი'
                },
                {
                    title: 'სამს. სექტორი',
                    value: 'მარკეტინგი, რეკლამა'
                },
                {
                    title: 'ანაზღაურება',
                    value: 'GEL 4500-5000/ თვე'
                },
                {
                    title: 'სამუშაო საათები',
                    value: '32-40 საათი კვირაში'
                },
                {
                    title: 'სამუშაო გამოცდილება',
                    value: '1 წელი გამოცდილება'
                },
                {
                    title: 'შესაძლებლობა',
                    value: 'ხელმისაწვდომია'
                },
                {
                    title: 'თანამდ. დონე',
                    value: 'მენეჯერი'
                }
            ]
        },
        requirements() {
            return [
                {
                    title: 'სამსახურის/ფროფესიის დასახელება',
                    value: 'გრაფიკული დიზაინერი'
                },
                {
                    title: 'სამსახურის თანამდებობის დონე',
                    value: 'მენეჯერი'
                },
                {
                    title: 'სასურველი ადგილმდებარეობა',
                    value: 'თბილისი'
                },
                {
                    title: 'სამუშაო გამოცთილება',
                    value: '10 წელი'
                },
                {
                    title: 'დასაქმების ტიპი',
                    value: 'დისტანციური'
                },
                {
                    title: 'სამსახურის სექტორი',
                    value: 'მარკეტინგი, რეკლამა'
                },
                {
                    title: 'სამუშაო საათები',
                    value: '15-20 საათი კვირაში'
                },
                {
                    title: 'ანაზღაურება',
                    value: 'GEL 1500-2000'
                },
                {
                    title: 'სამსახურის დაწყების პერიოდი',
                    value: '1 კვირაში'
                },
                {
                    title: 'ასაკი',
                    value: '25-37'
                }
            ]
        },
        education() {
            return [
                {
                    directionOfEducation: 'გრაფიკა, დიზაინი',
                    levelOfEducation: 'უნივერსიტეტი',
                    havingOfDiploma: 'დიახ'
                }
            ]
        },
        softwareKnowledge() {
            return [
                {
                    knowledge: 'Adobe Photoshop',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                },
                {
                    knowledge: 'Adobe Illustrator',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                },
                {
                    knowledge: 'Adobe After Effects',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                },
                {
                    knowledge: 'Adobe Premier Pro',
                    levelOfKnowledge: 'ექსპერტი',
                    havingDiploma: 'დიახ'
                }
            ]
        },
        knowledgeOfLanguages() {
            return [
                {
                    language: 'ქართული',
                    levelOfConversation: 'საუკეთესო',
                    writingLevel: 'საუკეთესო'
                },
                {
                    language: 'ინგლისური',
                    levelOfConversation: 'საუკეთესო',
                    writingLevel: 'საუკეთესო'
                },
                {
                    language: 'გერმანული',
                    levelOfConversation: 'საშუალო',
                    writingLevel: 'საშუალო'
                }
            ]
        }
    }
}
</script>