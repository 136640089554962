<template>
  <div class="w-full">
    <div class="bg-white flex px-5 md:px-9 pt-5 mb-3">
      <!-- <div>
        <h1 class="font-bannerExtrasquareCaps text-2xl w-fit">{{ $t('profile_edit') }}</h1>
      </div>
      <span class="block ml-1">
        <a href="#"><img src="@/assets/Images/shared/question-mark-icon.png" alt="" /></a>
      </span> -->
      <HeadingComponent :heading="$t('profile_edit')" />
    </div>

    <div class="bg-white flex-row px-5 md:px-9 py-16">
      <AboutInfo ref="about" @show-profile-update-message="displayProfileUpdateMessage()" />
      <email ref="email" />
      <Password ref="password" />

    </div>
    <Transition>
      <UpdateDataMessageComponent :showProfileUpdateMessage="showProfileUpdateMessage"
        message="თქვენი პროფილი განახლებულია!" @toggleMessage="showProfileUpdateMessage = false" />
    </Transition>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import AboutInfo from "./edit/AboutInfo.vue";
import Email from "./edit/Email.vue";
import Password from "./edit/Password.vue";
import IconClose from "@/components/icons/IconClose.vue";
import HeadingComponent from '@/components/common/HeadingComponent.vue'
import UpdateDataMessageComponent from "@/components/common/UpdateDataMessageComponent.vue";

export default {
  name: 'EditCandidate',
  components: {
    QuillEditor,
    AboutInfo,
    Email,
    Password,
    IconClose,
    HeadingComponent,
    UpdateDataMessageComponent
  },
  data() {
    return {
      editingAbout: false,
      editing: false,
      showProfileUpdateMessage: false
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    activateAbout() {
      this.$refs.email.editing = false;
      this.$refs.password.editing = false;
      console.log('active about')
      this.scrollToTop()
    },
    activateEmail() {
      this.$refs.about.editing = false;
      this.$refs.password.editing = false;
      console.log('active email')
      this.scrollToTop()
    },
    activatePassword() {
      this.$refs.email.editing = false;
      this.$refs.about.editing = false;
      console.log('active password')
      this.scrollToTop()
    },
    displayProfileUpdateMessage() {
      this.showProfileUpdateMessage = true;
      this.scrollToTop()
    }
  },
};
</script>

<style>
.ql-container {
  height: auto;
}

.ql-editor {
  min-height: 250px;
}

@media (max-width: 640px) {
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
