<template>
<!-- Login place -->
<div class="md:h-auto" :class="{'hidden':!showForm}">
  <div class="fixed w-full h-full top-0 left-0 bg-black opacity-30"></div>
  <div class="fixed w-full h-full top-0 left-0 overflow-x-hidden overflow-y-auto no-scrollbar">
    <div class="w-full md:max-w-3xl  bg-white mx-auto mt-24">
      <div v-click-away="handleClickAway">
        <div class="grid grid-cols-2 text-center font-bannerExtrasquareCaps">
          <div>
            <a href="#" class="authorization-btn " :class="{'authorization-active':registerActive}" @click="activateRegister">{{ $t('sign_up') }}</a>
          </div>
          <div>
            <a href="#" class="authorization-btn " :class="{'authorization-active':loginActive}" @click="activateLogin">{{ $t('sign_in') }}</a>
          </div>
        </div>
        <div class="mt-5 md:hidden">
          <a href="#" @click.prevent="showForm = false" class="text-main text-xs hover:underline mx-8 block ">{{ $t('back') }}</a>

        </div>
        <div :class="{'hidden':!forgotPasswordActive}">
          <ForgotPasswordComponent ref="forgotpassword" />
        </div>
        <div :class="{'hidden':!loginActive}">
          <LoginComponent ref="login" />
        </div>
        <div :class="{'hidden':!registerActive}">
          <RegisterComponent ref="register" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import LoginComponent from './Auth/LoginComponent.vue'
import RegisterComponent from './Auth/RegisterComponent.vue'
import ForgotPasswordComponent from './Auth/ForgotPasswordComponent.vue'

export default {
  name: "AuthForm",
  data() {
    return {
      showForm: false,
      loginActive: true,
      registerActive: false,
      forgotPasswordActive: false
    };
  },
  props: {
    msg: String,
  },
  methods: {
    activateLogin(){
      this.loginActive = true;
      this.forgotPasswordActive = false;
      this.registerActive = false;
    },
    setLoginEmail(email){
      this.$refs.login.signIn.email = email 
    },
    activateForgotPassword(){
      this.forgotPasswordActive = true;
      this.loginActive = false;
      this.registerActive = false;
    },
    activateRegister(){
      this.registerActive = true;
      this.loginActive = false;
      this.forgotPasswordActive = false;
    },
    handleClickAway() {
      if (this.showForm) {
        this.showForm = false;
      }
    },
  },
  components: {
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent
  }
};
</script>
