<template>
    <div v-if="showProfileUpdateMessage === true">
        <div class="fixed top-0 right-0 bottom-0 left-0 bg-jet-black bg-opacity-60 z-[60] w-screen" @click="$emit('toggleMessage')"></div>
          <div
            class="bg-white max-w-xs sm:max-w-[600px] z-[70]  md:max-w-2xl  m-auto fixed top-2/4 -translate-y-2/4  -translate-x-2/4 left-2/4 w-full px-7 pt-7 pb-12">
            <div v-click-away="closePopup">
              <div class="flex justify-end">
                <button @click="$emit('toggleMessage')">
                  <IconClose class="[&_path]:fill-dark-grey sm:w-4 sm:h-4" />
                </button>
              </div>
              <div class="flex flex-col justify-center items-center">
                <svg class="w-[75px] h-[75px] mt-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
                    fill="#36A8E0" />
                </svg>
                <p class="font-bannerExtrasquareCaps text-sm sm:text-2xl font-normal text-nav mt-10 sm:mt-[76px]">თქვენი
                  პროფილი განახლებულია!
                </p>
              </div>

            </div>
          </div>
        </div>
</template>
<script>
import IconClose from '../icons/IconClose.vue';

export default {
    name: "UpdateDataMessageComponent",
    props: ["showProfileUpdateMessage", "message"],
    emits: ["toggleMessage"],
    components: { IconClose }
}
</script>