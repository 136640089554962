import { createApp } from 'vue';
import VueClickAway from 'vue3-click-away';
import Notifications from 'notiwind';
// import VueCookies from 'vue-cookies';
import App from './App.vue';

import './assets/tailwind.css';
import './plugins/axios';
import store from './store';
import { createGtm } from '@gtm-support/vue-gtm';

import router from './router';


import { createI18n } from 'vue-i18n'
import en from './plugins/locales/en.json'
import ka from './plugins/locales/ka.json'
import VueCookies from 'vue-cookies'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const messages = {
  en,
  ka,
};

if (!$cookies.get('lang')) {
  $cookies.set('lang', 'ka');
}

const i18n = new createI18n({
  locale: $cookies.get('lang'), // set locale
  messages, // set locale messages
});

// console.log(process.env.VUE_APP_AXIOS_BASE_URL)



createApp(App)
  .use(store)
  .use(i18n)
  .use(VueCookies)
  .use(
    createGtm({
      id:'GTM-TGF22HNV',
      vueRouter: router
    })
  )
  .use(router)
  .use(Notifications)
  .use(VueClickAway)
  .use('VueDatePicker', VueDatePicker)
  .mount('#app');
