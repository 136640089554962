<template>
    <div>
        <div class="flex w-full">
            <!-- Non-TextArea Input -->
            <div v-if="!isTextArea" class="relative z-0 w-full group bg-white" :class="{
                'has-error focus-within:has-error': error,
                'bg-light-alice-blue': fillBackgroundColor === true
            }">
                <input :type="showPassword ? 'text' : type" name="company_name" :value="modelValue"
                    @input="$emit('update:modelValue', $event.target.value)" @blur="$emit('onBlur')"
                    class="formInputClass peer h-14" :class="{ 'rounded-l-md': checkSlot, 'rounded': !checkSlot }"
                    placeholder=" " required />
                <label for="company_name" class="formLabelClass pl-3" :class="{
                    'text-main': stayMainColorLabel,
                    '-translate-y-4 text-[10px]': modelValue || focused, // Move label when value is present or focused
                    'peer-focus:text-red-500': error,
                    'text-medium-dark-gray2': fillBackgroundColor === true
                }">
                    {{ label }}
                </label>
                <svg v-if="type === 'password'" @click="showPassword = !showPassword"
                    class="w-6 h-6 absolute right-2 translate-y-2 top-2.5 cursor-pointer" fill="none" stroke="#4E4E4E"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                    </path>
                </svg>
            </div>

            <!-- Text Area -->
            <div v-if="isTextArea" class="flex-1">
                <textarea rows="6" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                    class="bg-white w-full text-gray-900 border focus:outline-none p-3 border-form placeholder:text-gray-900 text-sm"
                    :class="{ 'bg-light-alice-blue': fillBackgroundColor === true }" :placeholder="label"></textarea>
            </div>
            <slot name="icon"></slot>
        </div>
        <small v-if="error && errorMessage" class="text-xs text-red-500 leading-3">
            {{ errorMessage }}
        </small>
    </div>
</template>
<script>
import { useSlots } from 'vue';

export default {
    props: {
        label: String,
        type: {
            type: String,
            default: 'text',
        },
        modelValue: [String, Number],
        error: Boolean,
        stayMainColorLabel: Boolean,
        errorMessage: String,
        fillBackgroundColor: Boolean,
        isTextArea: Boolean,
    },
    emits: ['update:modelValue', 'onBlur'],
    data() {
        return {
            showPassword: false,
        };
    },
    computed: {
        checkSlot() {
            const slot = useSlots();
            return slot.icon ? true : false;
        },
    },
};
</script>
