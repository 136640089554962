export default {
  isLoggedIn(state) {
    return state.loggedIn;
  },
  isCompany(state) {
    return state.isCompany;
  },

  // Company Data

  // User Data
  userResume(state) {
    if (state.userData) {
      return state.userData.resume.about;
    }
  },
  userData: (state) => (param) => {
    if (state.userData) {
      return state.userData[param];
    }
  },
  companyUserData: (state) => (param) => {
    if (state.companyUserData) {
      return state.companyUserData[param];
    }
  },
  companyData: (state) => (param) => {
    if (state.companyData) {
      return state.companyData[param];
    }
  },
  getUserDataObject(state) {
    return state.userData;
  },
  userResumeData: (state) => (param) => {
    if (state.userData) {
      return state.userData.resume[param];
    }
  },

  fullUserName(state) {
    if (state.userData) {
      return `${state.userData.name} ${state.userData.surname}`;
    }
  },
};
