<template>
    <div class="mt-7 px-3 py-8 sm:p-8 drop-shadow-select bg-white">
        <HeadingComponent :heading="$t('knowledge_of_languages')"/>
        <div>
            <!--Preview Start-->
            <div v-if="editing === false" class="mt-7 overflow-x-auto">
                <table class="w-full table-auto table-border">
                    <thead>
                        <tr class="table-border">
                            <td class="table-border jobsTableHeader">{{ $t('language_skills') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('level_of_conversation') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('writing_level') }}</td>
                            <td class="table-border jobsTableHeader">{{ $t('certificate') }}</td>

                        </tr>
                    </thead>
                    <tbody class="[&>*:nth-child(odd)]:bg-custom-main">
                        <tr class="table-border" v-for="(data, index) in knowledgeOfLanguages" :key="index">
                            <td class="jobsTableRow table-border">{{ data.languageSkills }}</td>
                            <td class="jobsTableRow table-border">{{ data.levelOfConversation }}</td>
                            <td class="jobsTableRow table-border">{{ data.writingLevel }}</td>
                            <td class="jobsTableRow table-border">{{ data.certificate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Preview End-->

            <!--Edit Mode Start-->
            <div v-if="editing === true">
                <div v-for="(field, index) in fieldsArray" :key="index" class="mb-6">
                    <div class="flex justify-end mb-1" v-if="fieldsArray.length > 1">
                        <button class="bg-main p-1 rounded " @click="remove(index)">
                            <IconMinus class="[&_path]:fill-white w-6 h-6" />
                        </button>
                    </div>

                    <div class="flex flex-col lg:flex-row  gap-4">
                        <div class="flex-1">
                            <SelectInputComponent :value="field.languageSkills" :label="$t('create_job_languages_title')" :showBorder="true"
                                v-model:updateValue="field.languageSkills"
                                :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1"/>
                        </div>
                        <div class="flex-1 flex flex-col sm:flex-row gap-4">
                            <SelectInputComponent :value="field.speakingLevel" :label="$t('speaking_level')" :showBorder="true"
                                v-model:updateValue="field.speakingLevel"
                                :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1"/>
                            <SelectInputComponent :value="field.levelOfWriting" :label="$t('writing_level')" :showBorder="true"
                                v-model:updateValue="field.levelOfWriting"
                                :options="['option 1', 'option 2', 'option 3', 'option 4']" class="flex-1"/>
                        </div>
                    </div>

                    <div class="mt-9">
                        <RadioButton :value="field.isGraduated" v-model:updateValue="field.isGraduated" :button1="{
                        label: $t('company_job_detail_software_graduated'), name: 'graduated' + index, value: true, id: 'graduated' + index,
                    }" :button2="{
    label: $t('not_graduated'), name: 'not-graduated' + index, value: false, id: 'not-graduated' + index,
}" />
                    </div>
                </div>
                <div class="my-7 lg:my-14">
                     <AddButton @addMore="addMore()"/>
                </div>

            </div>
            <!--Edit Mode End-->
        </div>
        <div class="mt-[30px] flex items-center gap-3">
            <div>
                <IconPdfFile />
                <p class="text-auth text-[8px] text-center">CV.pdf</p>
            </div>
            <div>
                <IconWordFile />
                <p class="text-auth text-[8px] text-center">CV.docx</p>
            </div>
            <div v-if="isResumePreview === true">
                <IconDownload />
            </div>
            <div v-if="editing === true">
                <IconAddDocument />
                <p class="text-main text-xs text-center">{{ $t('add_field') }}</p>
            </div>
        </div>
        <div v-if="isResumePreview === false" class="mt-8">
            <button @click="editing = !editing"
                class="edit-update-btn">
                {{ editing === true ? $t('update') : $t('edit') }}</button>
        </div>
    </div>
</template>
<script>
import IconPdfFile from '@/components/icons/IconPdfFile.vue';
import IconWordFile from '@/components/icons/IconWordFile.vue';
import SelectInputComponent from '@/components/common/SelectInputComponent.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconMinus from '@/components/icons/IconMinus.vue';
import IconAddDocument from '@/components/icons/IconAddDocument.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import HeadingComponent from '@/components/common/HeadingComponent.vue';
import AddButton from '@/components/common/AddButton.vue';
import RadioButton from '@/components/common/RadioButton.vue';
export default {
    name: "KnowledgeOfLanguages",
    components: { IconPdfFile, IconWordFile, SelectInputComponent, IconPlus, IconMinus, IconAddDocument, IconDownload, HeadingComponent, AddButton, RadioButton },
    props: ['isResumePreview'],
    data() {
        return {
            editing: false,
            fieldsArray: []

        }
    },
    computed: {
        knowledgeOfLanguages() {
            return [
                {
                    languageSkills: 'ქართული',
                    levelOfConversation: 'კარგად',
                    writingLevel: 'საშუალო',
                    certificate: 'დიახ'
                },
                {
                    languageSkills: 'ინგლისური',
                    levelOfConversation: 'კარგად',
                    writingLevel: 'საშუალო',
                    certificate: 'დიახ'
                }
            ]
        },
    },
    methods: {
        addMore() {
            this.fieldsArray.push(
                {
                    languageSkills: '',
                    speakingLevel: '',
                    levelOfWriting: '',
                    isGraduated: null
                })
        },
        remove(index) {
            if (this.fieldsArray.length > 1) {
                this.fieldsArray.splice(index, 1);
            }
        }
    },
    mounted() {
        this.addMore();
    }
}
</script>
<style></style>